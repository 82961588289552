
import { BigNumber } from 'ethers'
import { ENVIRONMENT } from '../constants'

// Big number cannot work with decimals, so a 1'5% fee turns into a 15/1000 fee
const FEE_PERCENTAGE_PER_THOUSAND = '15'
const PER_THOUSAND_OFFSET = '1000'
const abortRecipe = async (provider: any, userAddress: any, recipeSteps: any[], toastCb: (txHash) => void): Promise<any> => {
  const remainingTokens = recipeSteps.filter((node) => !node.params.input.functionName)
    .map((node) => {
      const { token, amount } = node.params.input

      if (node.type === 'addFundsNode') {
        const feeAmountToSubstract = (BigNumber.from(amount).mul(FEE_PERCENTAGE_PER_THOUSAND)).div(PER_THOUSAND_OFFSET)
        const liquidityMinusFees = BigNumber.from(amount).sub(feeAmountToSubstract)
        return {
          token,
          amount: liquidityMinusFees.toString()
        }
      }

      return ({ token, amount })
    })
  if (remainingTokens.length <= 0) {
    return
  }
  const nodes = new provider.eth.Contract(ENVIRONMENT.contracts.nodesAbi, ENVIRONMENT.contracts.nodes)
  const tokenAddresses = remainingTokens.map((input) => input.token)
  const tokenAmounts = remainingTokens.map((input) => input.amount)
  const res = nodes.methods.recoverAll(tokenAddresses, tokenAmounts).send({
    from: userAddress,
    maxPriorityFeePerGas: null,
    maxFeePerGas: null
  })
    .once('transactionHash', (txHash) => toastCb(txHash))
  return res
}

export default abortRecipe
