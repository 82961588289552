import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useLastSyncGraphBlock } from 'src/hooks/useGraph'
import { usePairDayDataByBlockNumber } from 'src/hooks/usePairInfoFromGraph'
import "./SummaryList.sass"

export interface SpookyRecipeSummaryLogProps {
  summaryInfo: any
}
const SpookyRecipeSummaryLog = ({ summaryInfo }: SpookyRecipeSummaryLogProps) => {
  const graphLastBlock: number = useLastSyncGraphBlock()
  const blockToUse: number = graphLastBlock
  const myFarmAddress: string = summaryInfo.data?.condition?.farmID
  const pairInfo: any = usePairDayDataByBlockNumber(myFarmAddress, blockToUse)
  const myFarm = pairInfo.data.pairDayDatas[0]

  return (
    <span className="span-info">
      <FormattedMessage id="apr-in-farm" /> <span className="coin">{`${myFarm.token0.symbol} - ${myFarm.token1.symbol}  `}</span>
      <FormattedMessage id={`combo-trigger-modal.${summaryInfo.data.condition.type}`} /> {' '}
      <FormattedMessage id="combo-trigger-modal.than" /> {summaryInfo.data.condition.value}%
    </span>
  )
}

export default SpookyRecipeSummaryLog
