import React, { Suspense, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal, useModal } from "@gluedigital/modal"
import "./ComboTriggerModalLog.sass"
import { findNodeInActiveRecipe, generateShowingTime, getCurrentRowLog } from "src/components/Diagram/nodes/nodesLogsHelper"
import { ComboTriggerModalData, ConditionProvider } from "src/routes/RecipeDiagram/helpers/types"
import NodeStateAndDate from "../NodeStateAndDate/NodeStateAndDate"
import { RecipeDetails, RecipeExecutionLog } from "src/types"
import { usePricesCombo, useTriggerControl } from "src/api/comboTrigger/comboApi"
import Loading from "src/components/common/Loading/Loading"
import { ModalErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary'
import ComboSpookySwapLog from "./ComboSpookySwapLog"
import ManualComboSpookySwapLog from "./ManualComboSpookySwapLog"
import ComboSentimentModalLog from "./ComboSentimentModalLog"
import { ENVIRONMENT } from '../../../../constants';
interface ComboTriggerLogModalProps {
  previousData: {
    data: ComboTriggerModalData
    id: string
    recipeLogs: RecipeExecutionLog[]
    recipeDetails: RecipeDetails
  }
}

interface CurrentPriceProps {
  conditionProvider: any
  coinToCompare: any
  recipeDetails: RecipeDetails

}

const CurrentPrice = ({ coinToCompare, recipeDetails, conditionProvider }: CurrentPriceProps) => {
  const coinsPrices = usePricesCombo()
  const [currentPrice, setCurrentPrice] = useState<string>("")
  if ((recipeDetails?.status === "active" || recipeDetails?.status === "finished") && currentPrice === "" && conditionProvider === ConditionProvider.chainlink) {
    const targetToken = coinsPrices.find((tokenPrice) => coinToCompare === tokenPrice.token)
    setCurrentPrice(targetToken.price)
  }
  return <span className="span-coin-price">{coinToCompare} {currentPrice}</span>
}

const ComboTriggerModalLog = (props: ComboTriggerLogModalProps) => {
  let isNodeExecuted: boolean
  let currentRowLog: any = null
  let showingDate: string
  const { previousData } = props
  const { data, recipeDetails } = previousData
  const recipeLogs = previousData.recipeLogs
  const nodeId = previousData.id
  if (recipeLogs !== null) isNodeExecuted = findNodeInActiveRecipe(recipeLogs, nodeId)
  const conditionProvider = previousData?.data.conditionProvider || ConditionProvider.chainlink

  const [manualExecutionStatus, setManualExecutionStatus] = useState<'ready' | 'pending' | 'done'>('ready')
  const [manualExecutionError, setManualExecutionError] = useState(false)
  const [manualExecutionEnabled, enableManualExecution] = useState(false)

  let isManuallyExecuted = false
  const unblock = useTriggerControl()

  if (isNodeExecuted) {
    currentRowLog = getCurrentRowLog(recipeLogs, nodeId)
    const triggerEvent = currentRowLog.events.find((event) => event.nodeID === nodeId)
    isManuallyExecuted = triggerEvent?.extraData?.manuallyExecuted
    showingDate = generateShowingTime(currentRowLog)
  }

  const modal = useModal()
  const executeManualTrigger = async (e) => {
    e.stopPropagation()
    enableManualExecution(false)
    setManualExecutionStatus('pending')
    unblock(recipeDetails.id, nodeId).then(() => {
      setManualExecutionError(false)
      modal.hide()
    })
      .catch((error) => {
        setManualExecutionStatus('ready')
        setManualExecutionError(true)
        enableManualExecution(true)
        console.error('Unexpected error: ', error)
      })
  }

  useEffect(() => {
    enableManualExecution((recipeDetails.step.some((step) => step.id === nodeId)) && (data?.contracts?.nodes === undefined || ENVIRONMENT.contracts.nodes === data?.contracts?.nodes))
  }, [nodeId, recipeDetails, data.contracts])

  if (manualExecutionStatus === 'pending') {
    return (
      <div className="combo-trigger-modal modal-content">
        <div className="modal-wrapper">
          <Loading />
        </div>
      </div>
    )
  }

  return (
    <div className="combo-trigger-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button>
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="combo-trigger-modal.title" /></h1>
          <NodeStateAndDate isNodeExecuted={isNodeExecuted} showingDate={showingDate} recipeDetails={recipeDetails} />
        </header>
        {
          data.conditionProvider === ConditionProvider.user && <div className="manual-execution-info">
            <span><FormattedMessage id="combo-trigger-modal.wait-manual-execution" /></span>
          </div>
        }
        <div className={`combo-trigger-modal-content ${isManuallyExecuted && 'manually-executed'}`}>
          {isManuallyExecuted &&
            <span className="manually-executed">
              <FormattedMessage id="combo-trigger-modal.manually-executed" />{showingDate}
            </span>
          }
          {data.conditionProvider === ConditionProvider.chainlink &&
            <>
              <div className="combo-trigger-grid">
                <section>
                  <h2><FormattedMessage id="combo-trigger-modal.if" /></h2>
                  <span className="span-info">{conditionProvider}</span>
                </section>
                <section>
                  <h2><FormattedMessage id="combo-trigger-modal.says" /></h2>
                  <span className="span-info">{data.condition.coinToCompare}</span>
                </section>
              </div>
              <section>
                <h2><FormattedMessage id="combo-trigger-modal.is" /></h2>
                <span className="span-info"><FormattedMessage id={`combo-trigger-modal.${data.condition.type}`} />
                  <FormattedMessage id="combo-trigger-modal.than" />   $ {data.condition.value}
                </span>
                <div className="div-current">
                  {(recipeDetails?.status === "active" || recipeDetails?.status === "finished") &&
                    <>
                      <span className="span-current">
                        <FormattedMessage id="combo-trigger-modal.current-price" /> {' '}
                      </span>
                      <Suspense fallback={<Loading />}>
                        <CurrentPrice
                          conditionProvider={data.conditionProvider}
                          recipeDetails={recipeDetails}
                          coinToCompare={data.condition.coinToCompare}
                        />
                      </Suspense>
                    </>
                  }
                </div>
              </section>
            </>
          }
          {data.conditionProvider === ConditionProvider.spookyswap && (
            isManuallyExecuted
              ?
              <ModalErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <ManualComboSpookySwapLog data={data} recipeDetails={recipeDetails} currentRowLog={currentRowLog} />
                </Suspense>
              </ModalErrorBoundary>
              :
              <ModalErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <ComboSpookySwapLog data={data} recipeDetails={recipeDetails} currentRowLog={currentRowLog} />
                </Suspense>
              </ModalErrorBoundary>)}
          {data.conditionProvider === ConditionProvider.sensei &&
            <ModalErrorBoundary>
              <Suspense fallback={<Loading />}>
                <ComboSentimentModalLog data={data} />
              </Suspense>
            </ModalErrorBoundary>
          }
        </div>
        {!isNodeExecuted &&
          <ModalErrorBoundary>
            <div className="execute-now-wrapper">
              {manualExecutionError
                && <span><FormattedMessage id="combo-trigger-modal.manual-execution-error" /></span>
              }
              <button
                disabled={!manualExecutionEnabled}
                onClick={executeManualTrigger as (e: any) => void}
                className="filled execute-now-button"
              >
                <FormattedMessage id="combo-trigger-modal.manual-execute" />
              </button>
            </div>
          </ModalErrorBoundary>
        }
      </div>
    </div >
  )
}

registerModal("combo-trigger-modal-log", ComboTriggerModalLog)
