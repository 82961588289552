
import { Connection } from "react-flow-renderer";
import { DiagramElement, EdgeElement } from "./types";

// Used to change the color of the arrows when validate button is used
export const updateAllEdgesColor = (elements, setElements) => {
    const newElements = elements.map((element) => {
        return element.type === "myCustomEdge" ? { ...element, style: { stroke: "blue", strokeWidth: 1.5 } } : element;
    });
    setElements(newElements);
};

export const createArrowToFarmCombo = (farmNode: DiagramElement, idCombo: string): EdgeElement => {
    const edgeFarmToCombo: EdgeElement = {
        id: `reactflow__edge-${farmNode.id}farm-outputMain-${idCombo}comboinput`,
        source: farmNode.id,
        sourceHandle: "deposit-output",
        style: { stroke: 'yellow', strokeWidth: 1.5 },
        target: idCombo,
        targetHandle: "combo-input",
        type: "myCustomEdge"
    }
    return edgeFarmToCombo;
}

// At this moment this function is outdated.
// Check if the connection between two nodes is finally valid,
export const isConnectionValid = (edges: EdgeElement[], newConnection: Connection) => {
    const alreadyExistingConnection = edges.find(
        (edge) => edge.source === newConnection.source && edge.sourceHandle === newConnection.sourceHandle
    );
    const alreadyExistingConnectionWithTarget = edges.find((edge) => edge.target === newConnection.target);
    if (alreadyExistingConnection !== undefined || alreadyExistingConnectionWithTarget !== undefined) {
        return false;
    }
    if (newConnection.sourceHandle === 'deposit-output' && newConnection.targetHandle !== 'combo-input') {
        return false
    }

    if (newConnection.sourceHandle === 'farm-outputMain' && newConnection.targetHandle !== 'combo-input') {
        return false
    }

    return true;
};
