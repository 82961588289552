import { SPOOKY_FEE } from 'src/constants'
export const calculateFeeApr = (dailyVolumeUSD: string, pairLiquidity: string): number => {
    const DAYS_IN_YEAR: number = 365
    return (parseFloat(dailyVolumeUSD) * DAYS_IN_YEAR * SPOOKY_FEE) / parseFloat(pairLiquidity)
}

export const calculateFarmApr = (
    rewardPerSecond: number,
    allocPoint: number,
    totalAllocPoint: number,
    tokenRewardPrice: number,
    pairLiquidity: string): number => {
    const FROM_WEI_TO_ETHER_CONSIDERING_PERCENTAGE: number = 10 ** 16
    const SECONDS_IN_A_YEAR: number = 31536000
    const booRewardPerYear = rewardPerSecond * SECONDS_IN_A_YEAR
    const poolRewardProportion = allocPoint / totalAllocPoint
    const poolRewardPerYearUSD = poolRewardProportion * booRewardPerYear * tokenRewardPrice
    return poolRewardPerYearUSD / parseFloat(pairLiquidity) / FROM_WEI_TO_ETHER_CONSIDERING_PERCENTAGE
}
