import React from 'react'
// import { Link } from 'react-router-dom'
// import { FormattedMessage } from 'react-intl'
import MarkdownMessage from "@gluedigital/markdown-message"
import { useAtTop, useScrollOver } from '@gluedigital/scrollastic'
import LanguageSelector from '../LanguageSelector/LanguageSelector'

import './Header.sass'
import { useModal } from '@gluedigital/modal';
import { FormattedMessage } from 'react-intl'
// import discord from "src/static/images/discord.svg"
import wallet from "src/static/images/wallet-icon.png"
import SocialLinks from 'src/components/SocialLinks/SocialLinks'

interface HeaderProps {
  scrollable?: any
}

const isBetaBlocked = !!process.env.BETA_BLOCKED

const Header = ({ scrollable }: HeaderProps) => {
  const activeClass = useAtTop(scrollable) ? '' : 'active'
  const introSection = useScrollOver('#intro', scrollable)
  const howSection = useScrollOver('#how', scrollable)
  const arcadeSection = useScrollOver('#arcade-banner', scrollable)
  const comboSection = useScrollOver('#combo', scrollable)
  const savingsSection = useScrollOver('#savings', scrollable)
  const modal = useModal()

  const swapButtonClass = () =>
    savingsSection >= 1
      ? 'savings'
      : comboSection >= 1
        ? 'combo'
        : arcadeSection >= 1
          ? 'arcade'
          : howSection >= 1
            ? 'how'
            : introSection >= 1
              ? 'intro'
              : ''
  return (
    <header id="masthead" className={`${activeClass} ${swapButtonClass()}`}>
      <div className="container">
        <nav>
          <ul className="menu">
            <li>
                <SocialLinks />
            </li>
            <div className = "right-side">
            <li>
              <LanguageSelector />
            </li>
            {isBetaBlocked &&
              <li>
                <a
                  className="app-link button link"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://gleam.io/mDtEF/tortle-ninja-whitelist"
                >
                  <span><MarkdownMessage id="home.about-you-early" /></span>
                </a>
              </li>
            }
            {!isBetaBlocked &&
              <li>
                {/* <Link to="/dashboard/your" className="app-link button link">
                  <span>
                    <MarkdownMessage id="header.link.app" />
                  </span>
                </Link> */}
                <button
                  id="metamask-right-top"
                  className="app-link button link"
                  onClick={() => modal.show('select-wallet-modal')}>
                  <img src={wallet} className="img-wallet" />
                  <FormattedMessage id="select-wallet-modal.connect-wallet" />
                </button>
                </li>
            }
            </div>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
