import axios from 'axios'
import { API_HOST, AXIOS_TIMEOUT } from './constants'
import axiosRetry from 'axios-retry'

axiosRetry(axios, {
  retries: 3,
  retryDelay: (count) => {
    return count * 1500
  },
  retryCondition: (error) => {
    return error.response.status === 503
  },
})

const API = axios.create({
  baseURL: API_HOST,
  timeout: AXIOS_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
})

export default API
