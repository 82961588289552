import React, { useState } from "react"
import web3 from "src/utils/web3"
import { useSelector } from "react-redux"
import { depositFTM } from "../../contracts/ApproveHelpers"
import { FormattedMessage } from "react-intl"
import { ApprovalStatus } from "src/routes/RecipeDiagram/helpers/types"
import useUnload from "src/hooks/useUnload"
import './ApprovesDepositList.sass'

interface DepositListProps {
  amount: string
  isAllApproved: boolean
  hasInsufficientBalance: boolean
  handleDeposit: () => void
  estimationSuccess?: boolean
}

const DepositList = (props: DepositListProps) => {
  const { amount, isAllApproved, hasInsufficientBalance, estimationSuccess } = props
  const user = useSelector((s: any) => s.user)
  const userAddress = user.account.account
  const [approvalStatus, setApprovalStatus] = useState<ApprovalStatus>('ready')

  useUnload(approvalStatus === 'pending')

  const approveDeposit = () => {
    setApprovalStatus('pending')
    depositFTM(web3, userAddress, amount)
      .then(() => {
        setApprovalStatus("approved")
        props.handleDeposit()
      })
      .catch(() => {
        setApprovalStatus('rejected')
      })
  }

  const handleClick = () => {
    window.open("https://docs.tortle.ninja/troubleshooting")
  }

  return <div className="deposit-list">
    <h3 className="deposit-header"> <FormattedMessage id="recipe-summary.deposit.header" /></h3>
    <span><FormattedMessage id="recipe-summary.deposit.instructions" /></span>
    <div className="tokens-list">
      <div className="token-item">
        <span className={`status-icon ${approvalStatus}`} />
        <span className="token-tag">FTM</span>
        {approvalStatus === "rejected" && <button className="icon-pregunta" onClick={handleClick}> </button>}
      </div>
    </div>
    {approvalStatus === "rejected" &&
      <div className="gas-info-div">
        <span className="gas-info"><FormattedMessage id="recipe-summary.gas-info" />
          <a
            href="https://docs.tortle.ninja/troubleshooting"
            target="_blank"
            rel="noopener noreferrer"
            className="help-link"
          >here.</a> </span>
      </div>}
    <button
      disabled={!estimationSuccess || approvalStatus === "approved" || approvalStatus === 'pending' || !isAllApproved || hasInsufficientBalance}
      onClick={approveDeposit}>
      <FormattedMessage id="recipe-summary.deposit.button" />
    </button>
  </div>
}

export default DepositList
