import React from 'react'

import './SocialLinks.sass'

const SocialLinks = () => {
  return (
    <div className="social-links">
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="https://t.me/tortleninjaTG"
        className="icon icon-telegram"
        title="telegram link"
      />
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="https://twitter.com/tortle_ninja"
        className="icon icon-twitter"
        title="twitter link"
      />
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="https://docs.tortle.ninja/"
        className="icon icon-gitbook"
        title="gitbook link"
      />
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="https://tortle-ninja.medium.com/"
        className="icon icon-medium"
        title="medium link"
      />
    </div>
  )
}

export default SocialLinks
