import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import logo from 'src/static/images/logo_one-line.svg'
import toy from 'src/static/images/not-found_toy.png'
import './NotFound.sass'

const NotFound = () => {
  return (
    <div id="not-found" className="page">
      <div className="split">
        <div className="item left">
          <img className="logo" src={logo} alt="Tortle Ninja logo" />
          <h1>
            <span>404</span>
          </h1>
          <Link to="/" className="button">
            <span><FormattedMessage id="back-home" /></span>
            <span className="icon icon-arrow-right" />
          </Link>
        </div>
        <div className="item right">
          <img className="toy" src={toy} alt="Tortle Ninja logo" />
        </div>
      </div>
    </div>
  )
}

export default NotFound
