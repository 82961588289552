import React, { Suspense } from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { findNodeInActiveRecipe, generateShowingTime, getCurrentRowLog, getEndingLogForFarmsDeposit, getNumberOfLogsForDepositAndFarms } from "src/components/Diagram/nodes/nodesLogsHelper"
import { FarmModalData } from "src/routes/RecipeDiagram/helpers/types"
import { getTokenImage } from "src/api/farms/helpers"
import NodeStateAndDate from "../NodeStateAndDate/NodeStateAndDate"
import { RecipeDetails, RecipeExecutionLog } from "src/types"
import { ENVIRONMENT } from 'src/constants'
import { useGetLPInfo, useLPUsdPrice } from "src/hooks/useLPsLogs"
import { FarmGraphData, PairDayData, PairInfoSpooky } from "src/api/farms/types"
import { useDepositBlockNumberFromTxHash, useOtherMomentBlockNumberFromTxHash } from "src/hooks/useWeb3"
import Loading from "src/components/common/Loading/Loading"
import { ModalErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { LoadingFarmModalLog } from "./LoadingFarmModalLog"
import { useAllFarmsIdFromApi, useFarmsOneDay, useLastSubgraphBlockFromApi } from "src/api/recipes"
import { CurrentValueFarmModalLog } from './MCv2FarmModalLog/CurrentValueFarmModalLog'
import { DepositedFarmModalLog } from "./DepositedFarmModalLog"
import { EarnedFarmModalLog } from "./MCv2FarmModalLog/EarnedFarmModalLog"
import { FinalValueFarmModalLog } from "./MCv2FarmModalLog/FinalValueFarmModalLog"
import { NoExecutedFarmModalLog } from "./NoExecutedFarmModalLog"
import { CurrentValueV3FarmModalLog } from "./MCv3FarmModalLog/CurrentValueV3FarmModalLog"
import { FinalValueV3FarmModalLog } from "./MCv3FarmModalLog/FinalValueV3FarmModalLog"
import { EarnedV3FarmModalLog } from "./MCv3FarmModalLog/EarnedV3FarmModalLog"
import { AprV3FarmModalLog } from "./MCv3FarmModalLog/AprV3FarmModalLog"

import "./FarmModalLog.sass"

export interface FarmLogModalProps {
  previousData: {
    data: FarmModalData
    id: string
    recipeLogs: RecipeExecutionLog[]
    recipeDetails: RecipeDetails
  }
}

const FarmModalLog = (props: FarmLogModalProps) => {
  const { previousData } = props
  const recipeLogs = previousData.recipeLogs
  const nodeID = previousData.id
  const pair = previousData?.data?.pair
  const myFarmAddressWithTimestamp: string = pair.id
  const myFarmAddress: string = myFarmAddressWithTimestamp.slice(0, -6)
  const NO_EXECUTED_NODE: number = 0
  const ACTIVE_NODE: number = 2
  const FINISHED_NODE: number = 4
  const ABORTED_NODE: number = 5
  let showingDate: string
  let executionSteps: number
  let isExecutionStarted: boolean = false
  let blockNumberAtDeposit: number = null
  let blockNumberAtOtherMoment: number = null
  const masterchefVersion: number = 2

  if (recipeLogs !== null) {
    executionSteps = getNumberOfLogsForDepositAndFarms(recipeLogs, nodeID)
    isExecutionStarted = findNodeInActiveRecipe(recipeLogs, nodeID)
  }

  const graphLastBlock: number = useLastSubgraphBlockFromApi()
  const depositRowLog = getCurrentRowLog(recipeLogs, nodeID)
  const endingLog = getEndingLogForFarmsDeposit(nodeID, recipeLogs)
  blockNumberAtDeposit = useDepositBlockNumberFromTxHash(depositRowLog?.trans)
  blockNumberAtOtherMoment = useOtherMomentBlockNumberFromTxHash(depositRowLog?.trans, endingLog?.trans, executionSteps)
  if (executionSteps === ACTIVE_NODE || executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) {
    showingDate = generateShowingTime(depositRowLog)
    if (executionSteps === ACTIVE_NODE || executionSteps === ABORTED_NODE) blockNumberAtOtherMoment = graphLastBlock
  } else if (executionSteps === NO_EXECUTED_NODE) {
    blockNumberAtDeposit = null
    blockNumberAtOtherMoment = graphLastBlock
  }
  const blockToUse: number = blockNumberAtOtherMoment > graphLastBlock ? graphLastBlock : blockNumberAtOtherMoment
  const allFarms: FarmGraphData = useFarmsOneDay()
  const masterchefInfo: PairDayData = allFarms.pools.data.find((pair) => pair.pairAddress === myFarmAddress)
  const allFarmLPs: string[] = useAllFarmsIdFromApi(masterchefVersion)
  const myFarm: PairInfoSpooky = useGetLPInfo(myFarmAddress, blockToUse)
  const splitDecimals = myFarm.reserveUSD.split('.')
  const liquidity = `${splitDecimals[0]}.${splitDecimals[1].slice(0, 2)}`

  const lpUSDPriceAtDeposit: number = useLPUsdPrice(myFarmAddressWithTimestamp, blockNumberAtDeposit)
  const lpUSDPriceAtOtherMoment: number = useLPUsdPrice(myFarmAddressWithTimestamp, blockNumberAtOtherMoment)

  return (
    <ModalErrorBoundary>
      <div className="swap-modal modal-content">
        <div className="modal-wrapper">
          <header className="header-buttons">
            <CloseModal>
              <button>
                <span className="icon icon-close" />
                <span><FormattedMessage id="close" /></span>
              </button>
            </CloseModal>
            <h1><FormattedMessage id="farm-modal.title" /></h1>
            <NodeStateAndDate isNodeExecuted={isExecutionStarted} showingDate={showingDate} recipeDetails={previousData.recipeDetails} numberOfLogs={executionSteps} />
          </header>
          <div className="farm-modal-wrapper">
            <div className="main-div">
              <div className="farm-chosen-div">
                <div className="div-tokens">
                  <span className="div-token-images">
                    <img className="logo img-token-farm" src={getTokenImage(pair?.token0)} />
                    <img className="logo img-token-farm-right" src={getTokenImage(pair?.token1)} />
                  </span>
                  <span className="span-token-farm">{pair?.token0} - {pair?.token1}</span>
                </div>
                <div className="div-link-ftmscan" >
                  <a className="link-ftmscan"
                    href={`${ENVIRONMENT.blockExplorerUrls}/address/${pair?.id.split('-')[0]}`}
                    target="_blank"
                    rel="noreferrer noopener">
                    <FormattedMessage id="farm-modal.view-contract" /> <strong>ftmscan.com</strong></a>
                </div>
              </div>
              <div>
                <section className="farm-modal-currency">
                  {executionSteps !== NO_EXECUTED_NODE ?
                    (
                      (masterchefInfo.poolIdV3 || masterchefInfo.poolIdV3 === 0 ?
                        <>
                          <DepositedFarmModalLog lpPrice={lpUSDPriceAtDeposit} executionSteps={executionSteps} previousData={previousData} />
                          {executionSteps === ACTIVE_NODE
                            ?
                            <>
                              <Suspense fallback={<Loading />} >
                                <CurrentValueV3FarmModalLog lpPrice={lpUSDPriceAtOtherMoment} masterchefInfo={masterchefInfo} blockNumberAtOtherMoment={blockNumberAtOtherMoment} executionSteps={executionSteps} previousData={previousData} />
                              </Suspense>
                            </>
                            :
                            <>
                              <Suspense fallback={<Loading />} >
                                <FinalValueV3FarmModalLog lpPrice={lpUSDPriceAtOtherMoment} executionSteps={executionSteps} previousData={previousData} masterchefInfo={masterchefInfo} blockNumberAtOtherMoment={blockNumberAtOtherMoment} />
                              </Suspense>
                            </>
                          }
                          <Suspense fallback={<Loading />} >
                            <EarnedV3FarmModalLog lpUSDPriceAtDeposit={lpUSDPriceAtDeposit} lpUSDPriceAtOtherMoment={lpUSDPriceAtOtherMoment} executionSteps={executionSteps} previousData={previousData} masterchefInfo={masterchefInfo} blockNumberAtOtherMoment={blockNumberAtOtherMoment} />
                          </Suspense>
                        </>
                        :
                        <>
                          <DepositedFarmModalLog lpPrice={lpUSDPriceAtDeposit} executionSteps={executionSteps} previousData={previousData} />
                          {executionSteps === ACTIVE_NODE
                            ?
                            <>
                              <Suspense fallback={<Loading />} >
                                <CurrentValueFarmModalLog lpPrice={lpUSDPriceAtOtherMoment} allFarmLPs={allFarmLPs} masterchefInfo={masterchefInfo} blockNumberAtOtherMoment={blockNumberAtOtherMoment} executionSteps={executionSteps} previousData={previousData} />
                              </Suspense>
                            </>
                            :
                            <>
                              <Suspense fallback={<Loading />} >
                                <FinalValueFarmModalLog lpPrice={lpUSDPriceAtOtherMoment} executionSteps={executionSteps} previousData={previousData} allFarmLPs={allFarmLPs} blockNumberAtOtherMoment={blockNumberAtOtherMoment} masterchefInfo={masterchefInfo} />
                              </Suspense>
                            </>
                          }
                          <Suspense fallback={<Loading />} >
                            <EarnedFarmModalLog lpUSDPriceAtOtherMoment={lpUSDPriceAtOtherMoment} lpUSDPriceAtDeposit={lpUSDPriceAtDeposit} executionSteps={executionSteps} previousData={previousData} allFarmLPs={allFarmLPs} masterchefInfo={masterchefInfo} blockNumberAtOtherMoment={blockNumberAtOtherMoment} />
                          </Suspense>
                        </>
                      )
                    )
                    :
                    <NoExecutedFarmModalLog />
                  }
                  <Suspense fallback={<Loading />} >
                    <AprV3FarmModalLog title="farm-modal-log.apr" myFarm={myFarm} masterchefInfo={masterchefInfo} blockNumberAtOtherMoment={blockNumberAtOtherMoment} />
                  </Suspense>
                  <div id="farm-log-field">
                    <h3><FormattedMessage id={"farm-modal-log.liquidity"} /></h3>
                    <strong className="span-coin">{`$ ${parseFloat(liquidity).toLocaleString()}`}</strong>
                  </div>
                </section>
              </div>
            </div>
            <div className="div-provider">
              <span className="span-title-provider"><FormattedMessage id="farm-modal-log.provider" /> </span>
              <span className="span-provider">{pair?.provider}</span>
            </div>
          </div>
        </div>
      </div>
    </ModalErrorBoundary>
  )
}

const FarmModalLogWrapper = (props: FarmLogModalProps) => (
  <Suspense fallback={<LoadingFarmModalLog {...props} />}>
    <FarmModalLog {...props} />
  </Suspense>
)
registerModal("farm-modal-log", FarmModalLogWrapper)
