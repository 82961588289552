import masterchefSpookyAbi from './abis/masterchef-spooky.json'
import masterchefV2SpookyAbi from './abis/masterchefV2-spooky.json'
import multicallAbi from './abis/multicall.json'
import complexRewarderV2Abi from './abis/complexRewarderV2.json'
import masterChefSpookyV3Abi from './abis/masterChefV3.json'
import routerSpookyAbi from './abis/router-spooky.json'
import uniswapV2FactoryAbi from './abis/UniswapV2Factory.json'
import { addresses } from './addresses'
import { ENVIRONMENT } from 'src/constants'
import web3 from 'src/utils/web3'

export default {
  masterchefSpookyV1: new web3.eth.Contract(masterchefSpookyAbi, addresses.masterchefSpookyV1[ENVIRONMENT.chainId]),
  masterchefSpookyV2: new web3.eth.Contract(masterchefV2SpookyAbi, addresses.masterchefSpookyV2[ENVIRONMENT.chainId]),
  complexRewarderV2: new web3.eth.Contract(complexRewarderV2Abi, addresses.complexRewarderV2[ENVIRONMENT.chainId]),
  masterChefSpookyV3: new web3.eth.Contract(masterChefSpookyV3Abi, addresses.masterchefSpookyV3[ENVIRONMENT.chainId]),
  multicall: new web3.eth.Contract(multicallAbi, addresses.multicall[ENVIRONMENT.chainId]),
  routerSpooky: new web3.eth.Contract(routerSpookyAbi, addresses.routerSpooky[ENVIRONMENT.chainId]),
  uniswapV2Factory: new web3.eth.Contract(uniswapV2FactoryAbi, addresses.uniswapV2Factory[ENVIRONMENT.chainId]),
}
