import { useCRUD, useFetch, useFetchCb, useFetchUncachedCb, usePaginatedFetch, usePeriodicFetch } from '@gluedigital/ruse-fetch-extras'
import { useDispatch } from 'react-redux'
import { EstimateGasResponse } from 'src/components/RecipeSummary/EstimateGasLabel'
import { API_HOST } from 'src/constants'
import { Recipe, RecipeDetails, RecipeExecutionLog, RecipePatch, UserStats, RecipePerformance } from 'src/types'
import { FarmGraphData } from './farms/types'
import { YearnVaultsData } from './yearnVaults/yearnTypes'

const base = API_HOST

// --- API Calls ---

export const useStats = () => useFetch<UserStats>(base + '/stats')

export const usePaginatedPublic = (query?: string) => usePaginatedFetch<Recipe[]>(base + '/recipes', { params: { size: 24, query } })

export const useRecipeDetails = (id: number) => useFetch<RecipeDetails>(id && (base + '/recipes/' + id))
export const useRecipeLogs = (id: number) => useFetch<RecipeExecutionLog[]>(id && (base + '/recipes/' + id + '/logs'))
export const useRecipePerformance = (id: number) => useFetch<RecipePerformance>(id && (base + '/recipes/' + id + '/performance'))
export const useRecipeEstimation = (id: number) => useFetch<EstimateGasResponse>(id && (base + '/recipes/' + id + '/estimate'), { method: 'PUT', body: { id } })

// --- Routes to obtain all the Masterchefv2 and Masterchefv3 info ---
export const useAllFarmsIdFromApi = (version: number) => useFetch<string[]>(version && (base + '/masterchef/ids/' + version))
export const useTotalAllocPointFromApi = (version: number) => useFetch<number>(version && (base + '/masterchef/totalallocpoint/' + version))
export const useAllFarmAllocPointsFromApi = (version: number) => useFetch<string[]>(version && (base + '/masterchef/farmsallocpoint/' + version))
export const useFarmsFiveDays = () => useFetch<FarmGraphData>(base + '/farms/info/five')
export const useFarmsOneDay = () => useFetch<FarmGraphData>(base + '/farms/info/single')
export const useLastSubgraphBlockFromApi = () => useFetch<number>(base + '/farms/lastblock')
// --- Routes to obtain Yearn  info ---
export const useYearnVaultsFromApi = () => useFetch<YearnVaultsData[]>(base + '/yearn/vaults')
export const useYearnTotalAssetsFromApi = () => useFetch<string[]>(base + '/yearn/vaults/assets')

// --- USE CRUD TO SAVE USERS ACCESS ---
export const useAccessCRUD = () => {
  const res = useCRUD(base + '/access')
  return res
}
export const useRecipeCRUD = () => {
  const res = useCRUD<number, RecipePatch>(base + '/recipes')
  const dispatch = useDispatch()
  const reload = useFetchCb()
  return {
    ...res,
    fork: async (id: number) => await res.custom(`/${id}/fork`, { method: 'POST' }),
    abort: async (id: number) => await res.custom(`/${id}/abort`, { method: 'PUT', body: { id } })
      .then((res) => {
        dispatch({ type: 'useFetch/success', key: base + '/recipes/' + id, value: res })
        return res
      })
      .catch((err) => {
        reload(base + '/recipes/' + id).catch(err => console.error(err))
        throw new Error(err)
      }),
    execute: async (id: number) => await res.custom(`/${id}/execute`, { method: 'PUT', body: { id } })
      .then((res) => {
        dispatch({ type: 'useFetch/success', key: base + '/recipes/' + id, value: res })
        return res
      }),
    update: async (id: number, recipePatch: RecipePatch) => await res.update(id, recipePatch)
      .then((res) => {
        dispatch({ type: 'useFetch/success', key: base + '/recipes/' + id, value: res })
        return res
      }),

  }
}

// --- Utility methods ---

export const useFetchUpdater = () => {
  const dispatch = useDispatch()
  const fetch = useFetchUncachedCb()
  return async (url) => await fetch(url)
    .then(value => {
      dispatch({ type: 'useFetch/success', key: url, value })
      return value
    })
}

export const useMyRecipesRefresh = () => {
  const update = useFetchUpdater()
  return async (status?: string) => await Promise.all([
    update(base + '/stats'),
    status && update(base + '/recipes/by-status/' + status)
  ])
}

export const useRecipePerformanceRefresh = () => {
  const update = useFetchUpdater()
  return async (id: number) => await update(base + '/recipes/' + id + '/performance')
}
export const usePeriodicRecipes = (status: string, interval: number = 5000): Recipe[] =>
  usePeriodicFetch<Recipe[]>(interval, base + '/recipes/by-status/' + status)

export const usePeriodicStats = (interval: number = 5000): UserStats => usePeriodicFetch<UserStats>(interval, base + '/stats')

export const usePeriodicLiquidatedRecipes = (interval: number = 5000): Recipe[] =>
usePeriodicFetch<Recipe[]>(interval, base + '/recipes/by-liquidationDescOrder/')
