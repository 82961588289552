import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal, useModal } from "@gluedigital/modal"
import { useHistory } from 'react-router-dom';
import "./FormEarlyAccessModal.sass"
import "../ThanksEarlyAccessModal/ThanksEarlyAccessModal"
import { useAccessCRUD } from "src/api/recipes";

const FormEarlyAccessModal = () => {
  const history = useHistory()
  const modal = useModal()
  const accessCrud = useAccessCRUD()
  const goBackHome = () => { history.push('/') }

  const [wallet, setWalletAddress] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const emailChange = (e) => { setEmail(e.target.value) }
  const walletChange = (e) => { setWalletAddress(e.target.value) }
  const applyHandler = (e) => {
    e.preventDefault()
    modal.hide()
    const res = accessCrud.create({ email, wallet })
    res.then((data) => {
      if (data === 'Wallet previously registered') console.log(data)
    }).catch((err) => console.log(err))
    modal.show('thanks-early-access-modal')
  }

  const disabled: boolean = wallet.length !== 42 || !email.includes('@')

  return (
    <div className="recipe-name-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button onClick={goBackHome} className="back-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
        </header>
        <div className="text-wrapper-early-access">
          <p><FormattedMessage id="early-access-metamask" /></p>
          <p><FormattedMessage id="early-access-email" /></p>
          <form onSubmit={applyHandler} >
            <input type="text" value={wallet} onChange={walletChange} placeholder="Metamask Wallet address..." />
            <input type="text" value={email} onChange={emailChange} placeholder="Email..." />
            <button className={`apply-now ${!disabled ? 'enable' : 'disable'}`} disabled={disabled}>
              <FormattedMessage id="get-early-access-form-modal.apply" /></button>
          </form>
        </div>
      </div>
    </div>
  )
}

registerModal("form-early-access-modal", FormEarlyAccessModal)
