import tokensMainnet from 'src/data/tokensMainnet.json'
import tokensTestnet from 'src/data/tokensTestnet.json'
import vaultsMainNetDev from 'src/data/vaultsMainNetDev.json'
import vaultsMainNetPro from 'src/data/vaultsMainNetPro.json'
import vaultsV3MainetDev from 'src/data/vaultsV3MainNetDev.json'
import vaultsV3MainNetPro from 'src/data/vaultsV3MainNetPro.json'
import batchAbiDev from 'src/contracts/abis/batch.json'
import batchAbiPro from 'src/contracts/abis/batchPRO.json'
import nodesAbiPro from 'src/contracts/abis/nodesPRO.json'
import nodesAbiMainnetDev from 'src/contracts/abis/nodesMainnetDev.json'
import nodesAbiTestnetDev from 'src/contracts/abis/nodesTestnetDev.json'

export const API_HOST = process.env.API_HOST || 'http://tortle-api.byglue.me'

export const METAMASK_ERROR_CODES = {
  USER_DENIED: 4001,
  TRANSACTION_UNDERPRICED: -32603
}
export const BOO_ADDRESS = {
  '0xfa': '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
  '0xfa2': '0x1CA68a01E2A8ff61b3FD2F0f6f00178699da26E7',
}

export const SPOOKY_FEE = 0.17
export const masterchefTheGraphUriFromSpookyswap = {
  '0xfa': 'https://api.thegraph.com/subgraphs/name/eerieeight/spookyswap',
  '0xfa2': 'https://graph-beta.tortle.ninja/subgraphs/name/alexpinilla/spookyswapv2',
}

export const yearnVaultsSubgraph = {
  '0xfa': 'https://api.thegraph.com/subgraphs/name/yearn/yearn-vaults-v2-fantom',
}

export const yearnVaultsYDaemon = {
  '0xfa': 'https://ydaemon.yearn.finance/250/vaults/all?strategiesDetails=noDetails',
}

export const fantomBlocksSubGraphUri = 'https://api.thegraph.com/subgraphs/name/matthewlilley/fantom-blocks'
export const AXIOS_TIMEOUT = 8000
export const pairTokensPools = 'pairTokens'
export const pairTokensFarms = 'pairTokensFarms'

const nativeCurrency = {
  name: 'Fantom',
  symbol: 'FTM',
  decimals: 18,
}

const testnetProContracts = {}

const testnetDevContracts = {
  batch: "0xFb4975EFA4E1b81D52B7a6fbd7B078CDaE3aACE9",
  nodes: "0x88797E5ED33ac8F15c995456eAf13EAbB021E021",
  batchAbi: batchAbiDev,
  nodesAbi: nodesAbiTestnetDev
}

const mainnetDevContracts = {
  batch: "0xf460C4587482938b20551b7863FD05c8A16262c8",
  nodes: "0x493b3C1754a77bA538FA61eEd2e83972c28469dD",
  batchAbi: batchAbiDev,
  nodesAbi: nodesAbiMainnetDev,
  vaults: vaultsMainNetDev,
  vaultsV3: vaultsV3MainetDev
}

const mainnetProContracts = {
  batch: "0x2d8a07D9aacA94954a7474C389362eECF371971F",
  nodes: "0xEDC8736B9686808964C289E03fFab8aa24c7eb56",
  batchAbi: batchAbiPro,
  nodesAbi: nodesAbiPro,
  vaults: vaultsMainNetPro,
  vaultsV3: vaultsV3MainNetPro
}

const mainnet = {
  chainId: "0xfa",
  chainName: "Fantom Opera",
  nativeCurrency,
  rpcUrls: ["https://rpc.ftm.tools/"],
  blockExplorerUrls: ["https://ftmscan.com/"],
  tokens: tokensMainnet,
}

const mainnetPro = { ...mainnet, contracts: mainnetProContracts }

const mainnetDev = { ...mainnet, contracts: mainnetDevContracts }

const testnet = {
  chainId: "0xfa2",
  chainName: "Fantom Testnet",
  nativeCurrency,
  rpcUrls: ["https://rpc.testnet.fantom.network/", "https://xapi.testnet.fantom.network/lachesis"],
  blockExplorerUrls: ["https://testnet.ftmscan.com/"],
  tokens: tokensTestnet,
}

const testnetPro = { ...testnet, contracts: testnetProContracts }

const testnetDev = { ...testnet, contracts: testnetDevContracts }

const localnet = {
  chainId: "0x7A69",
  chainName: "Local net",
  nativeCurrency,
  rpcUrls: ["http://localhost:8545"],
  blockExplorerUrls: ["https://testnet.ftmscan.com/"],
}

const nets = {
  LOCAL: localnet,
  TESTNET_DEV: testnetDev,
  TESTNET_PRO: testnetPro,
  MAINNET_DEV: mainnetDev,
  MAINNET_PRO: mainnetPro
}

export const ENV_ENVIROMENT = process.env.ENVIRONMENT
export const ENVIRONMENT = nets[ENV_ENVIROMENT] || testnetDev
