import React, { Suspense } from "react"
import OnlyDesktopVeil from "src/components/OnlyDesktopVeil/OnlyDesktopVeil"
import ToastList from "src/components/common/RecipeToasts/ToastList"
import { NodesContext } from "./helpers/nodesAndEdgesContext"

import './Diagram.sass'
// import Loading from "src/components/common/Loading/Loading"
import { LoadingDnDFlow } from "./DnDFlow/LoadingDnDFlow"

declare const __CLIENT__: boolean

const DnDFlow = __CLIENT__ && require("./DnDFlow/DnDFlow").DnDFlow

const Diagram = () => {
  if (!__CLIENT__) return <div style={{ height: "100%" }} />
  return (
    <div id="diagram" className="page" style={{ height: "100%" }}>
      <Suspense fallback={<LoadingDnDFlow />}>
        <ToastList darkmode />
        <NodesContext>
          <DnDFlow />
        </NodesContext>
      </Suspense>
      <OnlyDesktopVeil />
    </div>
  )
}

export default Diagram
