import { useFetch, useFetchUncachedCb } from '@gluedigital/ruse-fetch-extras'
import { API_HOST } from 'src/constants'
import { TokenSentiment } from 'src/routes/RecipeDiagram/helpers/types'
const base = API_HOST

export interface TokenPrice {
  token: string
  price: string
}
export const usePricesCombo = () => useFetch<TokenPrice[]>(base + '/combo/')
export const useSentimentCombo = () => useFetch<TokenSentiment[]>(base + '/combo/sentiment')

export const useTriggerControl = () => {
  const fetch = useFetchUncachedCb()
  return async (id: number, nodeID: string) =>
    await fetch(base + '/combo/' + id + '/unblock', { method: 'POST', body: { id, nodeID } })
}
