import { AddFundsModalData, LiquidateModalData, DepositOnLPModalData, FarmModalData, ComboTriggerElement, NodeType, ComboTriggerModalData, SwapModalData, SplitModalData, NodeElement, EdgeElement, NestedStrategiesModalData } from "src/routes/RecipeDiagram/helpers/types";
import { v4 as uuidv4 } from 'uuid';
import { ENVIRONMENT } from "src/constants";

const coins = ENVIRONMENT.tokens

export const addDroppedNode = (event: React.DragEvent<HTMLDivElement>, modal, addNode) => {
  const type = event.dataTransfer.getData("application/reactflow");
  event.preventDefault();
  switch (type) {
    case "addFundsNode":
      modal.show("add-funds-modal", { onSave: (extraData: AddFundsModalData) => addNode(event, type, extraData) });
      break;
    case "liquidateNode":
      modal.show("liquidate-modal", { onSave: (extraData: LiquidateModalData) => addNode(event, type, extraData) });
      break;
    case "comboTriggerNode":
      modal.show("combo-trigger-modal", { onSave: (extraData: ComboTriggerModalData) => addNode(event, type, extraData) });
      break;
    case "swapNode":
      modal.show("swap-modal", { onSave: (extraData: SwapModalData) => addNode(event, type, extraData) });
      break;
    case "splitNode":
      modal.show("split-modal", { onSave: (extraData: SplitModalData) => addNode(event, type, extraData) });
      break;
    case "depositOnLPNode":
      modal.show("deposit-lp-modal", { onSave: (extraData: DepositOnLPModalData) => addNode(event, type, extraData) });
      break;
    case "farmNode":
      modal.show("farm-modal", { onSave: (extraData: FarmModalData) => addNode(event, type, extraData) })
      break;
    case "nestedStrategiesNode":
      modal.show("nested-strategies-modal", { onSave: (extraData: NestedStrategiesModalData) => addNode(event, type, extraData) })
      break;
    default:
      addNode(event, type, {});
  }
};

export const createDataForNodeBeforeAdding = (type: NodeType) => {
  const nodeData: any = {}
  switch (type) {
    case 'sendToWalletNode':
    case "liquidateNode":
      nodeData.nextConnected = true;
      break
    case "splitNode":
      nodeData.secondNextConnected = false;
      nodeData.nextConnected = false;
      break
    default:
      nodeData.nextConnected = false;
      break
  }
  return nodeData;
};
export const getRecipeSummary = (elements) => {
  let addFundsNodes: any[] = []
  elements.forEach((element) => {
    if (element.type === "addFundsNode") addFundsNodes.push(element)
  })
  const orderedNodes: any[] = []

  while (addFundsNodes.length > 0) {
    const currentNode = addFundsNodes.pop();
    if (currentNode !== undefined) {
      const arrowConnections = elements.filter((element) => element.source === currentNode.id);
      const connectedElements = arrowConnections.map((arrow) => elements.find((element) => element.id === arrow.target)).filter((el) => el !== undefined);
      orderedNodes.push(currentNode);
      addFundsNodes = addFundsNodes.concat(connectedElements);
    }
  }
  return orderedNodes
}

export const getTokenAddress = (tokenID: string) => {
  const targetCoin = coins.find((coin) => coin.id === tokenID)
  return targetCoin?.address
}

// Used to create the comboNode which follows the farm or liquidate combo
export const createFollowingCombo = (newTarget): ComboTriggerElement => {
  const newNodeId: string = `node_${uuidv4().replace(/-/g, '')}_${+new Date()}`.slice(0, -1) + 'X';
  const xOldPosition: number = newTarget.position.x;
  const yOldPosition: number = newTarget.position.y;
  const xNewOffSet: number = 200;
  const yNewOffSet: number = 25;
  const followingCombo: ComboTriggerElement = {
    id: newNodeId,
    type: "comboTriggerNode",
    position: {
      x: (xOldPosition + xNewOffSet),
      y: (yOldPosition - yNewOffSet),
    },
    data: {
      onEditElementByID: newTarget.data.onEditElementByID,
      onRemoveElementById: newTarget.data.onRemoveElementById,
      nextConnected: false,
      outputCoin: newTarget.data.inputCoin,
    }
  }
  return followingCombo;
}

export const modifyElementsBeforeRemove = (nodeToRemove: NodeElement, nodes: NodeElement[], edges: EdgeElement[]): NodeElement[] => {
  const [previousNodesIDs, nextNodesIDs] = edges.reduce((accumulator, currentEdge) => {
    if (currentEdge.target === nodeToRemove.id) {
      accumulator[0].push(currentEdge.source)
    }
    if (currentEdge.source === nodeToRemove.id) {
      accumulator[1].push(currentEdge.target)
    }
    return accumulator
  }, [[], []])
  const updatedNodes: NodeElement[] = nodes.reduce((accNodes, node) => {
    if (previousNodesIDs.includes(node.id) && node.type !== 'sendToWalletNode' && node.type !== 'liquidateNode') {
      const updatedNode: NodeElement = { ...node }
      const edgeFromSplit: EdgeElement = edges.find((edge) => (edge.source === node.id) && (edge.target === nodeToRemove.id))
      if (edgeFromSplit?.sourceHandle === 'split-output-2' && updatedNode.type === 'splitNode') {
        updatedNode.data.secondNextConnected = false
      } else {
        updatedNode.data.nextConnected = false
      }
      accNodes[accNodes.indexOf(node)] = updatedNode
      return accNodes
    }
    if (nextNodesIDs.includes(node.id) && node.type !== 'addFundsNode') {
      const updatedNode = { ...node }
      updatedNode.data = { ...node.data, inputCoin: undefined }
      if (updatedNode.type === 'comboTriggerNode' || updatedNode.type === 'depositOnLPNode') {
        updatedNode.data.outputCoin = undefined
      } else if (updatedNode.type === 'farmNode') {
        updatedNode.data = { ...updatedNode.data, outputCoin: undefined, outputMain: undefined }
      }
      accNodes[accNodes.indexOf(node)] = updatedNode
      const updatedNodes = propagateRemovalChanges(updatedNode, accNodes, edges)
      return updatedNodes
    }
    return accNodes
  }, nodes)
  return updatedNodes
}

const propagateRemovalChanges = (sourceNode: NodeElement, nodes: NodeElement[], edges: EdgeElement[]): NodeElement[] => {
  if (sourceNode.type !== 'comboTriggerNode' && sourceNode.type !== 'farmNode' && sourceNode.type !== 'depositOnLPNode') {
    return nodes
  }

  const nextEdge = edges.find((edge) => edge.source === sourceNode.id)
  const nextNode = nodes.find((node) => node.id === nextEdge?.target)
  const updatedNode = { ...nextNode }
  updatedNode.data = { ...nextNode?.data, inputCoin: undefined }
  if (updatedNode?.type === 'comboTriggerNode' || updatedNode?.type === 'depositOnLPNode') {
    updatedNode.data.outputCoin = undefined
  } else if (updatedNode?.type === 'farmNode') {
    updatedNode.data = { ...updatedNode?.data, outputCoin: undefined, outputMain: undefined }
  }
  nodes[nodes.indexOf(nextNode)] = updatedNode
  const updatedNodes = propagateRemovalChanges(updatedNode, nodes, edges)
  return updatedNodes
}
