import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useRecipeDetails, useRecipeLogs } from "src/api/recipes";
import { useRecipeDetailsLive, useRecipeLogsLive } from "src/context/SocketContext";
import { RecipeExecutionLog } from "src/types";

export const obtainRecipeLogs = (): RecipeExecutionLog[] => {
    let { id } = useParams();
    const recipeLogs = useRecipeLogs(id) || [];
    const liveRecipeLogs = useRecipeLogsLive(id)
    const [aggregatedLogs, setLogs] = useState<RecipeExecutionLog[]>([])
    useEffect(() => {
        setLogs([...recipeLogs, ...liveRecipeLogs])
    }, [liveRecipeLogs])
    return aggregatedLogs;
};

export const obtainRecipeDetails = () => {
    let { id } = useParams();
    const recipeDetails = useRecipeDetails(id);
    const recipeDetailsLive = useRecipeDetailsLive(id)
    return recipeDetailsLive || recipeDetails;
};

export const isLogForOtherUserRecipe = (recipeLogs) => {
    const recipeID: number = (recipeLogs[0]?.recipe)
    const recipeDetails = useRecipeDetails(recipeID);
    const owner: string = recipeDetails?.owner
    const account = useSelector((s: any) => (s.user ? s.user.account : undefined))
    if (owner === account.account.slice(2)) return true;
    return false;
}