import React from "react"
import { FormattedMessage } from "react-intl"
import { FarmData, PairDayData, TokenInfoSpooky } from "src/api/farms/types"
import { useBooAmountFromStrategySC, useLpEarned } from "src/hooks/useFarmsLogs"
import { useGetTokenPriceUsd } from "src/hooks/useTokensLogsFromGraph"
import { useAllFarmsIdFromApi } from "src/api/recipes"
import { tokenAddressToName } from "src/components/Diagram/nodes/nodesLogsHelper"
import "../MCv2FarmModalLog/FarmLogField.sass"

interface CurrentValueV3FarmModalLogProps {
  lpPrice: number
  masterchefInfo: PairDayData
  blockNumberAtOtherMoment: number
  executionSteps: number
  previousData: any
}

export const CurrentValueV3FarmModalLog = ({ lpPrice, masterchefInfo, blockNumberAtOtherMoment, executionSteps, previousData }: CurrentValueV3FarmModalLogProps) => {
  const myFarmAddress: string = masterchefInfo.pairAddress
  const DECIMAL_USD_ROUNDED: number = 3
  const DECIMAL_TOKEN_ROUNDED: number = 12
  const version: number = masterchefInfo.poolIdV3 || masterchefInfo.poolIdV3 === 0 ? 3 : 2
  const farmData: FarmData = useLpEarned(previousData.id, previousData.date, executionSteps) || { state: 'Pending', lpDeposited: 'No Info', earned: 'No Info' }
  const totalLpAmount: number = Number(farmData.lpDeposited.slice(0, -2)) + Number(farmData.earned.slice(0, -2))

  const allFarmLPs: string[] = useAllFarmsIdFromApi(version)

  const amountRewardWei: number = useBooAmountFromStrategySC(myFarmAddress, allFarmLPs, version)
  const amountRewardEther: number = amountRewardWei / 10 ** 18

  const nameRewardToken: string = tokenAddressToName(masterchefInfo.tokenReward)
  const rewardTokenData: TokenInfoSpooky = useGetTokenPriceUsd(masterchefInfo.tokenReward, blockNumberAtOtherMoment)

  const totalLpUsdValue: number = totalLpAmount * lpPrice
  const booUSDValue: number = amountRewardEther * parseFloat(rewardTokenData.priceUSD)
  const totalUsdValue: number = totalLpUsdValue + booUSDValue

  const firstRow: JSX.Element = <strong className="span-coin">${totalUsdValue.toFixed(DECIMAL_USD_ROUNDED)} </strong>

  const secondRow: JSX.Element = <div className="div-2column-lps">
    <span className="lp-amount-row first-column">{totalLpAmount.toFixed(DECIMAL_TOKEN_ROUNDED)} <strong>LPs</strong></span>
    <span className="label-lp-price second-column">${totalLpUsdValue.toFixed(DECIMAL_USD_ROUNDED)} </span>
  </div>

  const thirdRow: JSX.Element = <div className="div-2column-lps">
    <span className="lp-amount-row first-column">{amountRewardEther.toFixed(DECIMAL_TOKEN_ROUNDED)} <strong>{nameRewardToken}</strong> </span>
    <span className="label-lp-price second-column">$ {booUSDValue.toFixed(DECIMAL_USD_ROUNDED)}</span>
  </div>

  return (
    <div id="farm-log-field">
      <h3><FormattedMessage id="farm-modal-log.current-value" /></h3>
      {firstRow}
      <> {secondRow} </>
      <> {thirdRow} </>
    </div>
  )
}
