import React from 'react'
import { registerModal, useModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { login, setUserAccount } from "src/store/actions/user"
import { changeChain, checkNetworkAndGetAccount, getLoginObject } from 'src/routes/login-helper'
import { ENVIRONMENT } from "src/constants"

import './SelectWalletModal.sass'
import '../WhiteListModal/WhiteListModal'
import "../EarlyAccessModal/EarlyAccessModal"
import "../TermsConditionsModal/TermsConditionsModal"
import metamask from 'src/static/images/wallet-icons/metamask.png'
import coinwallet from 'src/static/images/wallet-icons/coinwallet.png'
import walletconnect from 'src/static/images/wallet-icons/walletconnect.png'

declare const ethereum: any

const SelectWalletModal = () => {
  const history = useHistory()
  const modal = useModal()

  const dispatch = useDispatch()
  const hasWallet = typeof ethereum !== "undefined"

  const loginMetamask = async () => {
    if (!hasWallet) {
      console.error("MetaMask is not installed!")
      return { result: 'ERROR', message: 'MetaMask is not installed!' }
    } else {
      try {
        const accounts = await ethereum.request({ method: "eth_requestAccounts" })
        if (ethereum.chainId !== ENVIRONMENT.chainId) {
          const res = await changeChain()
          console.log(res)
        }
        const loginObject = await getLoginObject(accounts[0])
        const account = await checkNetworkAndGetAccount()
        await dispatch(login(loginObject))
        dispatch(setUserAccount(account))
        const deviceAccepted = localStorage.getItem("deviceAccepted")
        console.log(deviceAccepted)
        if (deviceAccepted === "true") {
          history.push('/dashboard/your')
          modal.hide()
        } else modal.show("terms-and-conditions-modal")
      } catch (err) {
        if (err.status === 403) {
          modal.hide()
          modal.show('early-access-modal')
        } else {
          alert(err.message)
          modal.hide()
        }
      }
    }
  }

  return (
    <div className="select-wallet-modal modal-content">
      <img src="/images/logo_white.png" alt="modal liquidate icon" />
      <div className="modal-wrapper">
        <h3><FormattedMessage id="select-wallet-modal.connect-wallet" /></h3>
        <button id="second-metamask" className="justify-between" onClick={() => (loginMetamask as () => void)()}>
          <div className="wallet-logo-container">
            <img className="wallet-logo" src={metamask} alt="metamask" />
          </div>
          <span>Metamask</span>
          <div className="coming-soon"></div>
        </button>
        <button className="justify-between btn-disabled">
          <div className="wallet-logo-container">
            <img className="wallet-logo" src={coinwallet} alt="coinwallet" />
          </div>
          <span>Coinwallet</span>
          <div className="coming-soon"><FormattedMessage id="select-wallet-modal.coming-soon" /></div>
        </button>
        <button className="justify-between btn-disabled">
          <div className="wallet-logo-container">
            <img className="wallet-logo" src={walletconnect} alt="wallet connect" />
          </div>
          <span>Wallet Connect</span>
          <div className="coming-soon"><FormattedMessage id="select-wallet-modal.coming-soon" /></div>
        </button>
      </div>
    </div>
  )
}

registerModal('select-wallet-modal', SelectWalletModal)
