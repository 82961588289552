import React, { useState, Suspense, useEffect, useRef } from "react"
import { FormattedMessage } from 'react-intl'
import RecipeList from "src/components/RecipeList/RecipeList"
import { usePaginatedPublic } from "src/api/recipes"
import Loading from "src/components/common/Loading/Loading";

const BrowseRecipes = () => {
  const [searchTerm, setSearch] = useState('')
  const paginatedRecipes = usePaginatedPublic(searchTerm)
  const inputRef = useRef<HTMLInputElement>(null)

  const [recipes, setRecipes] = useState(paginatedRecipes.data)
  const infiniteScrollCb = () => {
    if (!paginatedRecipes.isLoadingMore) {
      paginatedRecipes.loadMore()
        .then((res) => setRecipes(res))
        .catch(err => err.message)
    }
  }

  let searchTimeout
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchTimeout)
    if (!e.target.value) {
      return setSearch('')
    }

    searchTimeout = setTimeout(() => {
      setSearch(e.target.value)
    }, 500)
  }
  useEffect(() => {
    inputRef.current.focus()
  }, [searchTerm])

  useEffect(() => {
    setRecipes(paginatedRecipes.data)
  }, [paginatedRecipes])

  return (
    <section id="browse-recipes" className="recipes-section">
      <h1>
        <FormattedMessage id="recipes-section.browse-recipes.title" />
      </h1>
      <div className="input-wrapper">
        <FormattedMessage id="placeholder.search">
          {(text: string) => (
            <input
              ref={inputRef}
              autoFocus
              type="text"
              onChange={handleSearch}
              placeholder={text}
            />
          )}
        </FormattedMessage>
        <span className="icon icon-search" />
      </div>
      <RecipeList recipes={recipes} type={"public"} loadMoreCb={infiniteScrollCb} showLoadMoreButton={paginatedRecipes.hasNext} />
    </section>
  );
};

const BrowseRecipesWrapper = (props) => (
  <Suspense fallback={<Loading />}>
    <BrowseRecipes {...props} />
  </Suspense>
)

export default BrowseRecipesWrapper
