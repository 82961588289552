import { ApolloQueryResult } from "@apollo/client"
import { LP_PAIR_INFO } from "src/api/farms/thegraph/lpQueries"
import { clientSpooky } from 'src/components/ApolloClient'
import { LP_RESERVE_TOTAL_SUPPLY_FROM_BLOCK_NUMBER, GET_PAIR_TOKEN_BY_ADDRESS, ONE_LP_INFO_FROM_BLOCK_NUMBER } from '../api/farms/thegraph/lpQueries'
import usePromise from "./usePromise"
import { useFTMPriceUSDByBlock } from "./useTokensLogsFromGraph"
import web3 from "src/utils/web3"
import { PairInfoSpooky } from "src/api/farms/types"

const getLPLiquidityAndTotalSupply = async (pairAddress: string, blockNumber: number, graphLastBlock: number): Promise<ApolloQueryResult<any>> => {
  const usefulBlockNumber: number = blockNumber > graphLastBlock ? graphLastBlock : blockNumber
  if (usefulBlockNumber !== 0 && usefulBlockNumber !== null && usefulBlockNumber !== undefined) {
    const res = pairAddress.length === 42
      ? await clientSpooky.query({ query: ONE_LP_INFO_FROM_BLOCK_NUMBER(pairAddress, usefulBlockNumber) })
      : await clientSpooky.query({ query: ONE_LP_INFO_FROM_BLOCK_NUMBER(pairAddress.slice(0, -6), usefulBlockNumber) })
    return res
  }
  return undefined
}

export const useLPLiquidityAndTotalSupply = (pairAddress: string, blockNumber: number, graphLastBlock: number) => {
  const lpInfo = usePromise(getLPLiquidityAndTotalSupply, pairAddress, blockNumber, graphLastBlock)
  return [lpInfo?.data.pair?.reserveETH, lpInfo?.data.pair?.totalSupply]
}

const getLPReserveData = async (pairAddress: string, blockNumber: number, graphLastBlock: number): Promise<ApolloQueryResult<any>> => {
  const usefulBlockNumber: number = blockNumber > graphLastBlock ? graphLastBlock : blockNumber
  if (usefulBlockNumber !== 0 && usefulBlockNumber !== null && usefulBlockNumber !== undefined) {
    const res = pairAddress.length === 42
      ? await clientSpooky.query({ query: LP_RESERVE_TOTAL_SUPPLY_FROM_BLOCK_NUMBER(pairAddress, usefulBlockNumber) })
      : await clientSpooky.query({ query: LP_RESERVE_TOTAL_SUPPLY_FROM_BLOCK_NUMBER(pairAddress.slice(0, -6), usefulBlockNumber) })
    return res
  }
  return undefined
}

export const useLPReserves = (pairAddress: string, blockNumber: number, graphLastBlock: number) => {
  const lpInfo = usePromise(getLPReserveData, pairAddress, blockNumber, graphLastBlock)
  return lpInfo?.data?.pair
}

export const useLPPriceUSD = (pairAddress: string, blockNumber: number, graphLastBlock: number) => {
  const LpData: number[] = useLPLiquidityAndTotalSupply(pairAddress, blockNumber, graphLastBlock)
  const ftmPrice: number = useFTMPriceUSDByBlock(blockNumber, graphLastBlock)
  const lpPrice: number = (LpData[0] / LpData[1]) * ftmPrice
  return lpPrice
}

const getPairTokensByAddress = async (pairAddress: string) => {
  const result = await clientSpooky.query({
    query: GET_PAIR_TOKEN_BY_ADDRESS(pairAddress),
    fetchPolicy: 'cache-first',
  })
  return result
}

export const usePairTokensByAddress = (pairAddress: string) => {
  return usePromise(getPairTokensByAddress, pairAddress)
}

const getLPInfo = async (pairAddress: string, blockNumber: number = undefined, timestamp: number = undefined) => {
  if (timestamp) {
    const res = pairAddress.length === 42
      ? await clientSpooky.query({ query: LP_PAIR_INFO(pairAddress, timestamp) })
      : await clientSpooky.query({ query: LP_PAIR_INFO(pairAddress.slice(0, -6), timestamp) })
    return res
  } else if (blockNumber) {
    const block = await web3.eth.getBlock(blockNumber)
    const blockTimestamp: number = block.timestamp
    const res = pairAddress.length === 42
      ? await clientSpooky.query({ query: LP_PAIR_INFO(pairAddress, blockTimestamp) })
      : await clientSpooky.query({ query: LP_PAIR_INFO(pairAddress.slice(0, -6), blockTimestamp) })
    return res
  } else if (!blockNumber && !timestamp) return undefined
}

export const useGetLPInfo = (pairAddress: string, blockNumber: number = undefined, timestamp: number = undefined): PairInfoSpooky => {
const res = usePromise(getLPInfo, pairAddress, blockNumber, timestamp)
const pair: PairInfoSpooky = res.data.pairDayDatas[0]
return pair
}

export const useLPUsdPrice = (pairAddress: string, blockNumber: number = undefined, timestamp: number = undefined): number => {
  const pair: PairInfoSpooky = useGetLPInfo(pairAddress, blockNumber, timestamp)
  const lpPriceUSD: number = parseFloat(pair.reserveUSD) / parseFloat(pair.totalSupply)
  return lpPriceUSD
}
