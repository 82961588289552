import React, { useState, useEffect, useMemo } from 'react'
import { registerModal, CloseModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import { DepositOnLPModalData, LPPair } from 'src/routes/RecipeDiagram/helpers/types'
import Loading from 'src/components/common/Loading/Loading'
import { BaseNodeModal } from "../BaseNodeModal"
import pairsAPI from 'src/api/farms/api'
import featuredPools from 'src/data/featuredPools.json'
import PoolInfo from './PoolInfo'
import useInfiniteScroll from 'src/hooks/useInfiniteScroll'

import './DepositOnLPModal.sass'

interface DepositOnLPNodePropTypes extends BaseNodeModal<DepositOnLPModalData> { }

const PAGE_SIZE = 9
const DepositOnLPModal = (props: DepositOnLPNodePropTypes) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [showLoadMoreButton, setLoadMoreButton] = useState(true)
  const [chosenOption, setChosenOption] = useState(null)
  const [pairs, setPairs] = useState([])
  const [sort, setSort] = useState('APR')
  const [search, setSearch] = useState('')
  const [featuredPairs, setFeaturedPairs] = useState([])
  const [loading, setLoading] = useState(false)

  const readyPairs = useMemo(() => {
    const searchText = search.toLowerCase()
    const updatedPairs = [...pairs].filter((p) => {
      return (
        p.token0.id.toLowerCase().includes(searchText) ||
        p.token1.id.toLowerCase().includes(searchText) ||
        p.token0.symbol.toLowerCase().includes(searchText) ||
        p.token1.symbol.toLowerCase().includes(searchText)
      )
    })
    switch (sort) {
      case 'APR':
        updatedPairs.sort((a, b) => b.aprFees - a.aprFees)
        break
      case 'LIQUIDITY':
        updatedPairs.sort((a, b) => b.reserveUSD - a.reserveUSD)
        break
      case 'VOLUME':
        updatedPairs.sort((a, b) => b.dailyVolumeUSD - a.dailyVolumeUSD)
        break
      case 'PROVIDER':
        updatedPairs.sort((a, b) => (a.provider > b.provider) ? 1 : ((b.provider > a.provider) ? -1 : 0))
    }
    return updatedPairs.slice(0, PAGE_SIZE * currentPage)
  }, [currentPage, pairs, search, sort])

  const loadMoreHandler = () => {
    if (PAGE_SIZE * currentPage < pairs.length) {
      setCurrentPage(currentPage => currentPage + 1)
    } else {
      setLoadMoreButton(false)
    }
  }
  const ref: any = useInfiniteScroll(loadMoreHandler)

  const resumePair = (pair: any) => {
    return {
      id: pair.id,
      token0: pair.token0,
      token1: pair.token1,
      provider: pair.provider
    }
  }
  useEffect(() => {
      if (props?.previousData?.pair?.id) setChosenOption(props?.previousData?.pair?.id)
      const load = async () => {
        setLoading(true)
        const result = await pairsAPI.getPoolPairs({}, {})
        setFeaturedPairs(result.pools.data.filter((pair) => featuredPools.includes(pair.id.split('-')[0])))
        const pairs = result.pools.data.filter((pair) => !featuredPools.includes(pair.id.split('-')[0]))
        setPairs(pairs)
        setLoading(false)
      }
      load().catch(err => console.error(err.message))
  }, [props?.previousData?.pair?.id])

  const saveHandler = () => {
    const pair: LPPair = {
      id: chosenOption.pair.id,
      token0: chosenOption.pair.token0.symbol,
      token1: chosenOption.pair.token1.symbol,
      provider: chosenOption.pair.provider
    }
    props.onSave({ pair })
  }

  return (
    <div className="deposit-lp-modal modal-content">
      <div className="modal-wrapper" ref={ref}>
        <header className="header-buttons">
          <CloseModal>
            <button className="modal-close-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="deposit-lp-modal.title" /></h1>
          <CloseModal>
            <button
              disabled={!chosenOption}
              onClick={saveHandler}
              className="filled"
            >
              <FormattedMessage id="save" />
            </button>
          </CloseModal>
        </header>
        <section className="main-section">
          <header>
            <label className="search">
              <FormattedMessage id="deposit-lp-modal.placeholder">
                {(text: string) => (
                  <input
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={text}
                  />
                )}
              </FormattedMessage>
              <span className="icon icon-search" />
            </label>
            <div className="select-wrapper">
            <select defaultValue={sort} onChange={(e) => setSort(e.target.value)}>
                <FormattedMessage id="deposit-lp-modal.sort-by-apr">
                  {(text) => <option value="APR">{text}</option>}
                </FormattedMessage>
                <FormattedMessage id="deposit-lp-modal.sort-by-liquidity">
                  {(text) => <option value="LIQUIDITY">{text}</option>}
                </FormattedMessage>
                <FormattedMessage id="deposit-lp-modal.sort-by-volume">
                  {(text) => <option value="VOLUME">{text}(24h)</option>}
                </FormattedMessage>
                <FormattedMessage id="deposit-lp-modal.sort-by-provider">
                  {(text) => <option value="PROVIDER">{text}</option>}
                </FormattedMessage>
            </select>
            </div>
          </header>
          {!loading ? (
            <ul className="deposit-list" >
              {featuredPairs.map((pair, i) =>
                <PoolInfo
                  key={i}
                  pair={pair}
                  isChosen={chosenOption?.pair?.id === pair?.id || chosenOption === pair?.id}
                  setChosenOption={(pair) => setChosenOption({ pair: resumePair(pair), autochoose: false })}
                  featured
                />
              )}
              {readyPairs.map((pair, i) =>
                <PoolInfo
                  key={i}
                  pair={pair}
                  isChosen={chosenOption?.pair?.id === pair?.id || chosenOption === pair?.id}
                  setChosenOption={(pair) => setChosenOption({ pair: resumePair(pair), autochoose: false })}
                />
              )}
              {showLoadMoreButton &&
                <li className="load-more-wrapper">
                  <button className="load-more-button" onClick={loadMoreHandler}><FormattedMessage id="load-more" /></button>
                </li>}
            </ul>
          ) : (
            <div className="table-body loading">
              <Loading />
            </div>
          )}
        </section>
      </div>
    </div>
  )
}

registerModal('deposit-lp-modal', DepositOnLPModal)
