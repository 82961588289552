import { BigNumber } from "ethers"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { tokensABI } from "src/data/ABIs"
import { ApprovalStatus, TokenForApproval } from "src/routes/RecipeDiagram/helpers/types"
import { ENVIRONMENT } from '../constants';

export const allowance = async (provider: any, userAddress: string, tokens: TokenForApproval[], setTokenStatus: (tokenID: string, status: ApprovalStatus) => void) => {
  for (let i = 0; i < tokens.length; i++) {
    if (tokens[i].status === "approved") {
      continue
    }
    setTokenStatus(tokens[i].address, 'pending')
    const token: any = new provider.eth.Contract(tokensABI, tokens[i].address);
    const result = await token.methods.allowance(userAddress, ENVIRONMENT.contracts.nodes).call()

    if (BigNumber.from(result).lt(BigNumber.from(tokens[i].amount))) {
      const tokenAddress = tokens[i].address
      await token.methods.approve(ENVIRONMENT.contracts.nodes, tokens[i].amount.toString()).send({
        from: userAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
        .once('confirmation', () => {
          setTokenStatus(tokenAddress, "approved")
        })
        .once('error', () => {
          setTokenStatus(tokenAddress, "rejected")
        })
    } else {
      setTokenStatus(tokens[i].address, "approved")
    }
  }
}

export const amountIsAlreadyApproved = async (provider: any, userAddress: string, tokens: TokenForApproval[], setTokenStatus: (tokenID: string, status: ApprovalStatus) => void) => {
  for (let i = 0; i < tokens.length; i++) {
    const token = new provider.eth.Contract(tokensABI, tokens[i].address)
    await token.methods.allowance(userAddress, ENVIRONMENT.contracts.nodes).call()
      .then((result: any) => {
        if (BigNumber.from(result).gte(BigNumber.from(tokens[i].amount))) {
          setTokenStatus(tokens[i].address, "approved")
        }
      })
  }
}

export const depositFTM = (provider: any, userAddress: string, amount: string) => {
  const nodes = new provider.eth.Contract(ENVIRONMENT.contracts.nodesAbi, ENVIRONMENT.contracts.nodes)
  return nodes.methods.addFundsForFTM(userAddress)
    .send({
      from: userAddress,
      value: amount,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null
    })
    .once('confirmation', () => {
      return true
    })
    .once('error', () => {
      return false
    })
}

export const checkEnoughTokenBalance = async (provider: any, userAddress: string, token: TokenForApproval): Promise<boolean> => {
  const tokenObj = new provider.eth.Contract(tokensABI, token.address)
  const balance: number = await tokenObj.methods.balanceOf(userAddress).call()
  return BigNumber.from(token.amount).lt(BigNumber.from(balance))
}

export const checkFantomBalance = async (provider: any, userBalance: string, fantoms: TokenForApproval) => {
  const balanceToWei = provider.utils.toWei(userBalance, 'ether')
  return BigNumber.from(fantoms.amount).lt(BigNumber.from(balanceToWei))
}

export const getNodesToApprove = (recipeCode: any): TokenForApproval[] => {
  const nodesToApprove = recipeCode.filter((node) => node.type === 'addFundsNode').map((node) => {
    const tokenAddress = tokenNameToAddress(node.data.outputCoin)
    return {
      id: node.data.outputCoin,
      address: tokenAddress,
      amount: node.data.amount.toString(),
      status: 'pending'
    }
  })
  return nodesToApprove
}
