import React, { useEffect, useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { AverageSentiment, ComboTriggerModalData, ComparisonType, ConditionProvider, SentimentCondition, TokenSentiment } from "src/routes/RecipeDiagram/helpers/types"
import veryHighIcon from "../../../../static/images/sentiment-icons/sentimentVeryHigh.svg"
import highIcon from "../../../../static/images/sentiment-icons/sentimentHigh.svg"
import neutralIcon from "../../../../static/images/sentiment-icons/sentimentNeutral.svg"
import lowIcon from "../../../../static/images/sentiment-icons/sentimentLow.svg"
import veryLowIcon from "../../../../static/images/sentiment-icons/sentimentVeryLow.svg"
import volume1 from "../../../../static/images/sentiment-icons/volume1.svg"
import volume2 from "../../../../static/images/sentiment-icons/volume2.svg"
import volume3 from "../../../../static/images/sentiment-icons/volume3.svg"
import volume4 from "../../../../static/images/sentiment-icons/volume4.svg"
import volume5 from "../../../../static/images/sentiment-icons/volume5.svg"
import { useSentimentCombo } from "src/api/comboTrigger/comboApi"

interface SentimentTriggerInputProps {
  triggerData: ComboTriggerModalData
  setTriggerData: (data: ComboTriggerModalData) => void
}

const SentimentTriggerInput = (props: SentimentTriggerInputProps) => {
  const { triggerData, setTriggerData } = props

  const tokenSentiments: TokenSentiment[] = useSentimentCombo()
  const condition = triggerData ? triggerData.condition as SentimentCondition : undefined

  const [currentToken, setCurrentToken] = useState<TokenSentiment>(() => tokenSentiments.find((token) => token.ticker === condition?.token))
  const [triggerMode, setTriggerMode] = useState<'or' | 'and'>(condition?.composition || 'and')
  const [volumeComparison, setVolumeComparison] = useState<ComparisonType>(condition?.volumeComparison || 'lower')
  const [targetVolume, setTargetVolume] = useState<number>(condition?.volume)
  const [sentimentComparison, setSentimenComparison] = useState<ComparisonType>(condition?.sentimentComparison || 'lower')
  const [targetSentiment, setTargetSentiment] = useState<AverageSentiment>(condition?.sentiment)

  const handleTokenChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const updatedToken = tokenSentiments.find((token) => token.ticker === e.target.value)
    if (updatedToken) {
      setCurrentToken(updatedToken)
    }
  }

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTargetVolume(+e.target.value)
  }

  const handleSentimentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTargetSentiment(+e.target.value as AverageSentiment)
  }

  const volumeIcon = useMemo(() => {
    if (!currentToken) {
      return <img src={volume1} />
    }
    switch (currentToken.volume) {
      case 1:
        return <img src={volume1} />
      case 2:
        return <img src={volume2} />
      case 3:
        return <img src={volume3} />
      case 4:
        return <img src={volume4} />
      case 5:
        return <img src={volume5} />
      default:
        return <img src={volume1} />
    }
  }, [currentToken])

  const sentimentIcon = useMemo(() => {
    if (!currentToken) {
      return <img src={neutralIcon} />
    }
    switch (currentToken.sentiment) {
      case AverageSentiment.veryLow:
        return <img src={veryLowIcon} />
      case AverageSentiment.low:
        return <img src={lowIcon} />
      case AverageSentiment.neutral:
        return <img src={neutralIcon} />
      case AverageSentiment.high:
        return <img src={highIcon} />
      case AverageSentiment.veryHigh:
        return <img src={veryHighIcon} />
    }
  }, [currentToken])

  useEffect(() => {
    setTriggerData({
      condition: {
        token: currentToken?.ticker,
        volume: targetVolume,
        sentiment: targetSentiment,
        volumeComparison,
        sentimentComparison,
        composition: triggerMode
      },
      conditionProvider: ConditionProvider.sensei
    })
  }, [currentToken, volumeComparison, targetVolume, triggerMode, sentimentComparison, targetSentiment, setTriggerData])

  return <div className="sentiment-trigger-wrapper">
    <section className="sentiment-trigger-currentData">
      <div className="sentiment-currentData-field">
        <span>
          <FormattedMessage id="combo-trigger-modal.sentiment-token" />
        </span>
        <select onChange={handleTokenChange} value={currentToken?.ticker || ''} placeholder="Choose one">
          <option value={''} disabled><FormattedMessage id="combo-trigger-modal.select.sentiment-token" /></option>
          {tokenSentiments.map((sentiment) => <option key={sentiment.ticker} value={sentiment.ticker}>{sentiment.ticker}</option>)}
        </select>
      </div>
      <div className="sentiment-currentData-field">
        <span className="currentData-title">
          <FormattedMessage id="combo-trigger-modal.sentiment-volume" />
        </span>
        {currentToken ? volumeIcon : <span>?</span>}
      </div>
      <div className="sentiment-currentData-field">
        <span className="currentData-title">
          <FormattedMessage id="combo-trigger-modal.sentiment-sentiment" />
        </span>
        {currentToken ? sentimentIcon : <span>?</span>}
      </div>
    </section>
    <section className="sentiment-trigger-options">
      <h3><FormattedMessage id="combo-trigger-modal.sentiment-header" /></h3>
      <section className="sentiment-trigger-option">
        <div>
          <h2><FormattedMessage id="combo-trigger-modal.volume-is" /></h2>
          <select
            value={volumeComparison}
            onChange={(e) => {
              const type: any = e.target.value
              setVolumeComparison(type)
            }}
          >
            <FormattedMessage id="lower">
              {(text: string) => <option value="lower">{text}</option>}
            </FormattedMessage>
            <FormattedMessage id="higher">
              {(text: string) => <option value="higher">{text}</option>}
            </FormattedMessage>
          </select>
        </div>
        <div className="sentiment-buttons-group">
          <label>
            <input type="radio" name="volume" value={1} onChange={handleVolumeChange} checked={targetVolume === 1} />
            <span>1</span>
          </label>
          <label>
            <input type="radio" name="volume" value={2} onChange={handleVolumeChange} checked={targetVolume === 2} />
            <span>2</span>
          </label>
          <label>
            <input type="radio" name="volume" value={3} onChange={handleVolumeChange} checked={targetVolume === 3} />
            <span>3</span>
          </label>
          <label>
            <input type="radio" name="volume" value={4} onChange={handleVolumeChange} checked={targetVolume === 4} />
            <span>4</span>
          </label>
          <label>
            <input type="radio" name="volume" value={5} onChange={handleVolumeChange} checked={targetVolume === 5} />
            <span>5</span>
          </label>
        </div>
      </section>
      <section className="sentiment-trigger-mode">
        <button className={`${triggerMode === 'and' ? 'mode-selected' : ''}`} onClick={() => setTriggerMode("and")}>
          <FormattedMessage id="and" />
        </button>
        <button className={`${triggerMode === 'or' ? 'mode-selected' : ''}`} onClick={() => setTriggerMode("or")}>
          <FormattedMessage id="or" />
        </button>
      </section>
      <section className="sentiment-trigger-option">
        <div>
          <h2><FormattedMessage id="combo-trigger-modal.sentiment-is" /></h2>
          <select
            value={sentimentComparison}
            onChange={(e) => {
              const type: any = e.target.value
              setSentimenComparison(type)
            }}
          >
            <FormattedMessage id="lower">
              {(text: string) => <option value="lower">{text}</option>}
            </FormattedMessage>
            <FormattedMessage id="higher">
              {(text: string) => <option value="higher">{text}</option>}
            </FormattedMessage>
          </select>
        </div>
        <div className="sentiment-buttons-group">
          <label>
            <input
              type="radio"
              name="sentiment"
              value={AverageSentiment.veryLow}
              onChange={handleSentimentChange}
              checked={targetSentiment === AverageSentiment.veryLow}
            />
            <img src={veryLowIcon} />
          </label>
          <label>
            <input
              type="radio"
              name="sentiment"
              value={AverageSentiment.low}
              onChange={handleSentimentChange}
              checked={targetSentiment === AverageSentiment.low}
            />
            <img src={lowIcon} />
          </label>
          <label>
            <input
              type="radio"
              name="sentiment"
              value={AverageSentiment.neutral}
              onChange={handleSentimentChange}
              checked={targetSentiment === AverageSentiment.neutral}
            />
            <img src={neutralIcon} />
          </label>
          <label>
            <input
              type="radio"
              name="sentiment"
              value={AverageSentiment.high}
              onChange={handleSentimentChange}
              checked={targetSentiment === AverageSentiment.high}
            />
            <img src={highIcon} />
          </label>
          <label>
            <input
              type="radio"
              name="sentiment"
              value={AverageSentiment.veryHigh}
              onChange={handleSentimentChange}
              checked={targetSentiment === AverageSentiment.veryHigh}
            />
            <img src={veryHighIcon} />
          </label>
        </div>
      </section>

    </section>
  </div>
}

export default SentimentTriggerInput
