import React from 'react'
import { useLPUsdPrice } from 'src/hooks/useLPsLogs'
interface DepositedFarmLeftLogProps {
  lpDeposited: number
  myFarmId: string
  blockNumberAtDeposit: number
  graphLastBlock?: number
}
export const DepositedFarmLeftLog = ({ lpDeposited, myFarmId, blockNumberAtDeposit }: DepositedFarmLeftLogProps) => {
  const lpUSDPriceAtDeposit: number = useLPUsdPrice(myFarmId, blockNumberAtDeposit)
  const lpUSDValueAtDeposit: number = lpDeposited * lpUSDPriceAtDeposit

  return (
    <>
      <p className="main-text-logs">$ {(lpUSDValueAtDeposit).toFixed(3)}</p>
      <p>{lpDeposited.toFixed(12)} LPs</p>
    </>
  )
}

export const LoadingDepositFarmLeftLog = () => {
  return (
    <>
      <div className="logs-group-rows">
        <p>Loading deposited</p>
        <div className="left-empty-position"></div>
      </div>
    </>
  )
}
