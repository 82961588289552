import { BigNumber } from "ethers"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { ConditionProvider, DiagramElement, BaseNodeData, NodeType, NodeElement } from "src/routes/RecipeDiagram/helpers/types"
import { validateDiagram } from "src/store/actions/validation"

export interface ValidationError {
  node: DiagramElement
  errorType: errorType
}

type errorType = 'comboTriggerUndefined' | 'insufficientLiquidityLP' | 'missingAddFunds' | 'insufficientLiquidityAddFunds' | 'missingInput' | 'missingOutput'

export const useDiagramValidator = (nodes: NodeElement[]): ValidationError[] => {
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  useEffect(() => {
    let updatedErrors: Array<Promise<ValidationError[]>> = []
    for (const node of nodes) {
      const elementErrors = validateDiagramElement(node)
      updatedErrors = updatedErrors.concat(elementErrors)
    }
    Promise.all(updatedErrors)
      .then((errors) => {
        setValidationErrors(errors.reduce((acc, val) => acc.concat(val), []))
      })
      .catch((e) => console.error('Error in validation: ', e))
  }, [nodes])

  return validationErrors
}

export const useNodeValidator = (data: BaseNodeData, type: NodeType): boolean => {
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    const dataAsNode = { data, position: { x: 0, y: 0 }, id: '', type }
    validateDiagramElement(dataAsNode as NodeElement)
      .then((updatedErrors) => {
        if (updatedErrors.length > 0) {
          dispatch(validateDiagram(false))
        }
        setValidationErrors(updatedErrors)
      })
      .catch((e) => console.error('Error in useNodeValidator: ', e))
  }, [data, type, dispatch])

  return validationErrors.length <= 0
}

export const validateDiagramElement = async (nodeToValidate: NodeElement): Promise<ValidationError[]> => {
  const validationErrors: ValidationError[] = []
  if (!nodeToValidate.data.nextConnected) {
    validationErrors.push({ node: nodeToValidate, errorType: 'missingOutput' })
  }
  if (nodeToValidate.type !== 'addFundsNode' && !nodeToValidate.data.inputCoin) {
    validationErrors.push({ node: nodeToValidate, errorType: 'missingInput' })
  }

  switch (nodeToValidate.type) {
    case 'addFundsNode': {
      const amountBN = BigNumber.from(nodeToValidate.data.amount.toString())
      if (amountBN.isZero() || amountBN.isNegative()) {
        validationErrors.push({ node: nodeToValidate, errorType: "insufficientLiquidityAddFunds" })
      }
      break
    }
    case 'comboTriggerNode': {
      const { conditionProvider } = nodeToValidate.data
      if (!conditionProvider) {
        validationErrors.push({ node: nodeToValidate, errorType: 'comboTriggerUndefined' })
      }
      if (nodeToValidate.data.conditionProvider === ConditionProvider.chainlink) {
        const { condition } = nodeToValidate.data
        if (!condition || (!conditionProvider && !condition.coinToCompare)) {
          validationErrors.push({ node: nodeToValidate, errorType: 'comboTriggerUndefined' })
        }
        if (condition && condition.value <= 0) {
          validationErrors.push({ node: nodeToValidate, errorType: 'comboTriggerUndefined' })
        }
      } else if (nodeToValidate.data.conditionProvider === ConditionProvider.spookyswap) {
        const { condition } = nodeToValidate.data
        if (!condition || (!conditionProvider && !condition.farmID)) {
          validationErrors.push({ node: nodeToValidate, errorType: 'comboTriggerUndefined' })
        }
        if (condition && condition.value <= 0) {
          validationErrors.push({ node: nodeToValidate, errorType: 'comboTriggerUndefined' })
        }
      }

      break
    }
    case 'depositOnLPNode':
      break
    case 'farmNode':
      break
    case 'nestedStrategiesNode':
      break
    case 'splitNode':
      if (!nodeToValidate.data.secondNextConnected) {
        validationErrors.push({ node: nodeToValidate, errorType: 'missingOutput' })
      }
      break;
    default:
      break;
  }
  return validationErrors
}
