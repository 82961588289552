import React, { Suspense, useCallback, useEffect, useState } from "react"
import { useHistory, useParams, Redirect } from "react-router-dom"
import { FormattedMessage, FormattedNumber } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { useModal } from "@gluedigital/modal"
import ReactFlow, { Controls, ReactFlowProvider } from "react-flow-renderer"
import { useRecipeCRUD } from "src/api/recipes"
import { useDiagram } from "../helpers/useDiagram"
import { getElementsForAPIFromInstance } from "../helpers/instanceHelper"
import Sidebar from "../Sidebar/Sidebar"
import "src/components/modals/nodeModals/AddFundsModal"
import "src/components/modals/nodeModals/LiquidateModal"
import "src/components/modals/nodeModals/ComboTriggerModal"
import "src/components/modals/nodeModals/DepositOnLPModal"
import "src/components/modals/nodeModals/SwapModal"
import "src/components/modals/nodeModals/SplitModal"
import "src/components/modals/nodeModals/FarmModal"
import "src/components/modals/nodeModals/NestedStrategiesModal"
import "src/components/modals/nodeModals/SendToWalletModal"
import "src/components/modals/ItWorksModal"
import "src/components/modals/RecipeNameModal"
import "src/components/modals/ViewDescriptionModal"
import "src/components/modals/ValidationModal"
import "src/components/modals/AlertModal"

import "src/components/modals/modalsViewLogs/AddFundsModalLogs/AddFundsModalLog"
import "src/components/modals/modalsViewLogs/SwapModalLogs/SwapModalLog"
import "src/components/modals/modalsViewLogs/LiquidateModalLogs/LiquidateModalLog"
import "src/components/modals/modalsViewLogs/SplitModalLogs/SplitModalLog"
import "src/components/modals/modalsViewLogs/ComboModalLog/ComboTriggerModalLog"
import "src/components/modals/modalsViewLogs/FarmModalLog/FarmModalLog"
import "src/components/modals/modalsViewLogs/DepositModalLog/DepositModalLog"
import "./DnDFlow.sass"
import "../RecipePrivacyToggle/RecipePrivacyToggle.sass"
import { checkNetworkAndGetAccount } from "src/routes/login-helper"
import { setUserAccount } from "src/store/actions/user"
import SidebarViewLogs from "../SideBarViewLogs/SidebarViewLogs"
import SidebarViewDescription from "../SideBarViewDescription/SidebarViewDescription"
import editPencilImg from "src/static/images/lapiz.png"
import DiagramButtons from "./DiagramButtons"
import { Recipe, RecipeStatus } from "src/types"
import { useToast } from "src/hooks/useToast"
import { validateDiagram } from 'src/store/actions/validation'
import { useDiagramValidator } from "src/hooks/useValidator"
import useUnload from "src/hooks/useUnload"
import { useRecipeDetailsLive } from "src/context/SocketContext"
import LanguageSelector from "src/components/Landing/LanguageSelector/LanguageSelector"
import { edgeTypes, nodeTypes } from "../helpers/types"
import { useNodesDispatch, useNodesRecipe, useNodesState } from "../helpers/nodesAndEdgesContext"
import { useAbort } from "src/hooks/useAbort"
import DetailsHeader from "src/components/DnDFlow/DetailsHeader"
import { formatRecipeTitle } from "src/routes/routesHelper"
import { MetaRecipe } from "src/components/Meta/MetaRecipe"
import NoLoginConnectWallet from "src/components/Diagram/NoLoginConnectWallet/NoLoginConnectWallet"
import { ShareRecipe } from "src/components/DnDFlow/ShareRecipe"

const DnDFlowContent = () => {
  const modal = useModal()
  const account = useSelector((s: any) => (s.user ? s.user.account : undefined))
  const userAddress: string = account ? account?.account.slice(2) : undefined
  const recipeCRUD = useRecipeCRUD()
  const recipeDetails = useNodesRecipe()
  const { nodes, edges, hasUnsavedChanges } = useNodesState()
  const nodesDispatch = useNodesDispatch()
  const {
    reactFlowInstance,
    reactFlowWrapper,
    onConnect,
    onInit,
    onDragOver,
    onDrop,
    zoom,
    position,
    onNodesChange,
    onEdgesChange,
  } = useDiagram(nodes, edges);
  const history = useHistory()
  const { recipeName } = useParams()
  const [enable, setEnable] = useState(recipeDetails ? recipeDetails.public : false)
  const isEnabled = enable ? 'public' : 'private'
  const handlePrivacy = () => {
    if ((recipeDetails === null || userAddress === recipeDetails?.owner) && title !== '') {
      setEnable(!enable)
    }
  }
  const [title, setTitle] = useState<string>(recipeDetails ? recipeDetails.title : '');
  const [shortDescription, setShortDescription] = useState<string>(recipeDetails ? recipeDetails.short : '');
  const [extendedDescription, setExtendedDescription] = useState<string>(recipeDetails ? recipeDetails.extended : '');
  const [enableSaveWithTitle, setEnableSaveWithTitle] = useState<boolean>(false)
  const sendToast = useToast()
  const validationErrors = useDiagramValidator(nodes)
  const saveRecipe = useCallback(
    async () => {
      if (recipeDetails) {
        await recipeCRUD.update(recipeDetails.id, {
          title,
          short: shortDescription,
          extended: extendedDescription,
          code: await getElementsForAPIFromInstance(reactFlowInstance.toObject()),
          public: enable || false,
          status: validationErrors.length <= 0 ? 'ready' : 'draft',
        })
      } else {
        const res = await recipeCRUD.create({
          title: title || "Unnamed Recipe",
          short: shortDescription,
          extended: extendedDescription,
          code: await getElementsForAPIFromInstance(reactFlowInstance.toObject()),
          public: enable || false,
          status: validationErrors.length <= 0 ? 'ready' : 'draft'
        })
        const adaptTitle: string = formatRecipeTitle(res.title)
        sendToast(res.title, 'create')
        if (adaptTitle === '') {
          history.push('/recipe/' + res.id)
        } else {
          history.push('/recipe/' + res.id + '/' + adaptTitle)
        }
      }
      nodesDispatch({ type: 'saveChanges' })
    },
    [title, validationErrors.length, shortDescription, enable, extendedDescription, history, reactFlowInstance, nodesDispatch, recipeCRUD, recipeDetails, sendToast],
  )

  useEffect(() => {
    if (enableSaveWithTitle) {
      saveRecipe().catch(err => console.error(err.message))
      setEnableSaveWithTitle(false)
    }
  }, [enableSaveWithTitle, saveRecipe])

  const [isValid, setValid] = useState(false)

  const dispatch = useDispatch()

  useUnload(hasUnsavedChanges)

  const recipeDetailsLive = useRecipeDetailsLive(recipeDetails?.id.toString() || '')

  useEffect(() => {
    if (recipeDetails?.title && recipeDetails.title !== title) {
      setTitle(recipeDetails?.title)
    }
    if (!recipeDetailsLive) {
      return
    }
    if (recipeDetailsLive?.status === 'finished') {
      sendToast(recipeDetailsLive.title, 'execute-success')
    }
    if (recipeDetailsLive?.status === 'failed') {
      sendToast(recipeDetailsLive.title, 'execute-fail')
    }
  }, [recipeDetailsLive, recipeDetails, sendToast, title])

  useEffect(() => {
    checkNetworkAndGetAccount().then((updatedAcc) => {
      dispatch(setUserAccount(updatedAcc))
    }).catch(err => console.error(err.message))
  }, [dispatch])

  let comeBackRoute = 'your'
  if (recipeDetails?.owner !== userAddress) {
    comeBackRoute = 'browse'
  } else if (recipeDetails?.status === 'failed') {
    comeBackRoute = 'failed'
  } else if (recipeDetails?.status === 'finished') {
    comeBackRoute = 'liquidated'
  } else if (recipeDetails?.status === 'active') {
    comeBackRoute = 'active'
  }

  useEffect(() => {
    const diagramValid = validationErrors.length <= 0
    setValid(diagramValid)
    if (recipeDetails?.status === 'finished') dispatch(validateDiagram(diagramValid))
  }, [validationErrors, dispatch, recipeDetails?.status])

  const goBack = () => {
    if (hasUnsavedChanges) {
      modal.show('alert-modal', {
        bodyTextID: 'dnd-flow.unsaved-changes',
        cancelHandler: () => {
          modal.hide()
          history.push("/dashboard/" + comeBackRoute)
        },
        confirmHandler: () => {
          saveRecipe()
            .then(() => modal.hide())
            .then(() => history.push("/dashboard/" + comeBackRoute))
            .catch((e) => {
              console.error('Error on save: ', e)
              modal.hide()
            })
        },
      })
    } else {
      history.push("/dashboard/" + comeBackRoute)
    }
  }

  const validateRecipe = () => {
    const updatedValidity = validationErrors.length === 0
    setValid(updatedValidity)
    if (!updatedValidity) {
      modal.show('validation-modal', { errors: validationErrors })
    } else {
      dispatch(validateDiagram(true))
      modal.show("it-works-modal", { type: "success" })
    }
  }
  useEffect(() => {
    if (hasUnsavedChanges) {
      const updatedValidity = validationErrors.length <= 0
      setValid(updatedValidity)
    }
  }, [hasUnsavedChanges, validationErrors.length])

  const onSave = () => {
    setValid(validationErrors.length <= 0)

    modal.show('alert-modal', {
      bodyTextID: 'dnd-flow.update-confirmation',
      cancelHandler: () => modal.hide(),
      confirmHandler: async () => await saveRecipe().then(() => modal.hide()),
    })
  }

  const onRun = () => {
    const adaptTitle: string = formatRecipeTitle(recipeDetails?.title)
    if (hasUnsavedChanges) {
      modal.show('alert-modal', {
        bodyTextID: 'dnd-flow.unsaved-changes',
        cancelHandler: () => modal.hide(),
        confirmHandler: async () => await saveRecipe().then(() => {
          modal.hide()
          history.push({ pathname: "/recipe-summary/" + recipeDetails.id + '/' + adaptTitle })
        })
      })
    } else {
      history.push({ pathname: "/recipe-summary/" + recipeDetails.id + '/' + adaptTitle })
    }
  }

  const abort = useAbort(recipeDetails as Recipe)
  const abortHandler = async () => {
    await abort()
  }

  const editTitleHandler = (setTitle, setShortDescription, setExtendedDescription, setEnableSaveWithTitle, title, short, extended) => {
    modal.show("recipe-name-modal", { setters: { setTitle, setShortDescription, setExtendedDescription, setEnableSaveWithTitle }, currentValues: { title, short, extended }, })
  }

  if (recipeDetails) {
    const adaptTitle: string = formatRecipeTitle(recipeDetails?.title)
    const canonical: string = `/recipe/${recipeDetails?.id}/${adaptTitle}`
    if (recipeName && recipeName !== adaptTitle) return <Redirect to={canonical} />
  }

  return (
    <div className="dnd-layout">
      {recipeDetails && <MetaRecipe recipeDetails={recipeDetails} />}
      <header className="dnd-header">
        <section>
          <button className="button-back" onClick={goBack}>
            <span className="icon icon-angle_left" />
            <span><FormattedMessage id="back" /></span>
          </button>
          {recipeDetails?.public && <ShareRecipe recipeDetails={recipeDetails} />}
          {userAddress && <h2 className="account-number">{account.account.substr(0, 6) + "..." + account.account.slice(-4)}</h2>}
        </section>
        <section>
          {(recipeDetails?.status === "draft" || recipeDetails?.status === "ready" || recipeDetails === null)
            ?
            <div onClick={handlePrivacy} className={`recipe-privacy-toggle ${isEnabled}`}>
              <div className="text pr">
                <FormattedMessage id={`recipe-privacy-toggle.${isEnabled}`} />
              </div>
              <div className="switcher">
                <div className="switch" />
              </div>
            </div>
            :
            <div>
              {userAddress ?
                <span className={`privacy ${recipeDetails?.public ? 'public' : 'private'}-span`}>
                  {recipeDetails?.public ? 'Public' : 'Private'}
                </span>
                : <></>}
            </div>
          }
          <label>
            {!title
              ? <FormattedMessage id="dnd-flow.name-placeholder" />
              : title?.length > 53 ? `${title.slice(0, 50)}...` : title
            }
          </label>
          {(recipeDetails?.status !== "finished" && recipeDetails?.status !== "active" && recipeDetails?.status !== "failed") ?
            <button className="edit-button" onClick={() => editTitleHandler(setTitle, setShortDescription, setExtendedDescription, setEnableSaveWithTitle, title, shortDescription, extendedDescription)}>
              <img className="pencil-img" src={editPencilImg} alt="Edit name" /></button>
            :
            <DetailsHeader recipeDetails={recipeDetails} />
          }
        </section>
        <section className="balance">
          {userAddress
            ? <h1><FormattedNumber value={account.balance} style="decimal" /> <sup>FTM</sup> </h1>
            : <></>}
          <LanguageSelector iconRight contracted />
        </section>
      </header>
      {/* } <div className={`dnd-flow ${recipeDetails?.public && recipeDetails?.owner !== userAddress ? 'no-sidebar' : ''}`}> */}
      <div className="dnd-flow">
        <ReactFlowProvider>
          <Suspense fallback={<div />}>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onConnect={onConnect}
                onInit={onInit}
                defaultZoom={zoom}
                defaultPosition={position}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
              >
                <Controls className={"controls-custom"} />
              </ReactFlow>
            </div>
          </Suspense>
          <Suspense fallback={<div />}>
            {userAddress === undefined && <>
              <SidebarViewDescription recipeDetails={recipeDetails} userAddress={userAddress} />
              <NoLoginConnectWallet />
            </>
            }
            {
              recipeDetails && userAddress && (recipeDetails?.owner === userAddress) ?
                <>
                  {(recipeDetails && (recipeDetails.status === 'active' || recipeDetails.status === 'finished' || recipeDetails.status === 'failed' as RecipeStatus)) ?
                    <SidebarViewLogs nodes={nodes} recipeDetails={recipeDetails} />
                    :
                    <Sidebar />
                  }
                </>
                :
                <>
                  {
                    recipeDetails === null ?
                      <Sidebar />
                      :
                      <Suspense fallback={<></>}>
                        <SidebarViewDescription recipeDetails={recipeDetails} userAddress={userAddress} />
                      </Suspense>
                  }
                </>
            }
          </Suspense>
        </ReactFlowProvider>
      </div>
      <DiagramButtons
        status={recipeDetails?.status}
        isRecipeOwner={userAddress === recipeDetails?.owner}
        recipeExists={!!recipeDetails}
        isEmpty={nodes.length <= 0}
        isValid={isValid}
        validateRecipe={validateRecipe}
        onSave={onSave}
        onRun={onRun}
        onAbort={abortHandler}
      />
    </div>
  )
}

export const DnDFlow = () => {
  return <DnDFlowContent />
}
