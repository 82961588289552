import { ENVIRONMENT } from 'src/constants'
import Web3 from 'web3'
declare const ethereum: any

export const getLoginObject = async (account) => {
  const timestamp = Date.now()
  const params = [
    account,
    JSON.stringify({
      domain: {
        chainId: ENVIRONMENT.chainId,
        name: ENVIRONMENT.chainName,
        version: "1",
      },
      message: {
        logints: "Tortle-Login-" + timestamp,
      },
      primaryType: "LoginTs",
      types: {
        EIP712Domain: [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "chainId", type: "uint256" },
        ],
        LoginTs: [{ name: "logints", type: "string" }],
      },
    }),
  ]
  const method = "eth_signTypedData_v4"
  const res = await ethereum.request({ method, params, account })
  return {
    ts: timestamp,
    signature: res,
  }
}

export const changeChain = async () => {
  const loginParams = {
    chainId: ENVIRONMENT.chainId,
    chainName: ENVIRONMENT.chainName,
    rpcUrls: ENVIRONMENT.rpcUrls,
    blockExplorerUrls: ENVIRONMENT.blockExplorerUrls,
  }
  return ethereum.request({
    method: "wallet_addEthereumChain",
    params: [loginParams],
  })
}

export const checkNetworkAndGetAccount = async () => {
  const account = ethereum.selectedAddress
  const balance = await ethereum.request({
    method: "eth_getBalance",
    params: [account, "latest"],
  });
  const humanReadable = Web3.utils.fromWei(balance, 'ether')
  return { account, balance: humanReadable }
}
