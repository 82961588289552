import React from "react"
import { FormattedMessage } from "react-intl"
import { FarmData, PairDayData, TokenInfoSpooky } from "src/api/farms/types"
import { useEndBooAmountFromWithdrawEvent, useLpEarned } from "src/hooks/useFarmsLogs"
import { useGetTokenPriceUsd } from "src/hooks/useTokensLogsFromGraph"
interface FinalValueFarmModalLogProps {
  previousData: any
  executionSteps: number
  blockNumberAtOtherMoment: number
  masterchefInfo: PairDayData
  allFarmLPs: string[]
  lpPrice: number
}
export const FinalValueFarmModalLog = ({ previousData, masterchefInfo, executionSteps, allFarmLPs, lpPrice, blockNumberAtOtherMoment }: FinalValueFarmModalLogProps) => {
  const DECIMAL_USD_ROUNDED: number = 3

  const farmData: FarmData = useLpEarned(previousData.id, previousData.date, executionSteps) || { state: 'Pending', lpDeposited: 'No Info', earned: 'No Info' }
  const totalLpAmount: number = Number(farmData.lpDeposited.slice(0, -2)) + Number(farmData.earned.slice(0, -2))

  const rewardTokenData: TokenInfoSpooky = useGetTokenPriceUsd(masterchefInfo.tokenReward, blockNumberAtOtherMoment)
  const amountBOOWei: number = useEndBooAmountFromWithdrawEvent(masterchefInfo.pairAddress, allFarmLPs)
  const amountBOOEther: number = amountBOOWei / 10 ** 18
  console.log("BOO sent to user:", amountBOOEther)

  const finalUSDValue: number = totalLpAmount * lpPrice + amountBOOEther * parseFloat(rewardTokenData.priceUSD)
  return (
    <div id="farm-log-field">
      <h3><FormattedMessage id="farm-modal-log.final-value" /></h3>
      <strong className="span-coin">$ {finalUSDValue.toFixed(DECIMAL_USD_ROUNDED)} </strong>
      <div className="div-2column-lps">
        <span className="lp-amount-row first-column">{totalLpAmount.toFixed(12)} <strong>LPs</strong></span>
      </div>
    </div>
  )
}
