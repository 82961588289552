import React, { useCallback, useEffect, useState } from "react"
import web3 from "src/utils/web3"
import { ApprovalStatus, TokenForApproval } from "src/routes/RecipeDiagram/helpers/types"
import { useSelector } from "react-redux"
import { allowance, amountIsAlreadyApproved } from "../../contracts/ApproveHelpers"
import { FormattedMessage } from "react-intl"
import './ApprovesDepositList.sass'

interface ApprovesListProps {
  tokens: TokenForApproval[]
  setTokenStatus: (address: string, status: ApprovalStatus) => void
  hasInsufficientBalance: boolean
}

const ApprovesList = (props: ApprovesListProps) => {
  const { tokens, setTokenStatus, hasInsufficientBalance } = props
  const user = useSelector((s: any) => s.user)
  const userAddress = user.account.account
  const [showRejectedInfo, setShowRejectedInfo] = useState<boolean>(false)

  useEffect(() => {
    const findRejected = tokens.find((token) => token.status === "rejected")
    setShowRejectedInfo(findRejected !== undefined)
  }, [tokens])

  useEffect(() => {
    const pendingTokens = tokens.filter((token) => token.status !== 'approved')
    amountIsAlreadyApproved(web3, userAddress, pendingTokens, setTokenStatus)
      .catch(err => console.error(err.message))
  }, [setTokenStatus, tokens, userAddress])

  const handleAllowance = useCallback(() => {
    allowance(web3, userAddress, tokens, setTokenStatus)
      .catch(err => console.error(err.message))
  }, [setTokenStatus, tokens, userAddress])

  const chooseGapSize = (token: string): string => {
    if (token.length <= 3) return ''
    else if (token.length === 4) return 'short'
    else if (token.length === 5) return 'mid'
    else if (token.length > 5) return 'long'
  }

  return <div className="approval-list">
    <h3 className="approval-header"> <FormattedMessage id="recipe-summary.approves.header" /></h3>
    <div className="tokens-list">
      {
        tokens.map((token, index) => (
          <div className={`token-item ${chooseGapSize(token.id)}`} key={`${token.id}-${index}`}>
            <span className={`status-icon ${token.status}`} />
            <span className="token-tag">{token.id}</span>
            {token.status === "rejected" && <button className="icon-pregunta" onClick={() => window.open("https://docs.tortle.ninja/troubleshooting")}> </button> }
          </div>))
      }
    </div>
    {showRejectedInfo &&
      <div className="gas-info-div">
        <span className="gas-info"><FormattedMessage id="recipe-summary.gas-info" />
          <a
            href="https://docs.tortle.ninja/troubleshooting"
            target="_blank"
            rel="noopener noreferrer"
            className="help-link"
          >here.</a> </span>
      </div>}
    <button
      disabled={hasInsufficientBalance || !tokens.find((token) => token.status === 'ready' || token.status === 'rejected') || !!tokens.find((token) => token.status === 'pending')}
      onClick={handleAllowance}
    >
      <FormattedMessage id="recipe-summary.approves.button" />
    </button>
  </div>
}

export default ApprovesList
