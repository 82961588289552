const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case "login/success":
      return { ...state, ...action.payload }
    case "logout":
      return {}
    case "loadUserData":
      return { ...action.payload }
    case "set_account":
      return { ...state, account: action.payload }
    default:
      return state
  }
}
