import React from "react"
import { FormattedMessage } from "react-intl"
import { FarmData } from "src/api/farms/types"
import { useLpEarned } from "src/hooks/useFarmsLogs"

interface DepositedFarmModalLogProps {
  lpPrice: number
  previousData: any
  executionSteps: number
}

export const DepositedFarmModalLog = ({ lpPrice, previousData, executionSteps }: DepositedFarmModalLogProps) => {
  const DECIMAL_USD_ROUNDED: number = 3
  const farmData: FarmData = useLpEarned(previousData.id, previousData.date, executionSteps) || { state: 'Pending', lpDeposited: 'No Info', earned: 'No Info' }
  const lpAmount: string = /^\d/.test(farmData.lpDeposited) ? farmData.lpDeposited.slice(0, -2) : farmData.lpDeposited
  const lpUSDValue: number = Number(lpAmount) * lpPrice
  return (
    <div id="farm-log-field">
      <h3><FormattedMessage id="farm-modal-log.deposited" /></h3>
      <strong className="span-coin">$ {lpUSDValue.toFixed(DECIMAL_USD_ROUNDED)}</strong>
      <div><span className="lp-amount-row">{lpAmount} <strong>LPs</strong></span></div>
    </div>
  )
}
