import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { API_HOST } from "src/constants";
import { RecipeDetails, RecipeExecutionLog } from "src/types";

const base = API_HOST
const wsURL = `ws${base.substring(4)}/ws`

export const useRecipeDetailsLive = (recipeID: string) => {
  const [recipeDetails, setRecipeDetails] = useState<RecipeDetails>()
  const ctx = useContext(SocketContext)

  useEffect(() => {
    const handler = (message) => {
      setRecipeDetails(message.data)
    }
    const handleWrapper = (message) => {
      if (message.target === recipeID) {
        handler(message)
      }
    }
    ctx.subscribe('recipe-details', handleWrapper)

    return () => ctx.unsubscribe('recipe-details', handleWrapper)
  }, [recipeID])
  return recipeDetails
}

export const useAllRecipeDetailsLive = () => {
  const [lastRecipeChange, setLastChange] = useState<RecipeDetails>()
  const ctx = useContext(SocketContext)

  useEffect(() => {
    const handler = (message) => {
      setLastChange(message.data)
    }
    ctx.subscribe('recipe-details', handler)

    return () => ctx.unsubscribe('recipe-details', handler)
  }, [])
  return lastRecipeChange
}

export const useRecipeLogsLive = (recipeID: string) => {
  const [recipeLogs, setRecipeLogs] = useState<RecipeExecutionLog[]>([])
  const ctx = useContext(SocketContext)

  useEffect(() => {
    const handler = (message) => {
      const newLog = message.data
      setRecipeLogs((logs) => [...logs, newLog])
    }
    const handleWrapper = (message) => {
      if (message.target === recipeID) {
        handler(message)
      }
    }
    ctx.subscribe('recipe-logs', handleWrapper)
    return () => ctx.unsubscribe('recipe-logs', handleWrapper)
  }, [])
  return recipeLogs
}

const SocketContext = createContext(null)

export const SocketProvider: React.FunctionComponent = ({ children }) => {
  const handlers = useRef({})
  const token = useSelector((s: any) => (s.user?.token))

  const value = useMemo(() => ({
    subscribe: (eventType, callback) => {
      handlers.current = { ...handlers.current, [eventType]: [...(handlers.current?.[eventType] || []), callback] }
    },
    unsubscribe: (eventType, callback) => {
      handlers.current = { ...handlers.current, [eventType]: [...handlers.current[eventType].filter((cb) => cb !== callback)] }
    }
  }), [])

  useEffect(() => {
    if (!token) {
      return
    }
    let ws: WebSocket
    const connect = () => {
      ws = new WebSocket(wsURL, ['v1'])
      ws.onopen = () => {
        ws.send(JSON.stringify({ token }))
      }
      ws.onmessage = message => {
        const data = JSON.parse(message.data)
        const handlersForEvent = handlers.current[data.eventType]
        if (handlersForEvent) {
          handlersForEvent.forEach((callback) => callback(data))
        }
      }
      ws.onclose = (event) => {
        if (event.code !== 3000 && event.code !== 1000) {
          setTimeout(connect, 1000)
        }
      }
    }
    connect()
    const disconnect = () => {
      ws.close(1000, 'client log out')
    }
    return () => disconnect()
  }, [token])

  return <SocketContext.Provider value={value}>
    {children}
  </SocketContext.Provider>
}
