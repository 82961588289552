import React, { Suspense, useEffect, useRef, useState } from "react"
import { FormattedMessage } from "react-intl"
import { FarmGraphData, PairDayData } from "src/api/farms/types"
import { useFarmsOneDay } from "src/api/recipes"
import { APRCondition, ComboTriggerModalData, ConditionProvider } from "src/routes/RecipeDiagram/helpers/types"
import APRTriggerLabel from "./APRTriggerLabel"
import APRTriggerOptions from "./APRTriggerOptions"

interface APRTriggerInputProps {
  triggerData: ComboTriggerModalData
  setTriggerData: (data: ComboTriggerModalData) => void
}

export interface CurrentFarmInfo {
  label: string
  apr: string
}

const APRTriggerInput = (props: APRTriggerInputProps) => {
  const { triggerData, setTriggerData } = props
  const condition = triggerData ? triggerData.condition as APRCondition : {}
  const [inputRange, setInputRange] = useState([1, 1])
  const [inputConditionValue, setInputConditionValue] = useState(condition?.value || 'Value...')
  const [inputValue, setInputValue] = useState<number>(condition?.value)
  const [farmID, setFarmID] = useState(condition?.farmID)
  const [conditionType, setConditionType] = useState(condition?.type || 'lower')
  const [farmInfo, setFarmInfo] = useState<PairDayData[]>(undefined)
  const inputCondition = useRef<HTMLInputElement>(null)
  const formatter = new Intl.NumberFormat('en-EN', { maximumFractionDigits: 16 })

  const setInput = () => {
    let _value = inputCondition.current.value
    if (_value.slice(_value.length - 1) === '.') {
      for (let x = 0; x < _value.length - 1; x++) if (_value.substring(x, x + 1) === '.') return
      setInputConditionValue(_value)
      setInputRange([inputCondition.current.selectionStart, inputCondition.current.selectionEnd])
      return
    }
    _value = _value.replace(/,/g, '')
    const isDec = _value.split(".").length > 1
    if (isNaN(parseFloat(_value))) {
      _value = '0'
      setInputRange([inputRange[0] + 1, inputRange[1] + 1])
    } else if ((parseInt(_value).toString().length - 1) % 3 === 0) {
      setInputRange([inputCondition.current.selectionStart + 1, inputCondition.current.selectionEnd + 1])
    } else {
      setInputRange([inputCondition.current.selectionStart, inputCondition.current.selectionEnd])
    }
    setInputConditionValue(formatter.format(parseFloat(_value)))

    if (isDec && _value.length > 2 && _value.slice(_value.length - 1) === '0') {
      setInputConditionValue(_value)
    }
    const value = parseFloat(_value)
    const dif = formatter.format(parseFloat(_value)).length - inputConditionValue.toString().length - 1
    if (dif > 0) {
      setInputRange([inputCondition.current.selectionStart + dif, inputCondition.current.selectionEnd + dif])
    }
    setInputValue(value)
    setTriggerData({ condition: { value, farmID, type: conditionType }, conditionProvider: ConditionProvider.spookyswap })
  }
  useEffect(() => {
    inputCondition.current.setSelectionRange(inputRange[0], inputRange[1])
  }, [inputConditionValue, inputRange])

  useEffect(() => {
    setTriggerData({ condition: { type: conditionType, value: inputValue, farmID }, conditionProvider: ConditionProvider.spookyswap })
  }, [farmID, conditionType, setTriggerData, inputValue])

  const farmValidationCondition = (pair: PairDayData): boolean => {
    return (
      pair?.id !== undefined &&
      pair?.pairAddress !== undefined &&
      pair?.token0 !== undefined &&
      pair?.token1 !== undefined &&
      pair?.aprFarm !== undefined &&
      pair?.aprFees !== undefined
    )
  }
  const farmsTotalInfo: FarmGraphData = useFarmsOneDay()
  useEffect(() => {
    const farmData: PairDayData[] = farmsTotalInfo.pools.data
    setFarmInfo(farmData.filter((pair) => farmValidationCondition(pair)))
  }, [farmsTotalInfo])

  return <>
    <div className="combo-trigger-grid">
      <section>
      <h2><FormattedMessage id="deposit-lp-modal.provider" /></h2>
        <div className="spookyswap-provider">
          <FormattedMessage id="spookyswap" />
        </div>
      </section>
      <Suspense fallback={""}>
        <section>
          <h2><FormattedMessage id="combo-trigger-modal.says" /></h2>
          <select value={farmID} onChange={(e) => setFarmID(e.target.value)}>
            <FormattedMessage id="select.currency">
              {text => <option value="" hidden>{text}</option>}
            </FormattedMessage>
           {farmInfo && <APRTriggerOptions pairs={farmInfo} />}
          </select>
        </section>
      </Suspense>
    </div>
    <section>
      <h2><FormattedMessage id="combo-trigger-modal.is" /></h2>
      <select
        value={conditionType}
        onChange={(e) => {
          const type: any = e.target.value
          setConditionType(type)
        }}
      >
        <FormattedMessage id="lower">
          {(text: string) => <option value="lower">{text}</option>}
        </FormattedMessage>
        <FormattedMessage id="higher">
          {(text: string) => <option value="higher">{text}</option>}
        </FormattedMessage>
      </select>
      <div className="input-value">
        <FormattedMessage id="placeholder.amount">
          {() => (
            <input
              ref={inputCondition}
              value={inputConditionValue === 'Value...' ? 0 : inputConditionValue}
              onChange={() => {
                setInput()
              }}
              placeholder="Value..."
              min={0}
            />
          )}
        </FormattedMessage>
        <span>%</span>
      </div>
      <Suspense fallback="">
      {farmInfo && <APRTriggerLabel pairs={farmInfo} farmID={farmID} />}
      </Suspense>
    </section>
  </>
}

export default APRTriggerInput
