import Web3 from "web3"
import tokensImages from 'src/data/tokensImages.json'
import nestedStrategiesImages from 'src/data/nestedStrategiesImages.json'
export const tokenAddress = (address: string) => {
  return Web3.utils.toChecksumAddress(address.toLowerCase())
}

export const getTokenImage = (symbol: string) => {
  if (tokensImages.includes(`${symbol.toLowerCase()}.png`)) {
    return `/logos/tokens/tokensByName/${symbol.toLowerCase()}.png`
  }
  return `/logos/tokens/tokensByName/TOKEN.svg`
}

export const getNestedStrategyImage = (symbol: string) => {
  if (nestedStrategiesImages.includes(`${symbol.toLowerCase()}.png`)) {
    return `/logos/nestedStrategies/${symbol.toLowerCase()}.png`
  } else {
    return `/logos/tokens/tokensByName/TOKEN.svg`
  }
}
