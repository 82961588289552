import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'
import Analytics from './Analytics'

const Meta = () => {
  const intl = useIntl()

  const title = `Tortle Ninja | A new DeFi hero is Born!`

  const description = intl.formatMessage({ id: 'meta.description' })
  const keywords = intl.formatMessage({ id: 'meta.keywords' })

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
        />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="https://tortle.ninja/" />
        <meta property="og:image" content="https://i.imgur.com/l8DQaMn.jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:description" content={description} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="https://i.imgur.com/l8DQaMn.jpeg" />

        <title>{title}</title>
      </Helmet>
      <Analytics />
    </>
  )
}

export default Meta
