import networkAction from '@gluedigital/network-action'
import { API_HOST } from 'src/constants'

const redirectURL = process.env.REDIRECT_URL

export const apiAction = networkAction.withDefaults({
  baseUrl: API_HOST
})

export const withSession = (body) => (dispatch, getState) => {
  if (!getState().user.token) {
    window.location.replace(redirectURL)
  }
  let headers =
    body.fetchOptions && body.fetchOptions.header
      ? body.fetchOptions.header
      : {}

  headers = {
    ...headers
  }

  const fetchOptions = { ...body.fetchOptions, headers }
  return dispatch(
    apiAction({
      ...body,
      ...{ fetchOptions: fetchOptions, token: getState().user.token }
    })
  ).catch((error) => {
    if (error.status === 401) {
      window.location.replace(redirectURL)
    }
    throw error
  })
}
