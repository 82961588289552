import React from 'react'
import { Helmet } from 'react-helmet-async'
import { formatRecipeTitle } from 'src/routes/routesHelper'
import { RecipeDetails } from 'src/types'

interface MetaRecipeProps {
  recipeDetails: RecipeDetails
}

export const MetaRecipe = ({ recipeDetails }: MetaRecipeProps) => {
  const id: number = recipeDetails?.id
  const title: string = recipeDetails?.title
  const description: string = recipeDetails?.short ? recipeDetails.short : "No description"
  const url: string = "https://tortle.ninja/recipe/" + id + "/" + formatRecipeTitle(title)

  return (
    <>
      <Helmet>
        <title>{`Tortle Ninja: ${title}`}</title>
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="https://i.imgur.com/l8DQaMn.jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:type" content="website" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={url}/>
        <meta name="twitter:image" content="https://i.imgur.com/l8DQaMn.jpeg"/>
        {/* <meta property="twitter:title" content={title} /> */}
        {/* <meta property="twitter:description" content={description} /> */}
      </Helmet>
    </>
  )
}
