import React from "react"
import { FormattedMessage } from "react-intl"
import { NavLink } from "react-router-dom"
import { usePeriodicStats } from "src/api/recipes"
import LanguageSelector from "../Landing/LanguageSelector/LanguageSelector"

import "./SideMenu.sass"
import logo from "src/static/images/logo-2.png"
import active from "src/static/images/sideMenu-icons/active.png"
import browse from "src/static/images/sideMenu-icons/browse.png"
import liquidated from "src/static/images/sideMenu-icons/liquidated.png"
import yours from "src/static/images/sideMenu-icons/yours.png"
import failed from "src/static/images/sideMenu-icons/failed.png"

const SideMenu = () => {
  const stats = usePeriodicStats()
  return (
    <div id="side-menu">
      <img className="logo" src={logo} alt="Tortle Ninja logo" />
      <nav className="elements">
        <ul>
          <li>
            <NavLink activeClassName="selected" to="/dashboard/active">
              <img className="logo" src={active} alt="side menu active recipes icon" />
              <span><FormattedMessage id="menu.active" /></span>
              <span className="amount"> ({stats.active})</span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="selected" to="/dashboard/liquidated">
              <img className="logo" src={liquidated} alt="side menu liquidated recipes icon" />
              <span><FormattedMessage id="menu.liquidated" /></span>
              <span className="amount"> ({stats.finished})</span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="selected" to="/dashboard/your">
              <img className="logo" src={yours} alt="side menu your recipes icon" />
              <span><FormattedMessage id="menu.your" /></span>
              <span className="amount"> ({stats.ready + stats.draft})</span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="selected" to="/dashboard/failed">
              <img className="logo-failed" src={failed} alt="side menu failed recipes icon" />
              <span><FormattedMessage id="menu.failed" /></span>
              <span className="amount-failed"> ({stats.failed})</span>
            </NavLink>
          </li>
          <li className="browse">
            <NavLink activeClassName="selected" to="/dashboard/browse">
              <img className="logo" src={browse} alt="side menu browse recipes icon" />
              <span><FormattedMessage id="menu.browse" /></span>
              <span className="amount"> ({stats.available})</span>
            </NavLink>
          </li>
          <li className="language-selector-li">
            <LanguageSelector iconRight />
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default SideMenu
