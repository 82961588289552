import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { logout } from "src/store/actions/user"
import { useHistory } from "react-router-dom"
import { ENVIRONMENT } from 'src/constants'
import './AccountData.sass'
import { FormattedMessage } from 'react-intl'

interface AccountDataProps {
  account: string
}

const AccountData = ({ account }: AccountDataProps) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <div id="account-data" onClick={() => setOpen(!open)} className={open ? 'opened' : 'closed'}>
      <div className="account-number">
        {account.substr(0, 6) + "..." + account.slice(-4)}
      </div>
      <div className="account-actions">
        <a
          href={`${ENVIRONMENT.blockExplorerUrls}/address/${account}`}
          target="_blank"
          rel="noopener noreferrer"
          className="button"
        >
          <FormattedMessage id="account-data.view" />
        </a>
        <button className="disconnect" onClick={() => {
          dispatch(logout())
          history.push('/')
        }}><FormattedMessage id="disconnect" /></button>
      </div>
    </div>
  )
}

export default AccountData
