import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react"
import web3 from "src/utils/web3"
import { useSelector } from "react-redux"
import { useParams, useHistory, Redirect } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useRecipeCRUD, useRecipeDetails, useRecipeEstimation } from "src/api/recipes"
import { useModal } from "@gluedigital/modal"
import { getRecipeSummary } from "src/routes/RecipeDiagram/helpers/nodeHelpers"
import SummaryList from "./SummaryList/SummaryList"
import OnlyDesktopVeil from "src/components/OnlyDesktopVeil/OnlyDesktopVeil"
import ApprovesList from "src/components/RecipeSummary/ApprovesList"
import DepositList from "src/components/RecipeSummary/DepositList"
import { AddFundsElement, ApprovalStatus, TokenForApproval } from "../RecipeDiagram/helpers/types"
import { checkEnoughTokenBalance, checkFantomBalance } from "src/contracts/ApproveHelpers"
import EstimateGasLabel from "src/components/RecipeSummary/EstimateGasLabel"
import { useRecipeLogsLive } from "src/context/SocketContext"
import LoadingBar from "src/components/common/Loading/LoadingBar"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { ENVIRONMENT } from "src/constants"
import LoadingRecipeSummary from "./LoadingRecipeSummary"
import "src/components/modals/RecipeWorkingModal"
import "src/components/modals/WaitRecipeWorkingModal"
import "src/components/modals/RetryApprovalModal/RetryApprovalModal"
import "src/components/modals/CompleteModal"
import "./RecipeSummary.sass"
import { formatRecipeTitle } from "../routesHelper"

const RecipeSummary = () => {
  const { execute } = useRecipeCRUD()
  const user = useSelector((s: any) => s.user)

  const modal = useModal()
  const { id, recipeName } = useParams()
  const recipeDetails = useRecipeDetails(id)
  const summary: any[] = getRecipeSummary(recipeDetails.code)

  const [showBar, setShowBar] = useState(false)
  const [completionPercentage, setCompletion] = useState(0)
  const liveRecipeLogs = useRecipeLogsLive(id)

  const [fantomsForDeposit, initialTokensForApproval] = useMemo(() => {
    if (recipeDetails) {
      const addFundsNodes = recipeDetails.code.filter((node) => node.type === 'addFundsNode') as AddFundsElement[]
      const [fantom, tokens] = addFundsNodes.reduce((acc, currentNode) => {
        const { data } = currentNode
        if (data.outputCoin === 'FTM') {
          const fantomForDeposit: TokenForApproval = {
            amount: data.amount,
            status: "ready",
            id: "FTM",
            address: tokenNameToAddress(data.outputCoin)
          }
          acc[0].push(fantomForDeposit)
        } else {
          const tokenForApproval: TokenForApproval = {
            amount: data.amount,
            status: "ready",
            id: data.outputCoin,
            address: tokenNameToAddress(data.outputCoin)
          }
          const tokenAlreadyAdded: TokenForApproval = acc[1].find((token) => token.id === tokenForApproval.id)
          if (!tokenAlreadyAdded) acc[1].push(tokenForApproval)
          else {
            const tokenIndexInAcc: number = acc[1].indexOf(tokenAlreadyAdded)
            acc[1][tokenIndexInAcc].amount = (Number(acc[1][tokenIndexInAcc].amount) + Number(tokenForApproval.amount)).toString()
          }
        }
        return acc
      }, [[], []])
      if (fantom.length > 1) {
        const newFantom = {
          address: fantom[0].address,
          id: fantom[0].id,
          status: fantom[0].ready,
          amount: null
        }
        for (let i = 0; i < fantom.length; i++) {
          newFantom.amount += Number(fantom[i].amount)
        }
        newFantom.amount = newFantom.amount.toString()
        return [newFantom, tokens]
      }
      return [fantom?.[0], tokens]
    }
  }, [recipeDetails])

  const [tokensForApproval, setTokensForApproval] = useState<TokenForApproval[]>(initialTokensForApproval)
  const setTokenStatus = useCallback((address: string, status: ApprovalStatus) => {
    setTokensForApproval((previousTokens) => {
      const targetToken = previousTokens.find((token) => token.address === address)
      if (!targetToken) {
        return previousTokens
      }
      const updatedTokens = previousTokens.filter((token) => token.address !== address)
        .concat({ ...targetToken, status })
      return updatedTokens
    })
  }, [])
  const [hasInsufficientBalance, setTokensWithInsufficientBalance] = useState<TokenForApproval[]>([])

  const isAllApproved = useMemo(() => {
    return tokensForApproval.filter((token) => token.status !== 'approved').length <= 0
  }, [tokensForApproval])

  useEffect(() => {
    tokensForApproval.forEach((token: TokenForApproval) => {
      checkEnoughTokenBalance(web3, user.account.account, token).then((hasEnoughBalance) => {
        if (!hasEnoughBalance) {
          setTokensWithInsufficientBalance((insufficientBalanceTokens) => {
            const filteredTokens = insufficientBalanceTokens
              .filter((insufficientBalanceToken) => insufficientBalanceToken.id !== token.id)
              .concat(token)
            return [...filteredTokens]
          })
        }
      })
        .catch((e) => console.error('Error while checking token balance: ', e))
    })
  }, [tokensForApproval, user.account.account])

  useEffect(() => {
    if (fantomsForDeposit) {
      checkFantomBalance(web3, user.account.balance, fantomsForDeposit).then((hasEnoughBalance) => {
        if (!hasEnoughBalance) {
          setTokensWithInsufficientBalance((insufficientBalanceTokens) => {
            const filteredTokens = insufficientBalanceTokens
              .filter((insufficientBalanceToken) => insufficientBalanceToken.id !== 'FTM')
              .concat(fantomsForDeposit)
            return [...filteredTokens]
          })
        }
      })
        .catch((e) => console.error('Error while checking fantom balance: ', e))
    }
  }, [fantomsForDeposit, user.account.balance])

  const goBackHandler = () => {
    const adaptTitle: string = formatRecipeTitle(recipeDetails.title)
    if (fantomsForDeposit) {
      modal.show('alert-modal', {
        bodyTextID: 'recipe-summary.pending-deposit',
        cancelHandler: () => {
          modal.hide()
        },
        confirmHandler: () => {
          modal.hide()
          history.push("/recipe/" + id + '/' + adaptTitle)
        }
      })
    } else {
      history.push("/recipe/" + id + '/' + adaptTitle)
    }
  }
  const history = useHistory()

  const [transactionHash, setTransactionHash] = useState<string>()
  useEffect(() => {
    if (liveRecipeLogs.length <= 0) {
      return
    }
    let loadBarTimeout
    let modalTimeout
    if (liveRecipeLogs[liveRecipeLogs.length - 1].logtype === 'fail') {
      setShowBar(false)
      modal.show('recipe-not-working-modal', { recipeID: id })
    } else if (liveRecipeLogs.length > 0) {
      const eventCount = liveRecipeLogs.reduce((totalCount, currentLog) => totalCount + currentLog.events.length, 0)
      if (eventCount > 0) {
        loadBarTimeout = setTimeout(() => setCompletion(100), 1000)
        modalTimeout = setTimeout(() => {
          modal.show('recipe-working-modal', { recipeID: id, transactionHash })
          setShowBar(false)
        }, 4000)
      } else {
        setTransactionHash(liveRecipeLogs[0].trans)
      }
    }
    return () => {
      clearTimeout(loadBarTimeout)
      clearTimeout(modalTimeout)
    }
  }, [id, liveRecipeLogs, modal])

  useEffect(() => {
    if (!transactionHash) {
      return
    }
    let modalTimeout
    web3.eth.getTransactionReceipt(transactionHash)
      .then((err, res) => {
        if (err) {
          console.error('Error on transaction: ', err)
          modal.show('recipe-not-working-modal', { recipeID: id })
          return
        }
        if (!res) {
          console.log('Transaction is pending')
          return
        }
        setCompletion(100)
        modalTimeout = setTimeout(() => {
          modal.show('recipe-working-modal', { recipeID: id })
          setShowBar(false)
        }, 3000)
      })
    return () => clearTimeout(modalTimeout)
  }, [transactionHash])
  const handleDeposit = () => {
    setShowBar(true)
    execute(id).catch(() => setShowBar(false))
  }

  const gasEstimation = useRecipeEstimation(isAllApproved ? id : '')

  const adaptTitle: string = formatRecipeTitle(recipeDetails.title)
  if (recipeDetails) {
    const canonical: string = `/recipe-summary/${recipeDetails?.id}/${adaptTitle}`
    if (recipeName && recipeName !== adaptTitle) return <Redirect to={canonical} />
  }

  return (
    <div id="recipe-summary" className="page">
      {hasInsufficientBalance.length > 0 &&
        <div className="insufficient-funds-toast">
          <span className="exclamation" />
          <span><FormattedMessage id="recipe-summary.insufficient-funds" /> {hasInsufficientBalance.map((token) => `${token.id}, `)}</span>
        </div>}
      <a className="back" onClick={goBackHandler}>
        <span className="icon icon-arrow-right" />
        <span>
          <FormattedMessage id="recipe-summary.back" />
        </span>
      </a>
      {!showBar && <h1>
        <FormattedMessage id="recipe-summary.title" />
      </h1>}
      {showBar && <LoadingBar completionPercentage={completionPercentage} />}
      {transactionHash &&
        <span className="transaction-link-container"><FormattedMessage id="recipe-summary.transaction-link" /><a
          className="transaction-link"
          href={`${ENVIRONMENT.blockExplorerUrls}tx/${transactionHash}`}
          target="_blank"
          rel="noreferrer noopener">FTMScan</a>
          <span className="transaction-status-container">
            <FormattedMessage id="recipe-summary.transaction-pending" />
            <span className="status-icon" />
          </span>
        </span>
      }
      {!fantomsForDeposit && <EstimateGasLabel result={gasEstimation} />}
      <SummaryList summary={summary} />
      {isAllApproved && !fantomsForDeposit && (gasEstimation && gasEstimation.estimationFTM !== 'error')
        ? <div className="complete-card">
          <h2>
            <FormattedMessage id="recipe-summary.complete-card.title" />
          </h2>
          <p>
            <FormattedMessage id="recipe-summary.complete-card.text" />
          </p>
          <button disabled={showBar} onClick={() => {
            setShowBar(true)
            execute(id).catch(() => setShowBar(false))
          }}>
            <FormattedMessage id="recipe-summary.complete-card.complete" />
          </button>
        </div>
        : <div className="recipe-summary-approvals">
          {tokensForApproval.length > 0
            && <ApprovesList
              tokens={tokensForApproval}
              setTokenStatus={setTokenStatus}
              hasInsufficientBalance={hasInsufficientBalance.length > 0}
            />
          }
          {fantomsForDeposit
            && <DepositList
              amount={fantomsForDeposit.amount}
              isAllApproved={isAllApproved}
              hasInsufficientBalance={hasInsufficientBalance.length > 0}
              handleDeposit={handleDeposit}
              // estimationSuccess={gasEstimation !== 'loading' && gasEstimation !== 'failed'}
              estimationSuccess={true}
            />
          }
        </div>
      }
      <OnlyDesktopVeil />
    </div>
  )
}

const RecipeSummaryWrapper = (props) => (
  <Suspense fallback={<LoadingRecipeSummary />}>
    <RecipeSummary {...props} />
  </Suspense>
)

export default RecipeSummaryWrapper
