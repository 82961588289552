
import React from "react"
import { FormattedMessage } from "react-intl"
import { getNestedStrategyImage } from "src/api/farms/helpers"
import { YearnYDaemonVaultsData } from "src/api/yearnVaults/yearnTypes"

import './ListNestedStrategiesModal.sass'

interface ListNestedStrategiesModalProps {
  vault: YearnYDaemonVaultsData
  isChosen: boolean
  setChosenOption: (option: YearnYDaemonVaultsData) => void
  featured?: boolean
}

export const ListNestedStrategiesModal = ({ vault, isChosen, setChosenOption, featured }: ListNestedStrategiesModalProps) => {
  const isLongName: boolean = (vault.formated_name).length > 15
  return (
    <li className={`nested-strategies-info-card ${featured ? 'featured' : ''}`}>
      {featured && <span className="featured-nested-strategy-tag"><FormattedMessage id="farm-modal.featured" /></span>}
      <section className="token-container">
        <div className="logos">
          <img className="logo" src={getNestedStrategyImage(vault.display_name)} />
          {/* <img className="logo" src={vault.icon} /> */}
        </div>
        <h1 className={`pair-names-container ${isLongName ? 'long' : ''}`}>
          {vault.formated_name}
        </h1>
      </section>
      <section className="section-data">
        <h2 className="data-title">
          <FormattedMessage id="nested-strategies-modal.APY" />
        </h2>
        <strong>{(vault.apy).toFixed(2) } %</strong>
      </section>
      <section className="section-data">
        <h2 className="data-title">
          <span><FormattedMessage id="nested-strategies-modal.Total-assets" /></span>
        </h2>
        <strong >$ { Number(vault.tvl.total_assets_usd).toLocaleString() }</strong>
      </section>
      <section className="section-data">
        <h2 className="data-title">
          <span><FormattedMessage id="nested-strategies-modal.avToDeposit" /></span>
        </h2>
        <strong>---</strong>
      </section>
      <section className="section-data">
        <h2 className="data-title">
          <span><FormattedMessage id="deposit-lp-modal.provider" /></span>
        </h2>
        <strong>{"YEARN"}</strong>
      </section>
      <section className="button-section">
        <button
          className={isChosen ? 'filled' : ''}
          onClick={() => { setChosenOption(vault) }}>
          {!isChosen ? <FormattedMessage id="choose" /> : <FormattedMessage id="chosen" />}
        </button>
      </section>
    </li>

  )
}
