import React, { Suspense } from "react";
import { FormattedMessage } from "react-intl";
import { useRecipePerformance } from "src/api/recipes";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

interface RecipeItemPerformanceProps {
  recipeID: number
}
const RecipeItemPerformance = ({ recipeID }: RecipeItemPerformanceProps) => {
  const { performance } = useRecipePerformance(recipeID)
  const { apr, earned } = performance
  return (
    <>
      {apr === null
        ?
        <div className="recipe-item-performance-bottom">
          <FormattedMessage id="recipe-item.apr-error" />
        </div>
        :
        <div className="recipe-item-performance-bottom">
          <div className="recipe-item-performance">
            <span className={`recipe-item-apr ${apr > 0 ? 'positive' : ''}`}>
              <FormattedMessage id="recipe-item.apr-front" values={{ n: (apr * 100).toFixed(2) }} />
            </span>
            <span className="recipe-item-earned">
              <FormattedMessage id="recipe-item.earned" values={{ n: earned.toFixed(3) }} />
            </span>
          </div>
        </div>}
    </>
  )
}
const RecipeItemPerformanceWrapper = (props: RecipeItemPerformanceProps) => {
  return <ErrorBoundary
    fallback={<div className="recipe-item-performance-bottom">
      <FormattedMessage id="recipe-item.apr-error" />
    </div>}
  >
    <Suspense fallback={<span className="performance-loading" />}>
      <RecipeItemPerformance {...props} />
    </Suspense>
  </ErrorBoundary>
}
export default RecipeItemPerformanceWrapper
