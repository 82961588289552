import { FormattedMessage } from "react-intl"
import React from "react"
import "./FarmLogField.sass"
import { FarmData, PairDayData, TokenInfoSpooky } from "src/api/farms/types"
import { useBooAmountFromStrategySC, useLpEarned } from "src/hooks/useFarmsLogs"
import { useGetTokenPriceUsd } from "src/hooks/useTokensLogsFromGraph"
import { tokenAddressToName } from "src/components/Diagram/nodes/nodesLogsHelper"

interface CurrentValueFarmModalLogProps {
  lpPrice: number
  masterchefInfo: PairDayData
  blockNumberAtOtherMoment: number
  executionSteps: number
  previousData: any
  allFarmLPs: string[]
}

export const CurrentValueFarmModalLog = ({ lpPrice, masterchefInfo, blockNumberAtOtherMoment, executionSteps, previousData, allFarmLPs }: CurrentValueFarmModalLogProps) => {
  const myFarmAddress: string = previousData?.data?.pair?.id.slice(0, -6)
  const DECIMAL_USD_ROUNDED: number = 3
  const DECIMAL_TOKEN_ROUNDED: number = 12
  const farmData: FarmData = useLpEarned(previousData.id, previousData.date, executionSteps) || { state: 'Pending', lpDeposited: 'No Info', earned: 'No Info' }
  const totalLpAmount: number = Number(farmData.lpDeposited.slice(0, -2)) + Number(farmData.earned.slice(0, -2))

  const rewardToken: string = tokenAddressToName(masterchefInfo.tokenReward)
  const rewardTokenData: TokenInfoSpooky = useGetTokenPriceUsd(masterchefInfo.tokenReward, blockNumberAtOtherMoment)
  const amountBOOWei: number = useBooAmountFromStrategySC(myFarmAddress, allFarmLPs)
  const amountBOOEther: number = amountBOOWei / 10 ** 18

  const totalLpUsdValue: number = totalLpAmount * lpPrice
  const booUSDValue: number = amountBOOEther * parseFloat(rewardTokenData.priceUSD)
  const totalUsdValue: number = totalLpUsdValue + booUSDValue

  const firstRow: JSX.Element = <strong className="span-coin">${totalUsdValue.toFixed(DECIMAL_USD_ROUNDED)} </strong>

  const secondRow: JSX.Element = <div className="div-2column-lps">
    <span className="lp-amount-row first-column">{totalLpAmount.toFixed(DECIMAL_TOKEN_ROUNDED)} <strong>LPs</strong></span>
    <span className="label-lp-price second-column">${totalLpUsdValue.toFixed(DECIMAL_USD_ROUNDED)} </span>
  </div>

  const thirdRow: JSX.Element = <div className="div-2column-lps">
    <span className="lp-amount-row first-column">{amountBOOEther.toFixed(DECIMAL_TOKEN_ROUNDED)} <strong>{rewardToken}</strong> </span>
    <span className="label-lp-price second-column">$ {booUSDValue.toFixed(DECIMAL_USD_ROUNDED)}</span>
  </div>

  return (
    <div id="farm-log-field">
      <h3><FormattedMessage id="farm-modal-log.current-value" /></h3>
      {firstRow}
      <> {secondRow} </>
      <> {thirdRow} </>
    </div>
  )
}
