import React, { memo, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Handle } from "react-flow-renderer";
import { ComboTriggerNodeData, ConditionProvider } from "src/routes/RecipeDiagram/helpers/types";
import Buttons from "./Buttons"
import { obtainRecipeDetails, obtainRecipeLogs } from "src/routes/RecipeDiagram/helpers/obtainRecipeLogs";
import { useCompareAccounts } from "./nodesLogsHelper";
import { useDispatch, useSelector } from "react-redux";
import { useNodeValidator } from "src/hooks/useValidator";
import { focusNode } from "src/store/actions/diagram";
import NodeExecutionTag from "./NodeExecutionTag";

import "./ComboTriggerNode.sass";
interface ComboTriggerNodeProps {
  data: ComboTriggerNodeData
  id: string
}

export default memo(({ id, data }: ComboTriggerNodeProps) => {
  const [showButtons, setShowButtons] = useState(false);

  const focusedNode = useSelector((state: any) => state.diagram.focused)
  const account = useSelector((s: any) => (s.user ? s.user.account : undefined))

  const dispatch = useDispatch()

  const userAddress = account?.account.slice(2)
  const diagramisValid = useSelector((s: any) => s.validation.validated)

  const positionTarget: any = "left";
  const positionSource: any = "right";

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setShowButtons(false)
      dispatch(focusNode())
    }
  }

  const handleShowButtons = () => {
    dispatch(focusNode(id))
    if (compareAddresses) {
      setShowButtons(true)
    }
  }

  const recipeLogs = obtainRecipeLogs();
  const recipeDetails = obtainRecipeDetails()
  const compareAddresses: boolean = useCompareAccounts(userAddress)

  const isValid = useNodeValidator(data, "comboTriggerNode")
  const handleNodeStyle = useMemo(() => {
    if (!compareAddresses) {
      return ''
    } else if (recipeDetails?.status === 'failed') {
      return 'node-handle-failed'
    } else if (diagramisValid || recipeDetails?.status === 'active') {
      return 'handle-valid'
    } else if (data.nextConnected) {
      return 'handle-connected'
    } else {
      return 'node-handle-private'
    }
  }, [compareAddresses, diagramisValid, data.nextConnected])

  const editModalType: string = useMemo(() => {
    switch (data.conditionProvider) {
      case ConditionProvider.chainlink:
        return 'combo-trigger-modal-pricefeeds'
      case ConditionProvider.sensei:
        return 'combo-trigger-modal-sentiment'
      case ConditionProvider.spookyswap:
        return 'combo-trigger-modal-aprbased'
      case ConditionProvider.time:
        return 'combo-trigger-modal-timebased'
      case ConditionProvider.user:
        return 'combo-trigger-modal-manualexecution'
      case ConditionProvider.tortle:
        return 'combo-trigger-modal-profit'
      default:
        return 'combo-trigger-modal'
    }
  }, [data.conditionProvider])

  return (
    <div className={`node ${focusedNode && focusedNode !== id ? 'node-unfocused' : ''}`} onClick={handleShowButtons} onBlur={handleBlur} tabIndex={0}>
      <NodeExecutionTag nodeID={id.toString()} />
      <div className={"combo-trigger"}>
        {compareAddresses && !isValid && <span className="icon icon-exclamation" />}
        <Handle style={{ top: 50 }} className={`node-handle ${handleNodeStyle} ${data.outputCoin && compareAddresses && 'handle-connected'}`} type="target" position={positionTarget} id="combo-input" />
        <Handle style={{ top: 50 }} className={`node-handle ${handleNodeStyle}`} type="source" position={positionSource} id="combo-output" />
      </div>
      <div className="node-info">
        <FormattedMessage id="node.comboTriggerNode" /> {data.outputCoin}
      </div>
      {
        showButtons &&
        <Buttons
          data={data} id={id}
          editModalType={editModalType}
          recipeLogs={recipeLogs}
          recipeDetails={recipeDetails}
          viewDetailsModalType={"combo-trigger-modal-log"}
        />
      }
    </div>
  );
});
