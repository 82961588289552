import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import './ErrorBoundary.sass'
import logo from 'src/static/images/logo_one-line.svg'
import toy from 'src/static/images/not-found_toy.png'

declare const __DEV__: boolean

interface MyProps {
  children: React.ReactNode
  fallback?: any
}

interface MyState {
  hasError: boolean
  details?: Error
}

export interface FallbackProps {
  error: Error
}
class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static propTypes = {
    children: PropTypes.node
  }

  static getDerivedStateFromError(error) {
    if (error.toString().includes('401')) {
      localStorage.removeItem('user')
      window.setTimeout(() => window.location.reload(), 0)
    }
    return {
      hasError: true,
      details: error
    }
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback
      } else {
        return <DefaultFallback error={this.state.details} />
      }
    }
    return this.props.children
  }
}

const DefaultFallback = ({ error }: FallbackProps) => {
  const handleReload = () => {
    localStorage.removeItem('user')
    window.setTimeout(() => window.location.reload(), 0)
  }
  return (
    <div className="error page">
      <div className="split">
        <div className="item left">
          <img className="logo" src={logo} alt="Tortle Ninja logo" />
          <h1><FormattedMessage id="error" /></h1>
          {__DEV__ && error &&
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            <div>{error.toString()}</div>
          }
           <div className="elements">
           <a className="troubleshooting" href="https://docs.tortle.ninja/troubleshooting"><FormattedMessage id="text-troubleshooting" /></a>
           <div className="buttons">
          <Link to="/" className="button" onClick={handleReload}>
            <span><FormattedMessage id="reload" /></span>
          </Link>
          <a href="https://discord.com/invite/PWvkZHwS" className="button-report" >
          <FormattedMessage id="report" />
        </a>
        </div>
        </div>
        </div>
        <div className="item right">
          <img className="toy" src={toy} alt="Tortle Ninja logo" />
        </div>
      </div>
    </div>
  )
}

interface ModalProp {
  children: React.ReactNode
}

interface SideBarProp {
  children: React.ReactNode
  type?: any
  data?: any
}

export const ModalErrorBoundary = ({ children }: ModalProp) =>
  <ErrorBoundary fallback={<ModalFallback />}>{children}</ErrorBoundary>

export const SideBarErrorBoundary = ({ children, type, data }: SideBarProp) =>
  <ErrorBoundary fallback={<SideBarFallback type={type} data={data} />}>{children}</ErrorBoundary>

const ModalFallback = () => {
  const handleReload = () => {
    localStorage.removeItem('user')
    window.setTimeout(() => window.location.reload(), 0)
  }
  return (
    <div className="modal-error">
      <div className="error-message">
        <FormattedMessage id="tortle-ninja-error" />
      </div>
      <div className="elements">
      <a className="troubleshooting" href="https://docs.tortle.ninja/troubleshooting"><FormattedMessage id="text-troubleshooting" /></a>
      <div className="buttons">
        <Link to="/" className="button-reload" onClick={handleReload}>
          <FormattedMessage id="reload" />
        </Link>
        <a href="https://discord.com/invite/PWvkZHwS" className="button-report" >
          <FormattedMessage id="report" />
        </a>
      </div>
      </div>
    </div>
  )
}

interface SideBarFallBackProp {
  type?: any
  data?: any
}

const SideBarFallback = ({ type, data }: SideBarFallBackProp) => {
  let logBody: JSX.Element
  let typeFunction: string
  switch (type) {
    case "splitNode":
      typeFunction = "Split"
      logBody = <span className="span-nodesData">
        {`${data.inputCoin}`} <span className="span-extraInfo">  <FormattedMessage id="view-details-to" /> </span>
        <span>{`${data?.firstPercentage}% ${data?.firstCoin} `}</span> <span className="span-extraInfo"> <FormattedMessage id="view-details-&" /> </span>
        <span>{`${data?.secondPercentage}% ${data?.secondCoin} `}</span> </span>
      break
    case "swapNode":
      typeFunction = "Swap"
      logBody = <span className="span-nodesData">
        {`${data.inputCoin}`} <span className="span-extraInfo">  <FormattedMessage id="view-details-to" /> </span>
        <span>{`${data.outputCoin}`}</span> </span>
      break
    case "depositOnLPNode":
      typeFunction = "Deposit on LP"
      logBody = <span className="span-nodesData">
        {`${data.pair.token0}-${data.pair.token1}`}
        <div></div> </span>
      break
    case "farmNode":
      typeFunction = "Farm"
      logBody = <span className="span-nodesData">
        {`${data.pair.token0}-${data.pair.token1}`} </span>
      break
  }
  return (
    <div className="side-bar-error">
      <div className="info-function">
        <span className="typeFunction">{typeFunction}</span>
        <span className="logBody">{logBody}</span>
      </div>
      <div className="elements">
        <span className="icon icon-exclamation" />
        <div className="error-texts">
          <div className="error-text-1">
            <FormattedMessage id="error-side-bar-text-1" />
          </div>
          <div className="error-text-2">
            <FormattedMessage id="error-side-bar-text-2" />
          </div>
          <a className="troubleshooting" href="https://docs.tortle.ninja/troubleshooting"><FormattedMessage id="text-troubleshooting" /></a>
        </div>
      </div>
    </div >
  )
}

export default ErrorBoundary
