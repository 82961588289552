import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { TokenPrice, usePricesCombo } from "src/api/comboTrigger/comboApi";
import { ComboTriggerModalData, ConditionProvider, TokenCondition } from "src/routes/RecipeDiagram/helpers/types";

interface TriggerTokenInputProps {
  triggerData: ComboTriggerModalData
  setTriggerData: (data: ComboTriggerModalData) => void
}

const TriggerTokenInput = (props: TriggerTokenInputProps) => {
  const { triggerData, setTriggerData } = props
  const condition = triggerData ? triggerData.condition as TokenCondition : {}
  const [inputRange, setInputRange] = useState([1, 1])
  const [inputConditionValue, setInputConditionValue] = useState(condition?.value || 'Value...')
  const [inputValue, setInputValue] = useState<number>(condition?.value)
  const [coinToCompare, setCoinToCompare] = useState(condition?.coinToCompare)
  const [conditionType, setConditionType] = useState(condition?.type || 'lower')
  const inputCondition = useRef<HTMLInputElement>(null)
  const formatter = new Intl.NumberFormat('en-EN', { maximumFractionDigits: 16 })

  const coinPrices: TokenPrice[] = usePricesCombo()
  const [currentPrice, setCurrentPrice] = useState<string>("")

  const setInput = () => {
    let _value = inputCondition.current.value
    if (_value.slice(_value.length - 1) === '.') {
      for (let x = 0; x < _value.length - 1; x++) if (_value.substring(x, x + 1) === '.') return
      setInputConditionValue(_value)
      setInputRange([inputCondition.current.selectionStart, inputCondition.current.selectionEnd])
      return
    }
    _value = _value.replace(/,/g, '')
    const isDec = _value.split(".").length > 1
    if (isNaN(parseFloat(_value))) {
      _value = '0'
      setInputRange([inputRange[0] + 1, inputRange[1] + 1])
    } else if ((parseInt(_value).toString().length - 1) % 3 === 0) {
      setInputRange([inputCondition.current.selectionStart + 1, inputCondition.current.selectionEnd + 1])
    } else {
      setInputRange([inputCondition.current.selectionStart, inputCondition.current.selectionEnd])
    }
    setInputConditionValue(formatter.format(parseFloat(_value)))

    if (isDec && _value.length > 2 && _value.slice(_value.length - 1) === '0') {
      setInputConditionValue(_value)
    }
    const value = parseFloat(_value)
    const dif = formatter.format(parseFloat(_value)).length - inputConditionValue.toString().length - 1
    if (dif > 0) {
      setInputRange([inputCondition.current.selectionStart + dif, inputCondition.current.selectionEnd + dif])
    }
    setInputValue(value)
    setTriggerData({ condition: { value, coinToCompare, type: conditionType }, conditionProvider: ConditionProvider.chainlink })
  }
  useEffect(() => {
    inputCondition.current.setSelectionRange(inputRange[0], inputRange[1])
  }, [inputConditionValue, inputRange]);

  useEffect(() => {
    setTriggerData({ condition: { type: conditionType, value: inputValue, coinToCompare }, conditionProvider: ConditionProvider.chainlink })
  }, [coinToCompare, conditionType, inputValue, setTriggerData])

  useEffect(() => {
    const targetTokenPrice = coinPrices.find((tokenPrice) => tokenPrice.token === coinToCompare)?.price
    setCurrentPrice(targetTokenPrice)
  }, [coinPrices, coinToCompare])

  return (
    <>
      <div className="combo-trigger-grid">
        <section>
        <h2><FormattedMessage id="deposit-lp-modal.provider" /></h2>
          <div className="chainlink-provider">
            <FormattedMessage id="chainlink"/>
          </div>
        </section>
        <section>
          <h2><FormattedMessage id="combo-trigger-modal.says" /></h2>
          <select value={coinToCompare} onChange={(e) => setCoinToCompare(e.target.value)}>
            <FormattedMessage id="select.currency">
              {text => <option value="" hidden>{text}</option>}
            </FormattedMessage>
            {coinPrices.map((tokenPrice) => (
              <option key={tokenPrice.token} value={tokenPrice.token}>{`${tokenPrice.token} $${tokenPrice.price}`}</option>
            ))}
          </select>
        </section>
      </div>
      <section>
        <h2><FormattedMessage id="combo-trigger-modal.is" /></h2>
        <select
          value={conditionType}
          onChange={(e) => {
            const type: any = e.target.value
            setConditionType(type)
          }}
        >
          <FormattedMessage id="lower">
            {(text: string) => <option value="lower">{text}</option>}
          </FormattedMessage>
          <FormattedMessage id="higher">
            {(text: string) => <option value="higher">{text}</option>}
          </FormattedMessage>
        </select>
        <div className="input-value">
          <FormattedMessage id="placeholder.amount">
            {() => (
              <input
                ref={inputCondition}
                value={inputConditionValue === 'Value...' ? 0 : inputConditionValue}
                onChange={() => {
                  setInput()
                }}
                placeholder="Value..."
                min={0}
              />
            )}
          </FormattedMessage>
          <span>USD</span>
        </div>
        <span>
          < span className="span-current">
            <FormattedMessage id="combo-trigger-modal.current-price" />
          </span>
          <span className="span-coin-price">{' '}{coinToCompare} {currentPrice}$</span>
        </span>
      </section>
    </>
  )
}
export default TriggerTokenInput
