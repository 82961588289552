import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { useScrollOver } from '@gluedigital/scrollastic'
import ScrollDown from 'src/components/ScrollDown/ScrollDown'
import TortleLettering from 'src/components/Landing/TortleLettering/TortleLettering'
import Footer from 'src/components/Landing/Footer/Footer'
import Header from 'src/components/Landing/Header/Header'
import ComboSteps from './ComboSteps/ComboSteps'
import SocialLinks from 'src/components/SocialLinks/SocialLinks'
import BackedLogos from './BackedLogos/BackedLogos'

import logo from 'src/static/images/logo.png'
import arcadeMachine from 'src/static/images/arcade-machine.png'
import diagram from 'src/static/images/savings-diagram.svg'
import ghost from 'src/static/images/ghost.png'
import diagramZh from 'src/static/images/savings-diagram-zh.svg'
import diagramVertical from 'src/static/images/savings-diagram-vertical.svg'
import diagramVerticalZh from 'src/static/images/savings-diagram-vertical-zh.svg'
import { useModal } from '@gluedigital/modal';

import 'src/components/modals/PresaleModal/PresaleModal.tsx'
import './Home.sass'

const Home = () => {
  const community = [
    { name: 'telegram', href: 'https://t.me/tortleninjaTG' },
    { name: 'twitter', href: 'https://twitter.com/tortle_ninja' },
    { name: 'gitbook', href: 'https://tortle-ninja.gitbook.io/tortle-ninja/' },
    { name: 'medium', href: 'https://medium.com/@tortle-ninja' }
  ]

  const scrollable = useRef(null)
  const scrollingTo = useRef(null)
  const currentLang = useSelector((s: any) => s.intl.locale)
  const introSection = useScrollOver('#intro', scrollable)
  const howSection = useScrollOver('#how', scrollable)
  const arcadeSection = useScrollOver('#arcade-banner', scrollable)
  const comboSection = useScrollOver('#combo', scrollable)
  const savingsSection = useScrollOver('#savings', scrollable)
  const fantomSection = useScrollOver('#fantom', scrollable)
  const recipesSection = useScrollOver('#recipes', scrollable)
  const recipesGrid = useScrollOver('#recipes', scrollable)
  const communityGrid = useScrollOver('#community', scrollable)
  const aboutSection = useScrollOver('#about-you', scrollable)
  const logosSection = useScrollOver('#logos-section', scrollable)
  const modal = useModal()

  return (
    <div id="home" ref={scrollable} className="page">
      <Header scrollable={scrollable} />
      <section id="hero-banner">
        <video
          className="only-desktop"
          id="video-hero"
          poster="/images/home-bg.png"
          loop
          autoPlay
          playsInline
          muted
          controls={false}
        >
          <source src="/videos/hero-banner.mp4" type="video/mp4"></source>
        </video>
        <div className="split">
          <div className="item left" ref={scrollingTo}>
            <TortleLettering color="#fff" />
            <img className="logo" src={logo} alt="Tortle Ninja logo" />
            <p>
              <FormattedMessage id="home.hero-banner.text" />
            </p>
            <div className="join-buttons">
              <a
                id = "metamask-center"
                className="button-design bt-discord"
                onClick={() => modal.show('select-wallet-modal')}
                target="_blank"
                rel="noreferrer noopener">
                <span><FormattedMessage id="home-launch-tortle" /> <span className="discord-white">  </span> </span>
              </a>
              <a
                  className="button-design bt-waitlist"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://docs.tortle.ninja/"
                >
                  <span><MarkdownMessage id="home-read-docs" /></span>
                </a>
            </div>
          </div>
          <ScrollDown />
        </div>
      </section>
      <section id="intro" className={introSection > 0.2 ? 'enter' : ''}>
        <div className="container">
          <div className="split">
            <div className="item left">
              <h1>
                <FormattedMessage id="home.intro.title" />
              </h1>
              <p>
                <MarkdownMessage id="home.intro.text" />
              </p>
            </div>
            <div className="item right">
              <TortleLettering color="#FF005C" shadow vertical />
              <TortleLettering shadow color="#FF005C" />
            </div>
          </div>
        </div>
      </section>
      <section id="how" className={howSection > 0.25 ? 'enter' : ''}>
        <div className="container">
          <h1 className="section-title">
            <span>
              <MarkdownMessage id="home.how.title" />
            </span>
            <TortleLettering color="#E7DEFF" />
          </h1>
          <h2>
            <span>
              <FormattedMessage id="home.how.subtitle" />
            </span>
          </h2>
          <p>
            <FormattedMessage id="home.how.text" />
          </p>
        </div>
      </section>
      <section
        id="arcade-banner"
        className={arcadeSection > 0.25 ? 'enter' : ''}
      >
        <TortleLettering color="#FF005C" />
        <img src={arcadeMachine} alt="arcade machine" />
      </section>
      <section id="combo" className={comboSection > 0.25 ? 'enter' : ''}>
        <div className="container">
          <h1 className="section-title">
            <span>
              <MarkdownMessage id="home.combo.title" />
            </span>
            <TortleLettering color="#E7DEFF" />
          </h1>
          <p>
            <FormattedMessage id="home.combo.text" />
          </p>
        </div>
        <div className="combo-wrapper">
          <ComboSteps />
        </div>
      </section>
      <section id="savings" className={savingsSection > 0.25 ? 'enter' : ''}>
        <div className="container">
          <h1 className="section-title">
            <span>
              <MarkdownMessage id="home.savings.title" />
            </span>
            <TortleLettering color="#190030" />
          </h1>
          <div className="savings-diagram">
            <img
              className="only-desktop"
              src={currentLang === 'zh' ? diagramZh : diagram}
              alt="tortle diagram"
            />
            <img
              className="only-mobile"
              src={currentLang === 'zh' ? diagramVerticalZh : diagramVertical}
              alt="tortle diagram"
            />
          </div>
        </div>
      </section>
      <section id="fantom" className={fantomSection > 0.25 ? 'enter' : ''}>
        <div className="container">
          <img className="ghost" src={ghost} alt="ghost image" />
          <div className="wrapper">
            <h1>
              <MarkdownMessage id="home.fantom.title" />
            </h1>
            <p>
              <FormattedMessage id="home.fantom.text" />
            </p>
            <a
              className="button"
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.tortle.ninja/tortle-x-fantom"
            >
              <FormattedMessage id="home.fantom.button" />
            </a>
          </div>
        </div>
      </section>
      <section id="recipes" className={recipesSection > 0.25 ? 'enter' : ''}>
        <div className="container">
          <h1 className="section-title">
            <span>
              <MarkdownMessage id="home.recipes.title" />
            </span>
            <TortleLettering color="#E7DEFF" />
          </h1>
          <p className="recipes-text">
            <FormattedMessage id="home.recipes.text" />
          </p>
        </div>
        <div id="recipes-grid" className={recipesGrid > 0.3 ? 'enter' : ''}>
          <article className="recipe-card">
            <div className="emoji">🧺</div>
            <h1>
              <FormattedMessage id="home.recipes.card-1.title" />
            </h1>
            <p>
              <FormattedMessage id="home.recipes.card-1.text" />
            </p>
            <Link className="button" to="/">
              <FormattedMessage id="home.recipes.go-recipe" />
            </Link>
          </article>
          <article className="recipe-card">
            <div className="emoji">🙌💎</div>
            <h1>
              <FormattedMessage id="home.recipes.card-2.title" />
            </h1>
            <p>
              <FormattedMessage id="home.recipes.card-2.text" />
            </p>
            <Link className="button" to="/">
              <FormattedMessage id="home.recipes.go-recipe" />
            </Link>
          </article>
        </div>
        <Link to="/dashboard/active" className="all-recipes button">
          <FormattedMessage id="home.recipes.go-all-recipes" />
        </Link>
      </section>
      <section id="community" className={communityGrid > 0.3 ? 'enter' : ''}>
        <div className="container">
          <h1 className="section-title">
            <span>
              <MarkdownMessage id="home.community.title" />
            </span>
          </h1>
          <p className="recipes-text">
            <FormattedMessage id="home.community.text" />
          </p>
          <div className="community-grid">
            {community.map((c) => (
              <a
                key={c.name}
                target="_blank"
                rel="noreferrer noopener"
                className={`community-card ${c.name}`}
                href={c.href}
              >
                <span className={`icon icon-${c.name}`} />
                <h1>
                  <FormattedMessage
                    id={`home.community.card-${c.name}.title`}
                  />
                </h1>
                <p>
                  <MarkdownMessage id={`home.community.card-${c.name}.text`} />
                </p>
              </a>
            ))}
          </div>
        </div>
      </section>
      <section id="logos-section" className={logosSection > 0.25 ? 'enter' : ''}>
        <div className="container">
          <h1 className="section-title">
            <span>
              <MarkdownMessage id="logos-section.title" />
            </span>
            <TortleLettering color="#E7DEFF" />
          </h1>
          <BackedLogos />
        </div>
      </section>
      <section id="about-you" className={aboutSection > 0.3 ? 'enter' : ''}>
        <div className="container">
          <TortleLettering color="#6D00CC" />
          <img className="logo" src={logo} alt="Tortle Ninja logo" />
          <div className="wrapper">
            <h1>
              <FormattedMessage id="home.about-you.title" />
            </h1>
            <p>
              <FormattedMessage id="home.about-you.text" />
            </p>
            <a
              className="button"
              target="_blank"
              rel="noreferrer noopener"
              href="https://t.me/tortleninjaTG"
            >
              <FormattedMessage id="home.about-you.button" />
            </a>
            <SocialLinks />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Home
