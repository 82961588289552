import { SpookyVaultsInfo } from "src/api/farms/types"
import { ENVIRONMENT } from "src/constants"

export const getElementsForAPIFromInstance = (reactFlowInstance) => {
  const newElements = reactFlowInstance.nodes.map((node) => {
    const { data, id, type, position } = node
    if (type === "farmNode") {
      let desiredVault: SpookyVaultsInfo
      const vaultsV2: SpookyVaultsInfo[] = ENVIRONMENT.contracts.vaults
      desiredVault = vaultsV2.find((vault) => vault.token0 === data?.pair?.token0 && vault.token1 === data?.pair?.token1)
      if (desiredVault === undefined) {
        const vaultsV3: SpookyVaultsInfo[] = ENVIRONMENT.contracts.vaultsV3
        desiredVault = vaultsV3.find((vault) => vault.token0 === data?.pair?.token0 && vault.token1 === data?.pair?.token1)
      }
      const newData = {
        ...data,
        contracts: {
          batch: ENVIRONMENT.contracts.batch,
          nodes: ENVIRONMENT.contracts.nodes,
          vault: desiredVault.address,
          strategy: desiredVault.strategy
        }
      }
      const newElem = { data: newData, id, type, position }
      return newElem;
    }
    const newData = {
      ...data,
      contracts: {
        batch: ENVIRONMENT.contracts.batch,
        nodes: ENVIRONMENT.contracts.nodes
      }
    }
    const newElem = { data: newData, id, type, position }
    return newElem;
  });
  reactFlowInstance.edges.forEach((edge) => {
    const { style, ...newElem } = edge
    newElements.push(newElem)
  })
  return newElements;
};
