import dayjs from 'dayjs'
import { gql } from '@apollo/client'
const BUNDLE_ID = 1

export const FTM_PRICE_CURRENT = () => {
  const queryString =
      `query bundles {
      bundles(where: { id: ${BUNDLE_ID} }) {
        id
        ethPrice
      }
    }
  `
  return gql(queryString)
}

export const FTM_PRICE_BY_BLOCK_NUMBER = (blockNumber: number) => {
  return gql`
    query bundle {
        bundle(block: {number: ${blockNumber}},  id: 1 ) {
          id
          ethPrice
          __typename @skip(if: true)
        }
      }
    `
}
export const TOKEN_DERIVED_FTM_BY_BLOCK_NUMBER = (address: string, blockNumber: number) => {
  return gql`
  query token{
    token(block: {number: ${blockNumber}}, id:"${address}") {
      symbol
      derivedETH
    }
  }`
}

export const TOKEN_PRICE_USD = (address: string, timestamp: number) => {
  const oneDayAgo = (Math.floor(dayjs().unix() / 86400) - 1) * 86400
  const date: number = timestamp > oneDayAgo ? oneDayAgo : timestamp
  return gql`
    query tokenDay {
      tokenDayDatas(first: 1, where: {date_gt: ${date}, token:"${address}" }) {
        id
        date
        priceUSD
      }
    }
  `
}

export const TOKEN_DAY_DATA_FIVE_DAYS_AGO = (address: string) => {
  const fivedaysago = (Math.floor(dayjs().unix() / 86400) - 5) * 86400
  return gql`
    query tokenDay {
      tokenDayDatas(first: 100, where: {date_gt: ${fivedaysago}, token:"${address}" }) {
        id
        date
        priceUSD
      }
    }
  `
}

export const getTokenDayData = (tokenAddress: String) => {
  return gql`
    query tokens {
      tokens( first: 100, where: { id: "${tokenAddress}"}) {
        id
        name
        symbol
        derivedETH
      }
    }
  `
}
