import React from "react"
import { FormattedMessage } from "react-intl"
import { FarmData, PairDayData, TokenInfoSpooky } from "src/api/farms/types"
import { useEndBooAmountFromWithdrawEvent, useLpEarned } from "src/hooks/useFarmsLogs"
import { useGetTokenPriceUsd } from "src/hooks/useTokensLogsFromGraph"

interface EarnedFarmModalLogProps {
  previousData: any
  executionSteps: number
  allFarmLPs: string[]
  lpUSDPriceAtOtherMoment: number
  lpUSDPriceAtDeposit: number
  masterchefInfo: PairDayData
  blockNumberAtOtherMoment: number
}
export const EarnedFarmModalLog = ({ previousData, executionSteps, allFarmLPs, lpUSDPriceAtOtherMoment, lpUSDPriceAtDeposit, blockNumberAtOtherMoment, masterchefInfo }: EarnedFarmModalLogProps) => {
  const farmAddress: string = previousData?.data?.pair?.id.slice(0, -6)
  const DECIMAL_USD_ROUNDED: number = 3

  const farmData: FarmData = useLpEarned(previousData.id, previousData.date, executionSteps) || { state: 'Pending', lpDeposited: 'No Info', earned: 'No Info' }
  const totalLpAmount: number = Number(farmData.lpDeposited.slice(0, -2)) + Number(farmData.earned.slice(0, -2))
  const lpDifference: number = totalLpAmount - Number(farmData.lpDeposited.slice(0, -2))

  const rewardTokenData: TokenInfoSpooky = useGetTokenPriceUsd(masterchefInfo.tokenReward, blockNumberAtOtherMoment)
  const amountBOOWei: number = useEndBooAmountFromWithdrawEvent(farmAddress, allFarmLPs)
  const amountBOOEther: number = amountBOOWei / 10 ** 18

  const totalLPUSDValueAtOtherMoment: number = lpUSDPriceAtOtherMoment * totalLpAmount
  const lpUSDValueAtDeposit: number = Number(farmData.lpDeposited.slice(0, -2)) * lpUSDPriceAtDeposit
  const totalUSDValue: number = totalLPUSDValueAtOtherMoment + amountBOOEther * parseFloat(rewardTokenData.priceUSD)
  const differenceEarnedValue: number = totalUSDValue - lpUSDValueAtDeposit

  return (
    <div id="farm-log-field">
      <h3><FormattedMessage id="farm-modal-log.earned" /></h3>
      <strong className="span-coin">$ {differenceEarnedValue.toFixed(DECIMAL_USD_ROUNDED)} </strong>
      <div className="div-2column-lps">
        <span className="lp-amount-row first-column">{lpDifference.toFixed(12)} <strong>LPs</strong></span>
      </div>
    </div>
  )
}
