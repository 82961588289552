import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getTokenImage } from 'src/api/farms/helpers'
import Tooltip from 'src/components/common/ToolTip/Tooltip'
import { FarmMode, FarmPeriod } from 'src/routes/RecipeDiagram/helpers/types'

import './FarmInfo.sass'

interface FarmInfoProps {
  pair: any
  isChosen: boolean
  farmMode: FarmMode
  setFarmMode: (farmMode: FarmMode) => void
  farmPeriod: FarmPeriod
  setFarmPeriod: (farmPeriod: FarmPeriod) => void
  setChosenOption: (option: any) => void
  featured?: boolean
}

interface CustomRadioButtonProps {
  disabled?: boolean
  checked: boolean
  onChange: () => void
  labelTextID: string
}
const CustomRadioButton = (props: CustomRadioButtonProps) => {
  const { disabled, checked, onChange, labelTextID } = props
  return <label className={`customRadioButton ${disabled ? 'disabled' : ''}`}>
      <span><FormattedMessage id={labelTextID} /></span>
      <input type="radio" disabled={disabled} checked={checked} onChange={onChange} />
    </label>
}

const periodicityValues: FarmPeriod[] = ['daily', 'weekly', 'monthly', 'auto']
const FarmInfo = (props: FarmInfoProps) => {
  const { pair, isChosen, farmMode, setFarmMode, farmPeriod, setFarmPeriod, setChosenOption, featured } = props
  const [showInfo, setShowInfo] = useState<boolean>(isChosen)
  const longPairNames = pair.token0.symbol.length + pair.token1.symbol.length > 9

  useEffect(() => {
    if (!isChosen) setShowInfo(false)
  }, [isChosen])

  const handleFarm = (mode) => {
    setFarmMode(mode)
    setChosenOption(pair)
  }
  return (
    <li className={`farm-info-card ${featured ? 'featured' : ''}`}>
      {featured && <span className="featured-pool-tag"><FormattedMessage id="farm-modal.featured" /></span>}
      <div className="farm-info">
        <section className="token-container">
          <div className="logos">
            <img className="logo" src={getTokenImage(pair.token0.symbol)} />
            <img className="logo" src={getTokenImage(pair.token1.symbol)} />
          </div>
          <h1 className={`pair-names-container ${longPairNames ? 'long' : ''}`}>
            {pair.token0.symbol} - {pair.token1.symbol}
          </h1>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span>
              <FormattedMessage id="deposit-lp-modal.apr.current" />
              <Tooltip
                tooltipText={[
                  `Farm APR: ${pair.aprFarmCurrent.toFixed(2)}%`,
                  `Fees APR: ${pair.aprFeesCurrent.toFixed(2)}%`,
                ]}
              />
            </span>
          </h2>
          <strong>{(pair?.aprFarmCurrent + pair?.aprFeesCurrent).toFixed(2)} %</strong>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span>
              <FormattedMessage id="deposit-lp-modal.apr.declared" />
              <Tooltip
                tooltipText={[
                  `Farm APR: ${pair.aprFarm.toFixed(2)}%`,
                  `Fees APR: ${pair.aprFees.toFixed(2)}%`,
                ]}
              />
            </span>
          </h2>
          <strong>{(pair?.aprFarm + pair?.aprFees).toFixed(2)} %</strong>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span><FormattedMessage id="deposit-lp-modal.liquidity" /></span>
          </h2>
          <strong >{'$' + parseInt(pair?.reserveUSD).toLocaleString()}</strong>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span><FormattedMessage id="deposit-lp-modal.daily-volume-USD" /></span>
          </h2>
          <strong>{'$' + parseInt(pair?.dailyVolumeUSD).toLocaleString()}</strong>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span><FormattedMessage id="deposit-lp-modal.provider" /></span>
          </h2>
          <strong>{pair.provider}</strong>
        </section>
        <section className="button-section">
          <button
            className={isChosen ? 'filled' : ''}
            onClick={() => {
              setShowInfo(!showInfo)
              setChosenOption(pair)
              setFarmPeriod(undefined)
            }}
          >
            <span className="details-text">
              <FormattedMessage id={isChosen ? 'chosen' : 'farm-modal.details'} />
            </span>
            {!isChosen && <span className={`icon icon-angle_${showInfo ? 'up' : 'down'} details-icon`} />}
          </button>
        </section>
      </div>
      <div className={`farming-options ${showInfo ? 'visible' : ''}`}>
        <section>
          <h3 className="farming-option-title"><FormattedMessage id="farm-modal.compoundingOptions" /></h3>
          <div className="options-list">
            <CustomRadioButton
              checked={farmMode === 'autocompound'}
              onChange={() => handleFarm('autocompound')}
              labelTextID={'farm-modal.autocompound'}
            />
            <CustomRadioButton
              disabled
              checked={farmMode === 'autoharvest'}
              onChange={() => handleFarm('autoharvest')}
              labelTextID={'farm-modal.autoharvest'}
            />
          </div>
        </section>
        <section className={farmMode === 'autoharvest' ? '' : ' disabled'}>
          <h3 className="farming-option-title"><FormattedMessage id="farm-modal.periodicity" /></h3>
          <div className="options-list">
            {periodicityValues.map((value) =>
              <CustomRadioButton
                key={`periodicity-option/${value}`}
                checked={farmPeriod === value}
                onChange={() => setFarmPeriod(value)}
                disabled={!farmMode || farmMode === 'autocompound'}
                labelTextID={`farm-modal.${value}`}
              />
            )}
          </div>
        </section>
      </div>
    </li>
  )
}
export default FarmInfo
