import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useModal } from "@gluedigital/modal";
import './Buttons.sass'
import { RecipeDetails } from 'src/types';
import { useNodesDispatch } from 'src/routes/RecipeDiagram/helpers/nodesAndEdgesContext';

interface ButtonsProps {
  data?: any
  id?: string
  viewDetailsModalType?: string
  recipeLogs?: any
  recipeDetails?: RecipeDetails
}

const ButtonsSendToWallet = ({ data, id, viewDetailsModalType, recipeLogs, recipeDetails }: ButtonsProps) => {
  const modal = useModal();
  const nodesDispatch = useNodesDispatch()

  return (
    <div className="node-buttons">
      {(recipeLogs === undefined) && <button className="delete" onClick={() => nodesDispatch({ type: 'removeNode', nodeID: id })}>
        <span className="icon icon-close" />
        <span className="text"> <FormattedMessage id="delete" /></span>
      </button>}
      <div>
        {viewDetailsModalType && <button onClick={() => modal.show(viewDetailsModalType, { previousData: { data, id, recipeLogs, recipeDetails } })}>
          <span className="text"> <FormattedMessage id="node-buttons.view-details" /></span>
        </button>}
      </div>
    </div>
  )
}

export default ButtonsSendToWallet
