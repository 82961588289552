import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ENVIRONMENT } from 'src/constants'
import { logout } from 'src/store/actions/user'
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import { version } from 'package.json'
declare const ethereum: any
const hasWallet = typeof ethereum !== "undefined"

interface AppProps {
  children?: React.ReactNode
}

const App = ({ children }: AppProps) => {
  const user = useSelector((s: any) => s.user)
  const ready = useSelector((s: any) => s.ready)
  const dispatch = useDispatch();
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (!hasWallet) {
      dispatch(logout())
    }
    if (hasWallet) {
      const handleAccountsChange = async (accounts: string[]) => {
        if (accounts.length === 0) {
          dispatch(logout())
        }
      }
      const handleChainChange = async (chainId: string) => {
        if (chainId !== ENVIRONMENT.chainId) {
          history.push('/')
          dispatch(logout())
        }
      }

      ethereum.on("accountsChanged", handleAccountsChange);
      ethereum.on("chainChanged", handleChainChange);
      return () => {
        ethereum.removeListener("accountsChanged", handleAccountsChange);
        ethereum.removeListener("chainChanged", handleChainChange);
      }
    }
  }, [dispatch, history])

  const locationArray: string[] = (location.pathname.slice(1)).split('/')
  const id: string = locationArray[1]
  const reg: RegExp = /^\d+$/

  if (!user?.token) {
    if (!ready) return null
    if (!reg.test(id) || !(location.pathname.startsWith(`/recipe/${id}/`))) {
      return <Redirect to="/" />
    }
  }

  return (
    <div>
      {children}
      <span id="app-version-id">V{version}</span>
    </div>
  );
};

export default App
