import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal, useModal } from "@gluedigital/modal"
import RetryApprovesTokens from "src/components/RecipeSummary/RetryApprovesTokens"
import { ApprovalStatus, TokenForApproval } from "src/routes/RecipeDiagram/helpers/types"
import web3 from "src/utils/web3"
import { allowance, checkEnoughTokenBalance, checkFantomBalance, depositFTM, getNodesToApprove } from "src/contracts/ApproveHelpers"
import RetryDepositList from "src/components/RecipeSummary/RetryDepositList"
import { useRecipeCRUD, useRecipeDetails } from "src/api/recipes"
import { tokensABI } from "src/data/ABIs"
import { BigNumber } from 'ethers'
import "./RetryApprovalModal.sass"
import Loading from "src/components/common/Loading/Loading"

interface RetryApprovalModalProps {
  recipeID: number
}
const RetryApprovalModal = (props: RetryApprovalModalProps) => {
  const { recipeID } = props

  const user = useSelector((s: any) => s.user)
  const userAddress: string = user.account.account
  const recipeDetails = useRecipeDetails(recipeID)
  const recipeCRUD = useRecipeCRUD()
  const modal = useModal()
  const [fantomsForDeposit, setFantomsForDeposit] = useState<TokenForApproval>()
  const [tokensForApproval, setTokensForApproval] = useState<TokenForApproval[]>([])
  const [isAllApproved, setAllApproved] = useState(false)
  const [hasInsufficientBalance, setTokensWithInsufficientBalance] = useState<TokenForApproval[]>([])
  const [approvalFantomStatus, setApprovalFantomStatus] = useState<ApprovalStatus>('ready')
  const [loading, setLoading] = useState(false)

  const convertWeiToEtherAux = useCallback(
    async (tokenAddress: string, amount: string): Promise<string> => {
      const token: any = new web3.eth.Contract(tokensABI as any, tokenAddress);
      const decimals: string = await token.methods.decimals().call()
      const FINAL_DECIMALS = 10 ** 6
      let convertedAmount: any
      if (decimals !== '18') {
        const valueDifference: any = BigNumber.from(10).pow(decimals)
        convertedAmount = BigNumber.from(amount).mul(FINAL_DECIMALS).div(valueDifference)
        convertedAmount = Number(convertedAmount) / FINAL_DECIMALS
        return parseFloat(convertedAmount).toFixed(8)
      }
      const stringAmount: string = amount.toString()
      const bnAmount: BigNumber = BigNumber.from(stringAmount)
      convertedAmount = web3.utils.fromWei(bnAmount.toString(), 'ether')
      return parseFloat(convertedAmount).toFixed(15)
    },
    [],
  )

  useEffect(() => {
    if (!recipeDetails) {
      return
    }
    const nodesToApprove = getNodesToApprove(recipeDetails.code)
    const fantomsForDeposit = nodesToApprove.find((elem) => elem.id === 'FTM')
    if (fantomsForDeposit !== undefined) {
      convertWeiToEtherAux(fantomsForDeposit?.address, fantomsForDeposit?.amount.toString()).then((newAmount: string) => {
        const newFantomForDeposit: TokenForApproval = { ...fantomsForDeposit, amount: newAmount }
        setFantomsForDeposit(newFantomForDeposit)
      }).catch(err => console.error(err))
      setTokensForApproval(nodesToApprove.filter((elem) => elem.id !== 'FTM'))
    }
  }, [convertWeiToEtherAux, recipeDetails])

  useEffect(() => {
    setAllApproved(tokensForApproval.length <= 0)
  }, [tokensForApproval, fantomsForDeposit])

  useEffect(() => {
    tokensForApproval.forEach((token: TokenForApproval) => {
      checkEnoughTokenBalance(web3, userAddress, token).then((hasEnoughBalance) => {
        if (!hasEnoughBalance) {
          setTokensWithInsufficientBalance([...hasInsufficientBalance, token])
        }
      }).catch(err => console.error(err))
    })
  }, [hasInsufficientBalance, tokensForApproval, userAddress])

  useEffect(() => {
    if (fantomsForDeposit) {
      checkFantomBalance(web3, user.account.balance, fantomsForDeposit).then((hasEnoughBalance) => {
        if (!hasEnoughBalance) {
          setTokensWithInsufficientBalance([...hasInsufficientBalance, fantomsForDeposit])
        }
      }).catch(err => console.error(err))
    }
  }, [fantomsForDeposit, hasInsufficientBalance, user.account.balance])

  const handleRetry = () => {
    setLoading(true)
    setTimeout(() => {
      recipeCRUD.execute(recipeID)
        .then(() => modal.hide())
        .catch(err => console.error(err.message))
    }, 2000)
  }

  const approveAllButtonHandler = () => {
    if (fantomsForDeposit?.amount !== undefined && tokensForApproval.length > 0) {
      allowance(web3, userAddress, tokensForApproval, setTokenStatus)
        .then(() => {
          depositFTM(web3, userAddress, fantomsForDeposit?.amount)
            .then(() => {
              setApprovalFantomStatus("approved")
              handleRetry()
            })
            .catch(() => {
              setApprovalFantomStatus('rejected')
            })
          })
        .catch(err => console.error(err))
    } else if (fantomsForDeposit?.amount === undefined) {
      allowance(web3, userAddress, tokensForApproval, setTokenStatus)
        .then(() => handleRetry())
        .catch(err => console.error(err))
    } else {
      setApprovalFantomStatus('pending')
      depositFTM(web3, userAddress, fantomsForDeposit?.amount)
        .then(() => {
          setApprovalFantomStatus("approved")
          handleRetry()
        })
        .catch(() => {
          setApprovalFantomStatus('rejected')
        })
    }
  }

  const setTokenStatus = (tokenID: string, status: ApprovalStatus) => {
    const updatedTokenIndex = tokensForApproval.indexOf(tokensForApproval.find((token) => token.id === tokenID))
    tokensForApproval[updatedTokenIndex].status = status
    const updatedArray = [...tokensForApproval]
    setTokensForApproval(updatedArray)
  }

  return (
    <div className="retry-approval-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button>
              <span className="icon icon-close icon-close-updated" />
              <span className="purple-color"><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h2><FormattedMessage id="recipe-item.retry-recipe" /></h2>
        </header>

        <div className="retry-approval-modal-wrapper">
          <h3>{recipeDetails?.title}</h3>
          {loading
            ? <Loading />
            : <div className="recipe-summary-approvals">
              {tokensForApproval.length > 0
                && <RetryApprovesTokens
                  tokens={tokensForApproval}
                  setAllApproved={setAllApproved}
                  hasInsufficientBalance={hasInsufficientBalance.length > 0}
                  convertWei={convertWeiToEtherAux}
              />}
            {fantomsForDeposit
              && <RetryDepositList amount={fantomsForDeposit.amount} fantomStatus={approvalFantomStatus} />}
            <button
              className="approve-all-button"
              disabled={(approvalFantomStatus === "approved" || approvalFantomStatus === 'pending') && isAllApproved} onClick={approveAllButtonHandler}>
              {
                tokensForApproval.find((token) => token.status !== 'approved')
                  ? <FormattedMessage id="retry-recipe-modal.approve" />
                    : fantomsForDeposit
                      ? <FormattedMessage id="retry-recipe-modal.deposit" />
                      : <FormattedMessage id="retry-recipe-modal.retry" />
              }
            </button>
          </div>}
        </div>
      </div>
    </div>
  )
}

registerModal("retry-approval-modal", RetryApprovalModal)
