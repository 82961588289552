import { apiAction } from "./withSession"

export const logout = () => {
  return {
    type: "logout",
  }
}

export const login = (body: object) => {
  return apiAction({
    action: "login",
    url: "/login",
    body: body,
  })
}

export const setUserAccount = (account: object) => {
  return {
    type: "set_account",
    payload: account,
  }
}
