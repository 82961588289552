import React from "react"
import MarkdownMessage from '@gluedigital/markdown-message'
import { FormattedMessage } from "react-intl"

import './EstimateGasLabel.sass'

export interface EstimateGasResponse {
  estimationFTM: string
  estimationUSD: string
}
interface EstimateGasLabelProps {
  result?: EstimateGasResponse
}

const EstimateGasLabel = ({ result }: EstimateGasLabelProps) => {
  const componentLoading = <div className="estimate-gas-container loading-container">
    <span className="icon-loading" />
    <span><FormattedMessage id="estimate-gas.loading" /></span>
  </div>
  if (!result) {
    return componentLoading
  }
  if (result.estimationFTM === 'error') {
    return <div className="estimate-gas-container failed-container">
      <span className="icon icon-exclamation" />
      <span><FormattedMessage id="estimate-gas.failed" /></span>
    </div>
  }
  return <div className="estimate-gas-container success-container">
    <span className="icon-done" />
    <MarkdownMessage
      id="estimate-gas.success"
      values={{ estimationFTM: result?.estimationFTM, estimationUSD: result?.estimationUSD }}
    />
  </div>
}

export default EstimateGasLabel
