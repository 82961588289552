import React, { Suspense, useCallback, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { BigNumber } from 'ethers'
import web3 from "src/utils/web3"
import { tokensABI } from "src/data/ABIs"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import SummaryListSpookyLog from "./SummaryListSpookyLog"

import "./SummaryList.sass"

interface SummaryListProps {
  summary: [any]
}
const SummaryList = ({ summary }: SummaryListProps) => {
  const [updatedSummary, setUpdatedSummary] = useState([])
  const LongToShortNumber = useCallback(
    async (tokenAddress: string, amount: string): Promise<number> => {
      const token: any = new web3.eth.Contract(tokensABI as any, tokenAddress);
      const tokenDecimals: string = await token.methods.decimals().call()
      const FINAL_DECIMALS = 10 ** 8
      let convertedAmount: any
      if (tokenDecimals !== '18') {
        const valueDifference: any = BigNumber.from(10).pow(tokenDecimals)
        convertedAmount = BigNumber.from(amount).mul(FINAL_DECIMALS).div(valueDifference)
        convertedAmount = Number(convertedAmount) / FINAL_DECIMALS
      } else {
        convertedAmount = web3.utils.fromWei(amount, 'ether')
      }
      return Number(parseFloat(convertedAmount).toFixed(Number(tokenDecimals)))
    },
    [],
  )

  useEffect(() => {
    const addFundsNodes = summary.filter((el) => el.type === "addFundsNode")
    Promise.all(addFundsNodes.map(async (addFunds) =>
      await LongToShortNumber(tokenNameToAddress(addFunds.data.outputCoin), addFunds.data.amount.toString())
        .then((amount) => {
          const newAddFunds = { ...addFunds, data: { ...addFunds.data, amount } }
          return newAddFunds
        })
    )).then((res) => {
      setUpdatedSummary(res.concat([...summary.filter((el) => el.type !== "addFundsNode")]))
    }).catch(err => console.error(err.message))
  }, [LongToShortNumber, summary])

  return (
    <div id="summary-list">
      {updatedSummary.map((el) => (
        <div className={`summary-item type-${el.type}`} key={el.id}>
          <strong><FormattedMessage id={"node." + el.type} />: </strong>
          {el.type === "addFundsNode" && (<><span className="amount">{el.data.amount}</span><span className="coin">{el.data.outputCoin}</span></>)}
          {(el.type === "swapNode" || el.type === "liquidateNode") && <span className="coin">{el.data.inputCoin} - {el.data.outputCoin}</span>}
          {el.type === "splitNode" && <span className="coin">{el.data.inputCoin} - {el.data.firstCoin} & {el.data.secondCoin}</span>}
          {el.type === "sendToWalletNode" && <span className="coin">{el.data.inputCoin}</span>}
          {el.type === "farmNode" && <span className="coin">{el.data.pair.token0} -{el.data.pair.token1} </span>}
          {el.type === "depositOnLPNode" && <span className="coin">{el.data.pair.token0} -{el.data.pair.token1} </span>}
          {el.type === "comboTriggerNode" &&
            ((el.data.conditionProvider === "CHAINLINK" && <span className="coin">{el.data.condition.coinToCompare} {el.data.condition?.type} {el.data.condition?.value} USD </span>)
              || (el.data.conditionProvider === "SPOOKYSWAP" && <SummaryListSpookyLog summaryInfo={el} />)
              || (el.data.conditionProvider === "USER" && <span className="coin"> <FormattedMessage id="combo-trigger-modal.manual-execution" /></span>)
              || (el.data.conditionProvider === "TIME" && (
                <span>
                  <FormattedMessage id="combo-type-DCA" /> {el.data.condition.type === "exact"
                    ? <>
                      <FormattedMessage id="combo-type-DCA.at" /> {' '}
                      {el.data.condition.value}
                    </>
                    : <>
                      <FormattedMessage id="combo-type-DCA.after" /> {' '}
                      {el.data.condition.value}
                    </>}
                </span>))
              || (el.data.conditionProvider === "SENSEI" && (
                <span>
                  <FormattedMessage id="combo-type-sentiment" /> <FormattedMessage id="combo-type-sentiment.for" />  {el.data.condition.token}
                  <FormattedMessage id="combo-type-sentiment.with" /> <FormattedMessage id="combo-trigger-modal.sentiment-volume" /> {el.data.condition.volumeComparison}  {el.data.condition.volume} {' '}
                  {el.data.condition.type} <FormattedMessage id="combo-trigger-modal.sentiment-sentiment" /> {el.data.condition.sentimentComparison}  {el.data.condition.sentiment}
                </span>))
              || (el.data.conditionProvider === "TORTLE" &&
                (<span>
                  <FormattedMessage id="combo-type-profit.profit" /> {el.data.condition.type} <FormattedMessage id="combo-trigger-modal.than" /> {el.data.condition.value}%
                </span>))
            )}
        </div>
      ))}
    </div>
  )
}

const SummaryListWrapper = (props) => (
  <Suspense fallback={<div />}>
    <SummaryList {...props} />
  </Suspense>
)

export default SummaryListWrapper
