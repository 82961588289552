import React from 'react'
import { PairDayData, TokenInfoSpooky } from 'src/api/farms/types'
import { useAllFarmsIdFromApi } from 'src/api/recipes'
import { tokenAddressToName } from 'src/components/Diagram/nodes/nodesLogsHelper'
import { useBooAmountFromStrategySC } from 'src/hooks/useFarmsLogs'
import { useLPUsdPrice } from 'src/hooks/useLPsLogs'
import { useGetTokenPriceUsd } from 'src/hooks/useTokensLogsFromGraph'

interface EarnedFarmLeftLogProps {
  lpDeposited: number
  totalLPAmount: number
  myFarmId: string
  blockNumberAtDeposit: number
  blockNumberAtOtherMoment: number
  masterchefInfo: PairDayData
}
export const EarnedFarmV3LeftLog = (props: EarnedFarmLeftLogProps) => {
  const { lpDeposited, totalLPAmount, myFarmId, blockNumberAtDeposit, blockNumberAtOtherMoment, masterchefInfo } = props
  const version: number = masterchefInfo.poolIdV3 || masterchefInfo.poolIdV3 === 0 ? 3 : 2
  const tokeRewardName: string = tokenAddressToName(masterchefInfo.tokenReward)
  const allFarmLPs: string [] = useAllFarmsIdFromApi(version)
  const amountBOOWei: number = useBooAmountFromStrategySC(myFarmId.slice(0, -6), allFarmLPs, version)
  const amountBOOEther = amountBOOWei / 10 ** 18
  const rewardTokenData: TokenInfoSpooky = useGetTokenPriceUsd(masterchefInfo.tokenReward, blockNumberAtOtherMoment)
  const lpUSDPriceAtDeposit: number = useLPUsdPrice(myFarmId, blockNumberAtDeposit)
  const lpUSDPriceAtOtherMoment: number = useLPUsdPrice(myFarmId, blockNumberAtOtherMoment)

  const lpUSDValueAtDeposit: number = lpDeposited * lpUSDPriceAtDeposit
  const totalLPUSDValueAtOtherMoment: number = lpUSDPriceAtOtherMoment * totalLPAmount
  const booUSDValueAtOtherMoment: number = parseFloat(rewardTokenData.priceUSD) * amountBOOEther
  const totalUSD: number = totalLPUSDValueAtOtherMoment + booUSDValueAtOtherMoment

  return (
    <>
       {/* CURRENT VALUE */}
        <div className="logs-group-rows">
          <p className="main-text-logs">$ {totalUSD.toFixed(3)} </p>
          <p>{totalLPAmount.toFixed(12)}  LPs<span className="left-logs-usd-price">$ {(totalLPUSDValueAtOtherMoment).toFixed(3)}</span></p>
          <p>{amountBOOEther.toFixed(10)} {tokeRewardName} <span className="left-logs-usd-price">$ {(booUSDValueAtOtherMoment).toFixed(3)}</span></p>
        </div>
       {/* EARNED */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {(totalUSD - lpUSDValueAtDeposit).toFixed(3)}</p>
        <p>{(totalLPAmount - lpDeposited).toFixed(12)} LPs</p>
      </div>
    </>
  )
}

interface LoadingEarnedLeftLogProps {
  recipeStatus: string
}

export const LoadingEarnedLeftLog = ({ recipeStatus }: LoadingEarnedLeftLogProps) => {
  return (
    <>
      {recipeStatus === "active"
        ?
        <div className="logs-group-rows">
          <p>Loading current value</p>
          <div className="left-empty-position"></div>
          <div className="left-empty-position"></div>
        </div>
        :
        <div className="logs-group-rows">
          <p>Loading final value</p>
          <div className="left-empty-position"></div>
        </div>}

      <div className="logs-group-rows">
        <p>Loading earned</p>
        <div className="left-empty-position"></div>
      </div>
    </>
  )
}
