import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ComboTriggerModalData, ConditionProvider } from "src/routes/RecipeDiagram/helpers/types";

interface TriggerTimeInputProps {
  triggerData: ComboTriggerModalData
  setTriggerData: (data: ComboTriggerModalData) => void
}

const SECONDS_IN_A_DAY = 86400
const SECONDS_IN_A_WEEK = 604800
const SECONDS_IN_A_MONTH = 2628000
const TriggerTimeInput = (props: TriggerTimeInputProps) => {
  const [timeframe, setTimeframe] = useState<number>()
  const [conditionType, setConditionType] = useState<'period' | 'exact'>()

  const { setTriggerData } = props

  useEffect(() => {
    setTriggerData({ condition: { timeComparison: conditionType, value: timeframe }, conditionProvider: ConditionProvider.time })
  }, [conditionType, setTriggerData, timeframe])

  const [date, setDate] = useState<Date>(new Date())

  const getCurrentDate = () => {
    const myCurrentDate = new Date()
    const day = myCurrentDate.getDate();
    const month = myCurrentDate.getMonth() + 1;
    const year = myCurrentDate.getFullYear();

    return `${year}` + "-" + `${month}` + "-" + `${day}`
  }

  const isValidDate = (dateToValidate: Date) => {
    return dateToValidate.getTime() > Date.now()
  }

  const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const splitInput = e.target.value.split('-')
    const updatedDate = new Date(date.getTime())
    updatedDate.setFullYear(+splitInput[0])
    updatedDate.setMonth(+splitInput[1] - 1)
    updatedDate.setDate(+splitInput[2])
    setConditionType("exact")
    if (isValidDate(updatedDate)) {
      setDate(updatedDate)
      setTimeframe(updatedDate.getTime())
    }
  }

  const hourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const splitInput = e.target.value.split(':')
    const updatedDate = new Date(date.getTime())
    updatedDate.setHours(+splitInput[0])
    updatedDate.setMinutes(+splitInput[1])
    setConditionType("exact")
    if (isValidDate(updatedDate)) {
      setDate(updatedDate)
      setTimeframe(updatedDate.getTime())
    }
  }

  const handlePeriod = (data) => {
    setConditionType("period")
    setTimeframe(data)
  }

  return (
    <>
      <div className="combo-trigger-grid">
        <section className="section0">
          <h2><FormattedMessage id="timeframe-after" /></h2>
          <div className="time-frames">
            <button className="timeframe-24h" onClick={() => handlePeriod(SECONDS_IN_A_DAY)}>
              <FormattedMessage id="timeframe-24h" />
            </button>
            <button className="timeframe-7d" onClick={() => handlePeriod(SECONDS_IN_A_WEEK)}>
              <FormattedMessage id="timeframe-7d" />
            </button>
            <button className="timeframe-1m" onClick={() => handlePeriod(SECONDS_IN_A_MONTH)}>
              <FormattedMessage id="timeframe-1m" />
            </button>
          </div>
        </section>
        <section className="section1">
          <h2><FormattedMessage id="timeframe-on" /></h2>
          <div className="time-frames">
            <label>
              <input className="timeframe-date" type="date" name="date" value={format(date, 'yyyy-MM-dd')} onChange={dateChange} min={getCurrentDate()} />
            </label>
            <label>
              <input className="timeframe-hour" type="time" name="time" value={format(date, 'HH:mm')} onChange={hourChange} placeholder="00:00" />
            </label>
          </div>
        </section>

      </div>
    </>
  )
}
export default TriggerTimeInput
