import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { useRecipeDetails, useRecipeLogs, useRecipePerformance, useRecipePerformanceRefresh } from 'src/api/recipes'
import TableEvents from './TableEvents'
import { dhm } from 'src/utils/miliToDHMS'
import { tokenAddressToName, tokenNameToAddress } from 'src/components/Diagram/nodes/nodesLogsHelper'
import { getEtherNumberAmountFromAddress } from 'src/contracts/TokensDecimalsHelpers'
import { NodeExecutionResult, RecipeExecutionLog } from 'src/types'
import { LPTokenWithPrice, NodeTokenWithPrice } from '../RecipeDiagram/helpers/types'
import LoadingAprDetails from './LoadingAprDetails'
import logo from 'src/static/images/apr-bg.png'
import arrow from 'src/static/images/back-arrow-white.svg'
import './AprDetails.sass'
export interface TableData {
  input?: string
  output?: string
  date?: JSX.Element
  value?: string
}

type NodeEventWithDate = NodeExecutionResult & { date: Date }

const getActiveOutput = async (currentToken: NodeTokenWithPrice | LPTokenWithPrice) => {
  if (currentToken?.amount === '0') {
    return
  }
  const tokenLength = currentToken?.token?.length
  let token = tokenLength < 42 ? currentToken.token : tokenAddressToName(currentToken.token)
  if ("token0" in currentToken && "token1" in currentToken) {
    token = tokenAddressToName(currentToken.token0) + '-' + tokenAddressToName(currentToken.token1)
  }
  const amount = await getEtherNumberAmountFromAddress(tokenLength === 42 ? currentToken.token : tokenNameToAddress(currentToken.token), currentToken?.amount.toString())
  const output = `${+amount} ${token}`
  const date = <FormattedMessage id="apr-details.date-now" />
  const value = '$' + (currentToken.totalValueUSD || '???')
  return { output, date, value }
}
const outputTableDataDefault: TableData[] = [
  { output: null, date: null }
]
const inputTableDataDefault: TableData[] = [
  { input: null, date: null }
]
const emptyLogs: RecipeExecutionLog[] = []

const AprDetails = () => {
  const { id } = useParams()
  const [outputTableData, setOutputTableData] = useState(outputTableDataDefault)
  const [inputTableData, setInputTableData] = useState(inputTableDataDefault)
  const recipeLogs = useRecipeLogs(+id) || emptyLogs
  const recipeDetails = useRecipeDetails(+id)
  const status = recipeDetails?.status
  const startDate: Date = recipeLogs[0]?.date
  const endDate: Date = recipeLogs[recipeLogs.length - 1]?.date
  const start: number = new Date(startDate).getTime()
  const [end, setEndTime] = useState(new Date(endDate).getTime())
  const now: number = Date.now()
  const elapsed: number = (status === 'finished' ? end : now) - start
  let comeBackRoute = 'your'
  if (recipeDetails?.status === 'finished') {
    comeBackRoute = 'liquidated'
  } else if (recipeDetails?.status === 'active') {
    comeBackRoute = 'active'
  }

  useEffect(() => {
    let endInterval
    if (status !== 'finished') {
      endInterval = setInterval(() => {
        setEndTime((prevTime) => prevTime + 1000)
      }, 1000)
    }
    return () => clearInterval(endInterval)
  }, [status])
  const { performance } = useRecipePerformance(id)
  const refresh = useRecipePerformanceRefresh()
  const earned: string = performance.earned.toFixed(3)
  const roi: number = performance.roi

  const [isRefreshLoading, setRefreshLoading] = useState(false)
  const handleRefresh = () => {
    setRefreshLoading(true)
    void refresh(id)
      .then(() => setRefreshLoading(false))
      .catch(() => setRefreshLoading(false))
  }
  const data = useMemo(() => {
    const events: NodeEventWithDate[] = recipeLogs.reduce((acc, logItem,) => {
      const eventsWithDate: NodeEventWithDate[] = logItem.events.map((event) => ({ ...event, date: logItem.date }))
      return acc.concat(eventsWithDate)
    }, [])
    const inputs = events.filter((event) => event.nodeType === 'addFundsNode')
    const outputs = events.filter((event) => event.nodeType === 'sendToWalletNode' || event.nodeType === 'liquidateNode')
    return ({
      outputs,
      inputs
    })
  }, [recipeLogs])

  useEffect(() => {
    Promise.all([...data.outputs?.map(async currentEvent => {
      const currentOutput = currentEvent.output
      const tokenLength = currentOutput?.token?.length
      const token = tokenLength < 42 ? currentOutput.token : tokenAddressToName(currentOutput.token)
      const amount = await getEtherNumberAmountFromAddress(tokenLength === 42 ? currentOutput.token : tokenNameToAddress(currentOutput.token), currentOutput?.amount.toString())
      const output = `${+amount} ${token}`
      const date = <><FormattedDate value={currentEvent?.date} /> <FormattedTime value={currentEvent?.date} /></>
      const value = '$' + (currentEvent.extraData.outputTokenWithPrice?.totalValueUSD || '???')
      return { output, date, value }
    }), ...performance?.blockedTokens.map(async (currentToken) => await getActiveOutput(currentToken)),
    ...performance?.rewardsValue.map(async (reward) => await getActiveOutput(reward))
    ])
      .then((newOutput) => {
        const filteredUndefined = newOutput.filter((output) => !!output)
        setOutputTableData(filteredUndefined)
      })
      .catch(e => console.error(e.message))
  }, [data.outputs, performance])

  useEffect(() => {
    Promise.all(data.inputs?.map(async currentEvent => {
      const currentInput = currentEvent.input
      const tokenLength = currentInput?.token?.length
      const token = tokenLength < 42 ? currentInput.token : tokenAddressToName(currentInput.token)
      const amount = await getEtherNumberAmountFromAddress(tokenLength === 42 ? currentInput.token : tokenNameToAddress(currentInput.token), currentInput?.amount.toString())
      const input = `${+amount} ${token}`
      const date = <><FormattedDate value={currentInput?.date} /> <FormattedTime value={currentInput?.date} /></>
      const value = '$' + (currentEvent.extraData.inputTokenWithPrice?.totalValueUSD || '???')
      return { input, date, value }
    })).then((newInput) => {
      setInputTableData(newInput)
    }).catch(e => console.error(e.message))
  }, [data.inputs])

  return (
    <div id="apr-details" className="page">
      <img className="apr-image" src={logo} alt="apr illustration" />
      <div className="container">
        <header>
          <Link className="back-link" to={"/dashboard/" + comeBackRoute}>
            <img src={arrow} />
            <span><FormattedMessage id="back" /></span>
          </Link>
        </header>
        <section className="recipe-details">
          <h1>{recipeDetails?.title || ''}</h1>
          <span className="status">
            <FormattedMessage id={`apr-details.status-${status}`} />
          </span>
        </section>
        <section className="recipe-date">
          <h2>
            <span><FormattedMessage id="apr-details.start-date" /></span>
            <strong><FormattedDate value={startDate} /></strong>
            <span><FormattedTime value={startDate} /></span>
          </h2>
          {status === 'finished' &&
            <h2>
              <span><FormattedMessage id="apr-details.end-date" /></span>
              <strong><FormattedDate value={endDate} /></strong>
              <span><FormattedTime value={endDate} /></span>
            </h2>
          }
        </section>
        <section className="recipe-specific">
          <h3 className={`apr-info ${roi > 0 ? 'positive' : ''}`}>
            <strong><FormattedMessage id="apr-details.roi-info" /></strong>
            <span>{(roi * 100).toFixed(2)} %</span>
          </h3>
          <h3 className="earned-info">
            <strong><FormattedMessage id="apr-details.earned-info" /></strong>
            <span>$ {earned}</span>
          </h3>
          <h3 className="elapsed-info">
            <strong><FormattedMessage id="apr-details.elapsed-info" /></strong>
            <span>{!!elapsed && dhm(elapsed)}</span>
          </h3>
          <div className="refresh-wrapper">
            <button className="refresh-button" onClick={handleRefresh}>
              <FormattedMessage id="apr-details.refresh-data" />
            </button>
            <span className={`refresh-status ${isRefreshLoading ? 'status-loading' : 'status-loaded'}`} />
          </div>
        </section>
        <section className="events">
          <div className="input-table">
            <h3><FormattedMessage id="apr-details.input-title" /></h3>
            <TableEvents name="input" data={inputTableData} />
          </div>
          <div className="output-table">
            <h3><FormattedMessage id="apr-details.output-title" /></h3>
            <TableEvents name="output" data={outputTableData} />
          </div>
        </section>
      </div>
    </div>
  )
}

const AprDetailsWrapper = () =>
  <Suspense fallback={<LoadingAprDetails />}>
    <AprDetails />
  </Suspense>

export default AprDetailsWrapper
