import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { registerModal, CloseModal, useModal } from '@gluedigital/modal';
import { AddFundsModalData } from "src/routes/RecipeDiagram/helpers/types"
import web3 from "src/utils/web3"
import { getBalance } from "./GetBalances"
import { getTokenAddress } from '../../../../routes/RecipeDiagram/helpers/nodeHelpers'
import { BaseNodeModal } from "../BaseNodeModal"
import { getEtherNumberAmountFromAddress, getWeiNumberAmountDependOnDecimals } from "src/contracts/TokensDecimalsHelpers"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper";
import { ENVIRONMENT } from "src/constants"
import "./AddFundsModal.sass"
import "src/components/modals/Modals.sass"

interface AddFundsModalPropTypes extends BaseNodeModal<AddFundsModalData> { }

const coins = ENVIRONMENT.tokens
const MINIMUN_VALUE: number = 0

const AddFundsModal = (props: AddFundsModalPropTypes) => {
  const { onSave, previousData } = props
  const account = useSelector((s: any) => (s.user ? s.user.account : undefined))
  const modal = useModal()
  const [amount, setAmount] = useState<number>(-1)
  const [outputCoin, setCoin] = useState<string>(previousData?.outputCoin || coins[0].id)

  useEffect(() => {
    if (previousData?.amount) {
      getEtherNumberAmountFromAddress(tokenNameToAddress(previousData?.outputCoin), previousData?.amount.toString())
        .then((amount) => setAmount(Number(amount)))
        .catch(err => console.error(err.message))
    }
  }, [previousData?.amount, previousData?.outputCoin])

  const handleBalance = () => {
    getBalance(web3, account, getTokenAddress(outputCoin))
      .then((res: number) => setAmount(res))
      .catch(err => console.error(err.message))
  }

  return (
    <div className="add-funds-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button className="modal-close-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="add-funds-modal.title" /></h1>
          <button
            disabled={amount <= MINIMUN_VALUE}
            onClick={() => {
              if (amount > 0) {
                getWeiNumberAmountDependOnDecimals(outputCoin, amount)
                  .then((amount) => {
                    onSave({ amount, outputCoin })
                    modal.hide()
                  })
                  .catch(err => console.error(err.message))
              }
            }}
            className="filled"
          >
            <FormattedMessage id="save" />
          </button>
        </header>
        <section>
          <div className="input-container">
            <label>
              <div>
                <span className="span-coin">
                  <FormattedMessage id={'add-funds-modal.coin'} />
                </span>
                <select onChange={(ev) => {
                  setCoin(ev.target.value)
                }}
                  name="coins" value={outputCoin}>
                  {coins.map(c => (
                    <option key={c.id} value={c.id}>{c.id}</option>
                  ))}
                </select>
              </div>
              <FormattedMessage id="placeholder.amount">
                {(text) => (
                  <input
                    type="number"
                    onChange={(e) => {
                      e.preventDefault();
                      setAmount(Number(parseFloat(e.target.value).toFixed(6)))
                    }}
                    placeholder={text as string}
                    value={amount >= MINIMUN_VALUE ? amount : text as string}
                    min={MINIMUN_VALUE}
                  />
                )}
              </FormattedMessage>
            </label>
            <button className="max-button" onClick={handleBalance}>
              <FormattedMessage id="max" />
            </button>
          </div>
        </section>
      </div>
    </div>
  )
}

registerModal("add-funds-modal", AddFundsModal)
