import React, { Suspense } from "react"
import { FormattedMessage } from "react-intl"
import { useAllFarmsIdFromApi, useFarmsOneDay, useLastSubgraphBlockFromApi } from "src/api/recipes"
import { FarmNodeData, NodeToken } from "src/routes/RecipeDiagram/helpers/types"
import { tokenAddressToName } from "../../Diagram/nodes/nodesLogsHelper"
import { ViewRecipeLogsProps } from "../ViewRecipeLogs/ViewRecipeLogs"
import { useLpEarned } from "src/hooks/useFarmsLogs"
import { FarmData, FarmGraphData, PairDayData, PairInfoSpooky } from "src/api/farms/types"
import { useGetLPInfo } from "src/hooks/useLPsLogs"
import { useBlockNumberFromTxHash, useDepositBlockNumberFromTxHash } from "src/hooks/useWeb3"
import { DepositedFarmLeftLog, LoadingDepositFarmLeftLog } from './DepositedFarmLeftLog'
import { EarnedFarmLeftLog, LoadingEarnedLeftLog } from "./FarmMCV2LeftLog/EarnedFarmLeftLog"
import { TokenEndFarmLeftLog } from "./TokenEndFarmLeftLog"
import { FinishedFarmLeftLog } from "./FarmMCV2LeftLog/FinishedFarmLeftLog"
import { EarnedFarmV3LeftLog } from "./FarmMCV3LeftLog/EarnedFarmV3LeftLog"
import { FinishedFarmV3LeftLog } from "./FarmMCV3LeftLog/FinishedFarmV3LeftLog"
import { AprFarmV3LeftLog } from "./FarmMCV3LeftLog/AprFarmV3LeftLog"

import 'src/routes/RecipeDiagram/SideBarViewLogs/SidebarViewLogs.sass'

interface FarmExtraDataLogProps extends ViewRecipeLogsProps {
  data: FarmNodeData
}

const FarmExtraDataLog = (props: FarmExtraDataLogProps) => {
  const { events, data, date, trans, recipeStatus } = props
  const NO_EXECUTED_NODE: number = 0
  const ACTIVE_NODE: number = 2
  const FINISHED_NODE: number = 4
  const ABORTED_NODE: number = 5
  const myFarmId: string = data.pair.id
  const myFarmAddress = myFarmId.slice(0, -6)
  let token0: NodeToken
  let token1: NodeToken
  let executionSteps: number
  let blockNumberAtDeposit: number = null
  let blockNumberAtOtherMoment: number = null

  if (events !== undefined && events !== null) {
    executionSteps = events.length
    const splitEvent = events.find((event) => event.functionName === 'split')
    if (splitEvent) {
      const token0Log = splitEvent.output[0]
      const token1Log = splitEvent.output[1]
      token0 = { token: tokenAddressToName(token0Log.token.toLowerCase()), amount: token0Log.amount }
      token1 = { token: tokenAddressToName(token1Log.token.toLowerCase()), amount: token1Log.amount }
    }
  } else {
    executionSteps = 0
  }

  const graphLastBlock: number = useLastSubgraphBlockFromApi()
  blockNumberAtDeposit = useDepositBlockNumberFromTxHash(trans[0])
  blockNumberAtOtherMoment = useBlockNumberFromTxHash(trans, executionSteps)
  if (executionSteps === ACTIVE_NODE || executionSteps === FINISHED_NODE) {
    if (executionSteps === ACTIVE_NODE) {
      if (recipeStatus === "active" || recipeStatus === "failed" || recipeStatus === "finished") blockNumberAtOtherMoment = graphLastBlock
      // } else if (recipeStatus === "finished") { // this case is if the recipe is aborted
      //   blockNumberAtOtherMoment = useBlockNumberFromTxHash(trans[0])
      // }
    }
  } else if (executionSteps === NO_EXECUTED_NODE) {
    blockNumberAtDeposit = null
    blockNumberAtOtherMoment = graphLastBlock
  } else if (executionSteps === ABORTED_NODE) {
    blockNumberAtOtherMoment = graphLastBlock
  }
  const blockToUse: number = blockNumberAtOtherMoment > graphLastBlock ? graphLastBlock : blockNumberAtOtherMoment
  const allFarms: FarmGraphData = useFarmsOneDay()
  const masterchefInfo: PairDayData = allFarms.pools.data.find((pair) => pair.pairAddress === myFarmAddress)
  const allFarmLPs: string[] = useAllFarmsIdFromApi(2)
  const myFarm: PairInfoSpooky = useGetLPInfo(myFarmAddress, blockToUse)
  const splitDecimals = myFarm.reserveUSD.split('.')
  const liquidity = `${splitDecimals[0]}.${splitDecimals[1].slice(0, 2)}`
  const farmData: FarmData = useLpEarned(events[0].nodeID, date[0], executionSteps)
  const lpDeposited: number = Number(/^\d/.test(farmData?.lpDeposited) ? farmData?.lpDeposited.slice(0, -2) || 'Loading...' : farmData?.lpDeposited)
  const lpEarned: number = Number(farmData?.earned.slice(0, -2))
  const totalLPAmount: number = lpDeposited + lpEarned

  return (
    <div className="div-extra-info">
      <div className="first-column">
        <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.deposited" /></p>
          <div className="left-empty-position"></div>
        </div>
        {executionSteps === ACTIVE_NODE &&
          <div className="logs-group-rows">
            <p><FormattedMessage id="farm-modal-log.current-value" /></p>
            <div className="left-empty-position"></div>
            <div className="left-empty-position"></div>
          </div>
        }
        {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) &&
          <div className="logs-group-rows">
            <p><FormattedMessage id="farm-modal-log.final-value" /></p>
            <div className="left-empty-position"></div>
          </div>
        }
        <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.earned" /></p>
          <div className="left-empty-position"></div>
        </div>
        <p><FormattedMessage id="farm-modal-log.token0" /></p>
        <p><FormattedMessage id="farm-modal-log.token1" /></p>
        <p><FormattedMessage id="farm-modal-log.apr" /></p>
        <p><FormattedMessage id="farm-modal-log.liquidity" /></p>
      </div>
      <Suspense fallback={"Loading ..."}>
        <div className="second-column">
          <div className="logs-group-rows">
            <Suspense fallback={<LoadingDepositFarmLeftLog />}>
              <DepositedFarmLeftLog graphLastBlock={graphLastBlock} blockNumberAtDeposit={blockNumberAtDeposit} myFarmId={myFarmId} lpDeposited={lpDeposited} />
            </Suspense>
          </div>
          <Suspense fallback={<LoadingEarnedLeftLog recipeStatus={recipeStatus} />}>
            {executionSteps === ACTIVE_NODE &&
              (masterchefInfo.poolIdV3 || masterchefInfo.poolIdV3 === 0 ?
                <EarnedFarmV3LeftLog
                  masterchefInfo={masterchefInfo}
                  blockNumberAtOtherMoment={blockNumberAtOtherMoment}
                  blockNumberAtDeposit={blockNumberAtDeposit}
                  lpDeposited={lpDeposited}
                  totalLPAmount={totalLPAmount}
                  myFarmId={myFarmId}
                /> :
                <EarnedFarmLeftLog
                  blockNumberAtOtherMoment={blockNumberAtOtherMoment}
                  blockNumberAtDeposit={blockNumberAtDeposit}
                  lpDeposited={lpDeposited}
                  totalLPAmount={totalLPAmount}
                  myFarmId={myFarmId}
                  allFarmLPs={allFarmLPs}
                />
              )
            }
          </Suspense>
          <Suspense fallback={<LoadingEarnedLeftLog recipeStatus={recipeStatus} />}>
            {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) &&
              (masterchefInfo.poolIdV3 || masterchefInfo.poolIdV3 === 0 ?
                <FinishedFarmV3LeftLog
                  masterchefInfo={masterchefInfo}
                  blockNumberAtOtherMoment={blockNumberAtOtherMoment}
                  blockNumberAtDeposit={blockNumberAtDeposit}
                  lpDeposited={lpDeposited}
                  totalLPAmount={totalLPAmount}
                  myFarmId={myFarmId}
                /> :
                <FinishedFarmLeftLog
                  blockNumberAtOtherMoment={blockNumberAtOtherMoment}
                  blockNumberAtDeposit={blockNumberAtDeposit}
                  lpDeposited={lpDeposited}
                  totalLPAmount={totalLPAmount}
                  myFarmId={myFarmId}
                  allFarmLPs={allFarmLPs}
                />
              )
            }
          </Suspense>
          <Suspense fallback="Loading token0 amount" >
            <TokenEndFarmLeftLog blockNumberAtOtherMoment={blockNumberAtOtherMoment} myFarmId={myFarmId} tokenName={token0?.token} totalLP={totalLPAmount} />
          </Suspense>
          <Suspense fallback="Loading token1 amount" >
            <TokenEndFarmLeftLog blockNumberAtOtherMoment={blockNumberAtOtherMoment} myFarmId={myFarmId} tokenName={token1?.token} totalLP={totalLPAmount} />
          </Suspense>
          <Suspense fallback="Loading APR">
            <AprFarmV3LeftLog masterchefInfo={masterchefInfo} blockNumberAtOtherMoment={blockNumberAtOtherMoment} myFarm={myFarm} />
          </Suspense>
          <p>{parseFloat(liquidity).toLocaleString()} $</p>
        </div>
      </Suspense>
    </div>
  )
}

export default FarmExtraDataLog
