import React from 'react'
import investors from 'src/data/investors.json'
import partners from 'src/data/partners.json'

import './BackedLogos.sass'

const BackedLogos = () => {
  return (
    <div>
      <div className="div-partners">
        <span className="span-partners-investors">Partners </span>
      </div>

      <div className="div-backed-logos" id="backed-partners">
        {partners.map(partner =>
          <div key={partner.name} className={`logo-card ${partner.name}`}>
            <img src={`/logos/${partner.src}?`} alt={`${partner.name} logo image`} />
          </div>)}
      </div>

      <div className="div-investors">
        <span className="span-partners-investors">Investors </span>
      </div>

      <div className="div-backed-logos" id="backed-investors">
        {investors.map(investor =>
          <div key={investor.name} className={`logo-card ${investor.name}`}>
            <img src={`/logos/${investor.src}?`} alt={`${investor.name} logo image`} />
          </div>)}
      </div>
    </div>
  )
}

export default BackedLogos
