import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getTokenImage } from 'src/api/farms/helpers'
import Tooltip from 'src/components/common/ToolTip/Tooltip'

import './PoolInfo.sass'

interface PoolInfoProps {
  pair: any
  isChosen: boolean
  setChosenOption: (option: any) => void
  featured?: boolean
}

const PoolInfo = (props: PoolInfoProps) => {
  const { pair, isChosen, setChosenOption, featured } = props
  const longPairNames = pair.token0.symbol.length + pair.token1.symbol.length > 9
  return (
    <li className={`deposit-pool-info-card ${featured ? 'featured' : ''}`}>
      {featured && <span className="featured-pool-tag"><FormattedMessage id="farm-modal.featured" /></span>}
      <section className="token-container">
        <div className="logos">
          <img className="logo" src={getTokenImage(pair.token0.symbol)} />
          <img className="logo" src={getTokenImage(pair.token1.symbol)} />
        </div>
        <h1 className={`pair-names-container ${longPairNames ? 'long' : ''}`}>
          {pair.token0.symbol} - {pair.token1.symbol}
        </h1>
      </section>
      <section className="section-data">
        <h2 className="data-title">
          <span>
            <FormattedMessage id="deposit-lp-modal.apr" />
            <Tooltip tooltipText={[`Fees APR: ${pair.aprFees.toFixed(2)}%`]} />
          </span>
        </h2>
        <strong>{(pair.aprFees).toFixed(2)} %</strong>
      </section>
      <section className="section-data">
        <h2 className="data-title">
          <span><FormattedMessage id="deposit-lp-modal.liquidity" /></span>
        </h2>
        <strong >{'$' + parseInt(pair.reserveUSD).toLocaleString()}</strong>
      </section>
      <section className="section-data">
        <h2 className="data-title">
          <span><FormattedMessage id="deposit-lp-modal.daily-volume-USD" /></span>
        </h2>
        <strong>{'$' + parseInt(pair.dailyVolumeUSD).toLocaleString()}</strong>
      </section>
      <section className="section-data">
        <h2 className="data-title">
          <span><FormattedMessage id="deposit-lp-modal.provider" /></span>
        </h2>
        <strong>{pair.provider}</strong>
      </section>
      <section className="button-section">
        <button
          className={isChosen ? 'filled' : ''}
          onClick={() => {
            setChosenOption(pair)
          }}
        >
          {!isChosen ? <FormattedMessage id="choose" /> : <FormattedMessage id="chosen" />}
        </button>
      </section>
    </li>
  )
}
export default PoolInfo
