import React, { Suspense, useMemo, useState } from 'react'
import { registerModal, CloseModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import { NestedStrategiesModalData } from 'src/routes/RecipeDiagram/helpers/types'
import { BaseNodeModal } from '../BaseNodeModal'
import nestedStrategiesData from './nestedStrategiesDataTest.json'
import Loading from 'src/components/common/Loading/Loading'
import useInfiniteScroll from 'src/hooks/useInfiniteScroll'
import { ListNestedStrategiesModal } from './ListNestedStrategiesModal'
import { useFetchYearnDaemonAllVaults } from 'src/hooks/useYearn'
import { YearnYDaemonVaultsData } from 'src/api/yearnVaults/yearnTypes'
import { LoadingNestedStrategiesModal } from './LoadingNestedStrategiesModal'

import './NestedStrategiesModal.sass'

interface NestedStrategiesModalProps extends BaseNodeModal<NestedStrategiesModalData> { }

const NestedStrategiesModal = (props: NestedStrategiesModalProps) => {
  const { previousData } = props
  const [search, setSearch] = useState<string>('')
  const [sort, setSort] = useState<string>('APY')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showLoadMoreButton, setLoadMoreButton] = useState<boolean>(true)
  const [chosenOption, setChosenOption] = useState<YearnYDaemonVaultsData>(previousData?.vault)
  const PAGE_SIZE: number = 9

  const loadMoreHandler = () => {
    if (PAGE_SIZE * currentPage < nestedStrategiesData.length) {
      setCurrentPage(currentPage => currentPage + 1)
    } else {
      setLoadMoreButton(false)
    }
  }
  const vaultsData: YearnYDaemonVaultsData[] = useFetchYearnDaemonAllVaults()

  const readyNestedStrategiesData = useMemo(() => {
    const searchText = search.toLowerCase()
    const updatedStrategies = [...vaultsData].filter((strategy) => strategy.formated_name.toLowerCase().includes(searchText))
    switch (sort) {
      case 'APY':
        updatedStrategies.sort((a, b) => Number(b.apy) - Number(a.apy))
        break
      case 'TOTAL ASSETS':
        updatedStrategies.sort((a, b) => Number(b.tvl.total_assets_usd) - Number(a.tvl.total_assets_usd))
        break
      // case 'PROVIDER':
      //   updatedStrategies.sort((a, b) => (a.provider > b.provider) ? 1 : ((b.provider > a.provider) ? -1 : 0))
    }
    return updatedStrategies.slice(0, PAGE_SIZE * currentPage)
  }, [vaultsData, currentPage, search, sort])

  const ref: any = useInfiniteScroll(loadMoreHandler)

  return (
    <div className="nested-strategies-modal modal-content">
      <div className="modal-wrapper" ref={ref}>
        <header className="header-buttons">
          <CloseModal>
            <button className="modal-close-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="nested-strategies-modal.title" />
          </h1>
          <CloseModal>
            <button
              className="filled"
              onClick={() => { props.onSave({ vault: chosenOption }) }}
            >
              <FormattedMessage id="save" />
            </button>
          </CloseModal>
        </header>
        <section className="main-section">
          <header>
            <label className="search">
              <FormattedMessage id="deposit-lp-modal.placeholder">
                {(text: string) => (
                  <input
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={text}
                  />
                )}
              </FormattedMessage>
              <span className="icon icon-search" />
            </label>
            <div className="select-wrapper">
              <select defaultValue={sort} onChange={(e) => setSort(e.target.value)}>
                <FormattedMessage id="nested-strategies-modal.sort-by-apy">
                  {(text) => <option value="APY">{text}</option>}
                </FormattedMessage>
                <FormattedMessage id="nested-strategies-modal.sort-by-total-assets">
                  {(text) => <option value="TOTAL ASSETS">{text}</option>}
                </FormattedMessage>
                <FormattedMessage id="deposit-lp-modal.sort-by-provider">
                  {(text) => <option value="PROVIDER">{text}</option>}
                </FormattedMessage>
              </select>
            </div>
          </header>
          {nestedStrategiesData.length !== 0 ? (
            <ul className="farm-list">
              {readyNestedStrategiesData.map((vault, i) =>
                <ListNestedStrategiesModal
                  key={i}
                  vault={vault}
                  isChosen={chosenOption ? chosenOption?.formated_name === vault.formated_name : previousData?.vault?.formated_name === vault.formated_name}
                  setChosenOption={(vault: YearnYDaemonVaultsData) => setChosenOption(vault)} />
              )}
              {showLoadMoreButton &&
                <li className="load-more-wrapper">
                  <button className="load-more-button" onClick={loadMoreHandler}>
                    <FormattedMessage id="load-more" />
                  </button>
                </li>}
            </ul>
          ) : (
            <div className="table-body loading">
              <Loading />
            </div>)}
        </section>
      </div>
    </div>

  )
}

const NestedStrategiesModalWrapper = (props: NestedStrategiesModalProps) => (
  <Suspense fallback={<LoadingNestedStrategiesModal />}>
    <NestedStrategiesModal {...props} />
  </Suspense>
)

registerModal('nested-strategies-modal', NestedStrategiesModalWrapper)
