const initialState = false

export default (state = initialState, action) => {
  switch (action.type) {
    case '@@_AFTER_RENDER':
      return true
    default:
      return state
  }
}
