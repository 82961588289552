import React from 'react'
import { TokenInfoSpooky } from 'src/api/farms/types'
import { BOO_ADDRESS, ENVIRONMENT } from 'src/constants'
import { useEndBooAmountFromWithdrawEvent } from 'src/hooks/useFarmsLogs'
import { useLPUsdPrice } from 'src/hooks/useLPsLogs'
import { useGetTokenPriceUsd } from 'src/hooks/useTokensLogsFromGraph'
interface EarnedFarmLeftLogProps {
  lpDeposited: number
  totalLPAmount: number
  myFarmId: string
  blockNumberAtDeposit: number
  blockNumberAtOtherMoment: number
  allFarmLPs: string[]
}
export const FinishedFarmLeftLog = (props: EarnedFarmLeftLogProps) => {
  const { lpDeposited, totalLPAmount, myFarmId, blockNumberAtDeposit, blockNumberAtOtherMoment, allFarmLPs } = props

  const amountBOOWei = useEndBooAmountFromWithdrawEvent(myFarmId.slice(0, -6), allFarmLPs)
  const amountBOOEther = amountBOOWei / 10 ** 18
  const rewardTokenData: TokenInfoSpooky = useGetTokenPriceUsd(BOO_ADDRESS[ENVIRONMENT.chainId], blockNumberAtOtherMoment)
  const lpUSDPriceAtDeposit: number = useLPUsdPrice(myFarmId, blockNumberAtDeposit)
  const lpUSDPriceAtOtherMoment: number = useLPUsdPrice(myFarmId, blockNumberAtOtherMoment)
  const lpUSDValueAtDeposit: number = lpDeposited * lpUSDPriceAtDeposit
  const totalLPUSDValueAtOtherMoment: number = lpUSDPriceAtOtherMoment * totalLPAmount
  const booUSDValueAtOtherMoment: number = parseFloat(rewardTokenData.priceUSD) * amountBOOEther
  const totalUSD: number = totalLPUSDValueAtOtherMoment + booUSDValueAtOtherMoment

  return (
    <>
      {/* FINAL VALUE */}
        <div className="logs-group-rows">
          <p className="main-text-logs">$ {totalLPUSDValueAtOtherMoment.toFixed(3)} </p>
          <p>{totalLPAmount.toFixed(12)}  LPs</p>
        </div>

     {/* EARNED */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {(totalUSD - lpUSDValueAtDeposit).toFixed(3)}</p>
        <p>{(totalLPAmount - lpDeposited).toFixed(12)} LPs</p>
      </div>
    </>
  )
}

interface LoadingEarnedLeftLogProps {
  recipeStatus: string
}

export const LoadingEarnedLeftLog = ({ recipeStatus }: LoadingEarnedLeftLogProps) => {
  return (
    <>
      {recipeStatus === "active"
        ?
        <div className="logs-group-rows">
          <p>Loading current value</p>
          <div className="left-empty-position"></div>
          <div className="left-empty-position"></div>
        </div>
        :
        <div className="logs-group-rows">
          <p>Loading final value</p>
          <div className="left-empty-position"></div>
        </div>}

      <div className="logs-group-rows">
        <p>Loading earned</p>
        <div className="left-empty-position"></div>
      </div>
    </>
  )
}
