import usePromise from "./usePromise"
import { clientSpooky } from 'src/components/ApolloClient'
import { GET_LAST_BLOCK } from 'src/api/farms/thegraph/pairsQueries'
import { ApolloQueryResult } from "@apollo/client"

const getLastSyncGraphBlock = async (): Promise<ApolloQueryResult<any>> => {
    const blockQuery = GET_LAST_BLOCK
    const res = await clientSpooky.query({ query: blockQuery })
    return res
}

export const useLastSyncGraphBlock = (): number => {
    const lastBlockInfo = usePromise(getLastSyncGraphBlock)
    return lastBlockInfo.data._meta.block.number
}
