
import React, { useCallback, useEffect, useState } from "react"
import web3 from "src/utils/web3"
import { ApprovalStatus, TokenForApproval } from "src/routes/RecipeDiagram/helpers/types"
import { useSelector } from "react-redux"
import { amountIsAlreadyApproved } from "src/contracts/ApproveHelpers"

interface RetryApprovesTokensProps {
  tokens: TokenForApproval[]
  setAllApproved: (isApproved: boolean) => void
  hasInsufficientBalance: boolean
  convertWei: (address: string, amount: string) => Promise<string>
}

const RetryApprovesTokens = (props: RetryApprovesTokensProps) => {
  const { tokens, setAllApproved, convertWei } = props

  const user = useSelector((s: any) => s.user)
  const userAddress = user.account.account
  const [tokensForApproval, setTokensForApproval] = useState<(TokenForApproval)[]>([...tokens])
  const [newTokensForApproval, setNewTokensForApproval] = useState<(TokenForApproval)[]>([...tokens])

  const setTokenStatus = useCallback(
    (tokenID: string, status: ApprovalStatus) => {
      const updatedTokenIndex = tokensForApproval.indexOf(tokensForApproval.find((token) => token.id === tokenID))
      tokensForApproval[updatedTokenIndex].status = status
      const updatedArray = [...tokensForApproval]
      setTokensForApproval(updatedArray)
    },
    [tokensForApproval],
  )

  useEffect(() => {
    amountIsAlreadyApproved(web3, userAddress, tokensForApproval, setTokenStatus)
      .catch(err => console.error(err.message))
  }, [setTokenStatus, tokensForApproval, userAddress])

  useEffect(() => {
    let isAllApproved
    for (const token of tokensForApproval) {
      if (token.status !== 'approved') {
        isAllApproved = false
        break
      }
      isAllApproved = true
    }
    setAllApproved(isAllApproved)
  }, [setAllApproved, tokensForApproval])

  useEffect(() => {
    Promise.all(tokensForApproval.map(async (token) => {
      const newAmount = await convertWei(token.address, token.amount.toString())
      return ({ ...token, amount: newAmount })
    })).then((newTokens: TokenForApproval[]) => {
      setNewTokensForApproval(newTokens)
    }).catch(err => console.error(err.message))
  }, [convertWei, tokensForApproval])

  return (
    <div className="approval-list approves">
      <div className="tokens-list">
        {newTokensForApproval.map((token) => (
          <div key={token.id}>
            <span className={`status-icon ${token.status}`} />
            <span className="token-tag"> <span className="amount-tag">{token.amount}</span>  {token.id}</span>
            <span className="token-name">{token.id}</span>
          </div>))
        }
      </div>
    </div>
  )
}

export default RetryApprovesTokens
