
// Returns a copy of the array with the updated element

import { getOutgoers } from "react-flow-renderer";
import { EdgeElement, NodeData, NodeElement } from "./types";

// The only data propagating so far is the outputcoin, update this in case the requirements change
const updateNextElement = (nodes: NodeElement[], edges: EdgeElement[], nextElement: NodeElement, outputCoin: string) => {
  let newNextTarget: any
  const nextElementIndex: number = nodes.indexOf(nextElement)
  const updatedNodes = [...nodes]

  // Populate next target
  switch (nextElement?.type) {
    case 'comboTriggerNode':
      newNextTarget = { ...nextElement, data: { ...nextElement?.data, outputCoin, inputCoin: outputCoin }, };
      break
    case 'farmNode':
      newNextTarget = { ...nextElement, data: { ...nextElement?.data, inputCoin: outputCoin, outputMain: outputCoin, outputReward: outputCoin }, };
      break
    case 'nestedStrategiesNode':
      newNextTarget = { ...nextElement, data: { ...nextElement?.data, inputCoin: outputCoin, outputCoin }, };
      break
    case 'addFundsNode':
      break
    default:
      newNextTarget = { ...nextElement, data: { ...nextElement?.data, inputCoin: outputCoin }, };
      break
  }

  // Update next target
  updatedNodes[nextElementIndex] = newNextTarget

  // Further propagate changes
  if (nextElement?.type === 'farmNode' || nextElement?.type === 'depositOnLPNode' || nextElement?.type === 'nestedStrategiesNode') {
    const arrowAfterNextElem = edges.find((edge) => edge.source === nextElement?.id)
    if (arrowAfterNextElem) {
      const comboAfterNextElem = nodes.find((node) => node.id === arrowAfterNextElem.target)
      return updateComboTrigger(updatedNodes, edges, comboAfterNextElem, outputCoin)
    }
  }
  return updatedNodes
}

// Returns a copy of the elements array, with the updated elements
export const updateComboTrigger = (nodes: NodeElement[], edges: EdgeElement[], comboNode, outputCoin: string): NodeElement[] => {
  let updatedNodes = updateNextElement(nodes, edges, comboNode, outputCoin)

  const arrowAfterCombo = edges.find((edge) => edge.source === comboNode.id)
  if (arrowAfterCombo) {
    const nextNode = updatedNodes.find((node) => arrowAfterCombo.target === node.id)

    if (nextNode.type === 'comboTriggerNode') {
      updatedNodes = updateComboTrigger(updatedNodes, edges, nextNode, outputCoin)
    } else {
      updatedNodes = updateNextElement(updatedNodes, edges, nextNode, outputCoin)
    }
  }
  return updatedNodes
}

export const propagateChanges = (updatedNode: NodeElement, nodes: NodeElement[], edges: EdgeElement[]): NodeElement[] => {
  const outgoers = getOutgoers<NodeData>(updatedNode, nodes, edges)
  if (outgoers.length <= 0) {
    return [...nodes]
  }
  if (updatedNode.type === 'splitNode') {
    const firstEdge = edges.find((edge) => edge.source === updatedNode.id && edge.sourceHandle === "split-output-1")
    const secondEdge = edges.find((edge) => edge.source === updatedNode.id && edge.sourceHandle === "split-output-2")
    const firstBranchNode = outgoers.find((outgoer) => outgoer.id === firstEdge?.target)
    const secondBranchNode = outgoers.find((outgoer) => outgoer.id === secondEdge?.target)
    let updatedNodes = [...nodes]
    if (firstBranchNode?.type === 'comboTriggerNode') {
      updatedNodes = updateComboTrigger(updatedNodes, edges, firstBranchNode, updatedNode.data.firstCoin)
    } else {
      updatedNodes = updateNextElement(updatedNodes, edges, firstBranchNode as NodeElement, updatedNode.data.firstCoin)
    }
    if (secondBranchNode?.type === 'comboTriggerNode') {
      return updateComboTrigger(updatedNodes, edges, secondBranchNode, updatedNode.data.secondCoin)
    } else {
      return updateNextElement(updatedNodes, edges, secondBranchNode as NodeElement, updatedNode.data.secondCoin)
    }
  }
  if (updatedNode?.type === 'addFundsNode' || updatedNode?.type === 'swapNode') {
    const { outputCoin } = updatedNode.data
    if (outgoers[0].type === "comboTriggerNode") {
      return updateComboTrigger(nodes, edges, outgoers[0], outputCoin)
    } else {
      return updateNextElement(nodes, edges, outgoers[0] as NodeElement, outputCoin)
    }
  }
  return [...nodes]
}

export const insertElementSpecificIndex = (els, index, itemToInsert) => { return [...els.slice(0, index), itemToInsert, ...els.slice(index)] }
