import React from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal, useModal } from "@gluedigital/modal"
import { useHistory } from 'react-router-dom';
import "./TermsConditionsModal.sass"

const TermsConditionsModal = () => {
    const history = useHistory()
    const modal = useModal()
    const goBackHome = () => { history.push('/') }

    const acceptHandler = () => {
        localStorage.setItem("deviceAccepted", "true")
        history.push('/dashboard/your')
        modal.hide()
    }
    return (
        <div className="terms-and-conditions modal-content">
            <div className="modal-wrapper">
                <header className="header-buttons">
                    <CloseModal>
                        <button onClick={goBackHome} className="back-button">
                            <span className="icon icon-close" />
                            <span><FormattedMessage id="close" /></span>
                        </button>
                    </CloseModal>
                </header>
                <div className="title-div">
                    <h2 className="terms-and-conditions"><FormattedMessage id="terms-and-conditions.tittle" /></h2>
                </div>
                <div className="text-wrapper-terms-and-conditions">
                    <CloseModal>
                        <div className="terms-text"> <FormattedMessage id="terms-and-conditions-text01"/> </div>
                        <div className="terms-text"> <FormattedMessage id="terms-and-conditions-text02"/> </div>
                        <span className="terms-text"> <FormattedMessage id="terms-and-conditions-text03"/> </span>
                        <div className="terms-text"> <FormattedMessage id="terms-and-conditions-text04"/> </div>
                        <div className="terms-text"> <FormattedMessage id="terms-and-conditions-text05"/> </div>
                        <div className="terms-text"> <FormattedMessage id="terms-and-conditions-text06"/> </div>
                    </CloseModal>
                    <button onClick={acceptHandler}><FormattedMessage id="accept-terms-and-conditions.apply" /></button>
                </div>
            </div>
        </div>
    )
}

registerModal("terms-and-conditions-modal", TermsConditionsModal)
