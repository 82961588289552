import React from "react"
import { FormattedMessage } from "react-intl"

import "./Sidebar.sass";

const Sidebar = () => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType)
    event.dataTransfer.effectAllowed = "move"
  }

  const nodeList = ["addFundsNode", "splitNode", "swapNode", "depositOnLPNode", "farmNode", "comboTriggerNode", "sendToWalletNode", "liquidateNode"]

  return (
    <aside className="diagram-sidebar">
      <ul className="dnd-nodes-list">
        {nodeList.map(n => (
          <li key={n} className={`dnd-node dnd-node-${n}`} onDragStart={(event) => onDragStart(event, n)} draggable>
            <img className="node-icon" src={`/images/new-node-icons/${n}-icon.png`} alt={`sidebar ${n} icon`} />
            <span><FormattedMessage id={`node.${n}`} /></span>
          </li>
        ))}
      </ul>
    </aside>
  )
}

export default Sidebar
