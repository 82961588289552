import contracts from './reducers/contracts'
import user from './reducers/user'
import ready from './reducers/ready'
import toasts from './reducers/toasts'
import validation from './reducers/validation'
import diagram from './reducers/diagram'

export const reducerList = {
  // Add your reducers here
  user,
  contract: contracts,
  ready,
  toasts,
  validation,
  diagram
}

export default reducerList
