import React, { useState, useEffect, useMemo, Suspense } from 'react'
import { registerModal, CloseModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import { FarmModalData, FarmMode, LPPair, FarmPeriod } from 'src/routes/RecipeDiagram/helpers/types'
import Loading from 'src/components/common/Loading/Loading'
import { BaseNodeModal } from "../BaseNodeModal"
import FarmInfo from './FarmInfo'
import featuredFarms from 'src/data/featuredFarm.json'
import useInfiniteScroll from 'src/hooks/useInfiniteScroll'
import { obtainAvg5DaysFarmsData } from 'src/api/farms/farmHelpers'
import { FarmGraphData, PairDayData } from 'src/api/farms/types'
import { useFarmsFiveDays } from 'src/api/recipes'

import './FarmModal.sass'
interface FarmModalPropTypes extends BaseNodeModal<FarmModalData> { }

const PAGE_SIZE = 9

const FarmModal = (props: FarmModalPropTypes) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [showLoadMoreButton, setLoadMoreButton] = useState(true)
  const [chosenOption, setChosenOption] = useState(null)
  const [pairsFiveDaysAvg, setPairsFiveDaysAvg] = useState<PairDayData[]>([])
  const [sort, setSort] = useState<string>('APR')
  const [search, setSearch] = useState('')
  const [featuredPairs, setFeaturedPairs] = useState([])
  const [farmMode, setFarmMode] = useState<FarmMode>('autocompound')
  const [farmPeriod, setFarmPeriod] = useState<FarmPeriod>()

  const readyPairs: PairDayData[] = useMemo(() => {
    const searchText = search.toLowerCase()
    const updatedPairs: PairDayData[] = [...pairsFiveDaysAvg].filter((p) => {
      return (
        p.token0.id.toLowerCase().includes(searchText) ||
        p.token1.id.toLowerCase().includes(searchText) ||
        p.token0.symbol.toLowerCase().includes(searchText) ||
        p.token1.symbol.toLowerCase().includes(searchText)
      )
    })
    switch (sort) {
      case 'APR':
        updatedPairs.sort((a, b) => b.aprFarm + b.aprFees - (a.aprFarm + a.aprFees))
        break
      case 'LIQUIDITY':
        updatedPairs.sort((a, b) => Number(b.reserveUSD) - Number(a.reserveUSD))
        break
      case 'VOLUME':
        updatedPairs.sort((a, b) => Number(b.dailyVolumeUSD) - Number(a.dailyVolumeUSD))
        break
      case 'PROVIDER':
        updatedPairs.sort((a, b) => (a.provider > b.provider) ? 1 : ((b.provider > a.provider) ? -1 : 0))
    }
    return updatedPairs.slice(0, PAGE_SIZE * currentPage)
  }, [currentPage, pairsFiveDaysAvg, search, sort])

  const loadMoreHandler = () => {
    if (PAGE_SIZE * currentPage < pairsFiveDaysAvg.length) {
      setCurrentPage(currentPage => currentPage + 1)
    } else {
      setLoadMoreButton(false)
    }
  }
  const ref: any = useInfiniteScroll(loadMoreHandler)

  const resumePair = (pair: any) => {
    return ({
      id: pair.id,
      symbols: `${pair.token0.symbol}-${pair.token1.symbol}`,
      token0: pair.token0,
      token1: pair.token1,
      provider: pair.provider,
      apr: pair.aprFarm + pair.aprFees
    })
  }
  const fiveDaysPairsInfo: FarmGraphData = useFarmsFiveDays()
  const farmValidationCondition = (pair: PairDayData): boolean => {
    return (
      pair?.id !== undefined &&
      pair?.allocPoint !== undefined &&
      pair?.aprFarm !== undefined &&
      pair?.aprFees !== undefined &&
      pair?.pairAddress !== undefined &&
      pair?.provider !== undefined &&
      pair?.reserveUSD !== undefined &&
      pair?.token0 !== undefined &&
      pair?.token1 !== undefined
    )
  }
  useEffect(() => {
    if (fiveDaysPairsInfo !== undefined) {
      if (props?.previousData?.pair?.id) setChosenOption(props?.previousData?.pair?.id)
        const avgFarmsInfo: FarmGraphData = obtainAvg5DaysFarmsData(fiveDaysPairsInfo)
        const pairs: PairDayData[] = avgFarmsInfo.pools.data.filter((pair) => !featuredFarms.includes(pair.pairAddress))
        const pairsWithoutDEI: PairDayData[] = pairs.filter((pair) => pair?.token0?.symbol !== 'DEI' && pair?.token1?.symbol !== 'DEI')
        const securePairFiveDaysAvg: PairDayData[] = pairsWithoutDEI.filter((pair) => farmValidationCondition(pair))
        setFeaturedPairs(avgFarmsInfo.pools.data.filter((pair) => featuredFarms.includes(pair.pairAddress)))
        setPairsFiveDaysAvg(securePairFiveDaysAvg)
    }
  }, [fiveDaysPairsInfo, props?.previousData?.pair?.id])

  const saveHandler = () => {
    const pair: LPPair = {
      id: chosenOption.pair?.id,
      token0: chosenOption.pair?.token0.symbol,
      token1: chosenOption.pair?.token1.symbol,
      provider: chosenOption.pair?.provider
    }
    props.onSave({ pair, mode: farmMode, period: farmPeriod, expectedAPR: chosenOption.pair.apr })
  }

  return (
    <div className="farm-modal modal-content">
      <div className="modal-wrapper" ref={ref}>
        <header className="header-buttons">
          <CloseModal>
            <button className="modal-close-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="farm-modal.title" />
          </h1>
          <CloseModal>
            <button
              disabled={!chosenOption || !farmMode}
              onClick={saveHandler}
              className="filled"
            >
              <FormattedMessage id="save" />
            </button>
          </CloseModal>
        </header>

        <section className="main-section">
          <header>
            <label className="search">
              <FormattedMessage id="deposit-lp-modal.placeholder">
                {(text: string) => (
                  <input
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={text}
                  />
                )}
              </FormattedMessage>
              <span className="icon icon-search" />
            </label>
            <div className="select-wrapper">
              <select defaultValue={sort} onChange={(e) => setSort(e.target.value)}>
                <FormattedMessage id="deposit-lp-modal.sort-by-apr">
                  {(text) => <option value="APR">{text}</option>}
                </FormattedMessage>
                <FormattedMessage id="deposit-lp-modal.sort-by-liquidity">
                  {(text) => <option value="LIQUIDITY">{text}</option>}
                </FormattedMessage>
                <FormattedMessage id="deposit-lp-modal.sort-by-volume">
                  {(text) => <option value="VOLUME">{text}(24h)</option>}
                </FormattedMessage>
                <FormattedMessage id="deposit-lp-modal.sort-by-provider">
                  {(text) => <option value="PROVIDER">{text}</option>}
                </FormattedMessage>
              </select>
            </div>
          </header>
          {pairsFiveDaysAvg.length !== 0 ? (
            <ul className="farm-list">
              {featuredPairs.map((pair, i) =>
                <FarmInfo
                  key={i}
                  pair={pair}
                  isChosen={chosenOption?.pair?.id === pair?.id || chosenOption === pair?.id}
                  farmMode={farmMode} setFarmMode={setFarmMode} farmPeriod={farmPeriod} setFarmPeriod={setFarmPeriod}
                  setChosenOption={(pair) => setChosenOption({ pair: resumePair(pair), autochoose: false })}
                  featured
                />
              )}
              {readyPairs.map((pair, i) =>
                <FarmInfo
                  key={i}
                  pair={pair}
                  isChosen={chosenOption?.pair?.id === pair?.id || chosenOption === pair?.id}
                  farmMode={farmMode} setFarmMode={setFarmMode} farmPeriod={farmPeriod} setFarmPeriod={setFarmPeriod}
                  setChosenOption={(pair) => setChosenOption({ pair: resumePair(pair), autochoose: false })}
                />
              )}
              {showLoadMoreButton &&
                <li className="load-more-wrapper">
                  <button className="load-more-button" onClick={loadMoreHandler}>
                    <FormattedMessage id="load-more" />
                  </button>
                </li>}
            </ul>
          ) : (
            <div className="table-body loading">
              <Loading />
            </div>
          )}
        </section>
      </div>
    </div>
  )
}

const FarmModalWrapper = (props: FarmModalPropTypes) => (
  <Suspense fallback={<Loading />}>
    <FarmModal {...props} />
  </Suspense>
)

registerModal('farm-modal', FarmModalWrapper)
