import React from "react"
import { registerModal, CloseModal, useModal } from "@gluedigital/modal"
import { FormattedMessage } from "react-intl"
import "./ValidationModal.sass"
import { ValidationError } from "src/hooks/useValidator"
import { useDispatch } from "react-redux"
import { focusNode } from "src/store/actions/diagram"

interface ValidationModalProps {
  errors: ValidationError[]
}
const ValidationModal = (props: ValidationModalProps) => {
  const dispatch = useDispatch()
  const modal = useModal()

  const handleClick = (nodeID: string) => {
    dispatch(focusNode(nodeID))
    modal.hide()
  }
  const { errors } = props
  return <div className="modal-content">
    <div className="modal-wrapper">
      <header className="header-buttons">
        <CloseModal>
          <button>
            <span className="icon icon-close" />
            <span><FormattedMessage id="close" /></span>
          </button>
        </CloseModal>
        <h2><FormattedMessage id="validation-modal.title" /></h2>
      </header>
      <div className="validation-modal-wrapper">
        <span><FormattedMessage id="validation-modal.subtitle" /></span>
        <div className="errors-list">
          {errors.map((error) =>
            <div className="error-item" key={`${error.errorType}/${error.node.id}`} onClick={() => handleClick(error.node.id)}>
              <span className="icon icon-exclamation" />
              <span><FormattedMessage id={`node.${error.node?.type}`} />{' '}
                {error.node.type === "sendToWalletNode" ? error.node.data.inputCoin : error.node.type !== "myCustomEdge" ? error.node.data.outputCoin : ' '}
              </span>
              <span className="error-type-label"><FormattedMessage id={`validation-modal.${error.errorType}`} /></span>
            </div>)}
        </div>
      </div>
    </div>
  </div>
}

registerModal('validation-modal', ValidationModal)
