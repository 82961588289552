import React, { Suspense } from "react"
import { DepositOnLPModalData } from "src/routes/RecipeDiagram/helpers/types"
import { LoadingDepositModalLog } from "./LoadingDepositModalLog"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { FormattedMessage } from "react-intl"
import { findNodeInActiveRecipe, generateShowingTime, getCurrentRowLog, getEndingLogForFarmsDeposit, getNumberOfLogsForDepositAndFarms } from "src/components/Diagram/nodes/nodesLogsHelper"
import { getTokenImage } from "src/api/farms/helpers"
import { NodeExecutionResult, RecipeDetails, RecipeExecutionLog } from "src/types"
import NodeStateAndDate from "../NodeStateAndDate/NodeStateAndDate"
import { InfoDepositModalLog } from "./InfoDepositModalLog"
import { ENVIRONMENT } from 'src/constants'
import { useLastSyncGraphBlock } from "src/hooks/useGraph"
import { useDepositBlockNumberFromTxHash, useOtherMomentBlockNumberFromTxHash } from "src/hooks/useWeb3"
import { ModalErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary'
import { usePairDayDataByBlockNumber } from "src/hooks/usePairInfoFromGraph"
import { calculateFeeApr } from "src/api/farms/aprHelpers"

import "./DepositModalLog.sass"

export interface DepositLogModalProps {
  previousData: {
    data: DepositOnLPModalData
    id: string
    recipeLogs: RecipeExecutionLog[]
    recipeDetails: RecipeDetails
  }
}

const DepositModalLog = (props: DepositLogModalProps) => {
  const { previousData } = props
  const recipeLogs = previousData.recipeLogs
  const nodeID = previousData.id
  const pair = previousData?.data.pair
  const recipeDetails = previousData?.recipeDetails
  const myPoolIdWithTimestamp: string = pair.id
  const myPoolId = myPoolIdWithTimestamp.split('-')[0]
  const emptyNodeExecutionResult = []
  const ACTIVE_NODE: number = 2
  const NO_EXECUTED_NODE: number = 0
  let executionSteps: number
  let isExecutionStarted: boolean = false
  let logEvents: NodeExecutionResult[] = emptyNodeExecutionResult
  let showingDate: string
  let depositRowLog: any
  let blockNumberAtDeposit: number = 0
  let blockNumberAtOtherMoment: number = 0

  const graphLastBlock: number = useLastSyncGraphBlock()

  if (recipeLogs !== null) {
    isExecutionStarted = findNodeInActiveRecipe(recipeLogs, nodeID)
    executionSteps = getNumberOfLogsForDepositAndFarms(recipeLogs, nodeID)
    recipeLogs.forEach((log) => {
      logEvents = logEvents.concat(log.events.filter((event) => event.nodeID === nodeID))
    })
    depositRowLog = getCurrentRowLog(recipeLogs, nodeID)
    showingDate = generateShowingTime(depositRowLog)
  }
  const endingLog = getEndingLogForFarmsDeposit(nodeID, recipeLogs)
  blockNumberAtDeposit = useDepositBlockNumberFromTxHash(depositRowLog?.trans)
  blockNumberAtOtherMoment = useOtherMomentBlockNumberFromTxHash(depositRowLog?.trans, endingLog?.trans, executionSteps)
  if (executionSteps === ACTIVE_NODE || executionSteps === NO_EXECUTED_NODE) blockNumberAtOtherMoment = graphLastBlock

  const blockToUse: number = blockNumberAtOtherMoment > graphLastBlock ? graphLastBlock : blockNumberAtOtherMoment
  const pairInfo: any = usePairDayDataByBlockNumber(myPoolId, blockToUse)
  const myPoolData = pairInfo.data.pairDayDatas[0]
  const splitDecimals: string[] = myPoolData.reserveUSD.split('.')
  const liquidity: string = `${splitDecimals[0]}.${splitDecimals[1].slice(0, 2)}`
  const feeApr: number = calculateFeeApr(myPoolData.dailyVolumeUSD, liquidity)

  return (
    <div className="swap-modal modal-content">

      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button>
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="deposit-lp-modal.title" /></h1>
          <NodeStateAndDate isNodeExecuted={isExecutionStarted} showingDate={showingDate} recipeDetails={recipeDetails} numberOfLogs={executionSteps} />
        </header>

        <div className="deposit-modal-log-wrapper">
          <div className="main-div">
            <div className="deposit-chosen-div">
              <div className="div-tokens">
                <span className="div-token-images">
                  <img className="logo img-token-deposit" src={getTokenImage(pair?.token0)} />
                  <img className="logo img-token-deposit" src={getTokenImage(pair?.token1)} />
                </span>
                <span className="span-token-deposit">{pair?.token0} - {pair?.token1}</span>
              </div>
              <div className="div-link-ftmscan" >
                <a className="link-ftmscan"
                  href={`${ENVIRONMENT.blockExplorerUrls}/address/${myPoolIdWithTimestamp.split('-')[0]}`}
                  target="_blank"
                  rel="noreferrer noopener">
                  <FormattedMessage id="farm-modal.view-contract" /> <strong>ftmscan.com</strong></a>
              </div>
            </div>
            <div>
              <ModalErrorBoundary>
                <InfoDepositModalLog
                  logEvents={logEvents}
                  myPoolId={myPoolIdWithTimestamp}
                  blockNumberAtDeposit={blockNumberAtDeposit}
                  blockNumberAtOtherMoment={blockNumberAtOtherMoment}
                  graphLastBlock={graphLastBlock}
                  executionSteps={executionSteps}
                  recipeDetails={recipeDetails}
                  poolAPR={feeApr.toFixed(2)}
                  poolLiquidity={liquidity}
                />
              </ModalErrorBoundary>
            </div>
          </div>
          <div className="div-provider">
            <span className="span-title-provider"><FormattedMessage id="farm-modal-log.provider" /> </span>
            <span className="span-provider">{pair?.provider}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

const DepositModalLogWrapper = (props: DepositLogModalProps) => {
  const suspenseModal = <Suspense fallback={<LoadingDepositModalLog {...props} />}>
    <DepositModalLog {...props} />
  </Suspense>
  return suspenseModal
}
registerModal("deposit-modal-log", DepositModalLogWrapper)
