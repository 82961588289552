import { useModal } from "@gluedigital/modal";
import { useState, useRef, useCallback, DragEventHandler } from "react";
import { createDataForNodeBeforeAdding, addDroppedNode, createFollowingCombo } from "./nodeHelpers";
import { ComboTriggerElement, EdgeElement, NodeElement, NodeModalData, NodeType } from "./types";
import { v4 as uuidv4 } from 'uuid';
import { Connection, NodeChange, ReactFlowInstance } from "react-flow-renderer";
import { createArrowToFarmCombo } from "./arrowsHelpers";
import { useNodesDispatch } from "./nodesAndEdgesContext";

const getNodeId = () => `node_${uuidv4().replace(/-/g, '')}_${+new Date()}`;

const DEFAULT_POSITION: [number, number] = [0, 0];

export const useDiagram = (nodes: NodeElement[], edges: EdgeElement[]) => {
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance<any, any>>();
  const zoom = nodes?.length > 0 ? 0 : undefined
  const position = nodes?.length > 0 ? DEFAULT_POSITION : undefined

  const modal = useModal();
  const nodesDispatch = useNodesDispatch()

  // Add node flow
  const onDrop: DragEventHandler<HTMLDivElement> = (event: React.DragEvent<HTMLDivElement>) => {
    addDroppedNode(event, modal, addNode);
  };
  const addNode = (event: React.DragEvent<HTMLDivElement>, type: NodeType, extraData: NodeModalData) => {
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    const nodeData = createDataForNodeBeforeAdding(type);
    const id = getNodeId()
    const newNode: NodeElement = {
      id,
      type,
      position,
      data: { ...nodeData, ...extraData },
    };

    nodesDispatch({ type: 'addNode', node: newNode })
    if (type === "depositOnLPNode" || type === "farmNode" || type === "nestedStrategiesNode") {
      const nextCombo: ComboTriggerElement = createFollowingCombo(newNode)
      const newArrow: EdgeElement = createArrowToFarmCombo(newNode, nextCombo.id)
      newNode.data.nextConnected = true
      nodesDispatch({ type: 'addNode', node: nextCombo })
      nodesDispatch({ type: 'addEdge', edge: newArrow })
    }
  };

  const onConnect = (connection: Connection) => {
    nodesDispatch({ type: 'connectNodes', connection })
  };

  const onInit = (_reactFlowInstance: ReactFlowInstance) => {
    setReactFlowInstance(_reactFlowInstance);
    setTimeout(() => _reactFlowInstance.fitView(), 0);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onNodesChange = (changes: NodeChange[]) => {
    changes.forEach((change) => {
      if (change.type === "position" && change.dragging) {
        nodesDispatch({ type: 'moveNode', nodeID: change.id, newPosition: change.position })
      }
    })
  }
  const onEdgesChange = useCallback((changes) => {
    console.log('Edge changes: ', changes)
  }, [])

  return {
    reactFlowInstance,
    reactFlowWrapper,
    onConnect,
    onInit,
    onDragOver,
    onDrop,
    zoom,
    position,
    nodes,
    edges,
    onNodesChange,
    onEdgesChange
  };
};
