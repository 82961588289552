import { Middleware } from 'redux'

const localeToLocalStorage: Middleware = ({ getState }) => {
  return (next) => (action) => {
    const ret = next(action)
    if (action.type === '@@intl/UPDATE') {
      const newLang = getState().intl.locale
      localStorage.setItem('lang', newLang)
    }
    return ret
  }
}

const middlewares = [];

declare var __CLIENT__: boolean;

if (__CLIENT__) {
  middlewares.push(require("./middlewares/sessionStorage").default);
  middlewares.push(localeToLocalStorage);
}

export default middlewares;
