
import contracts from 'src/contracts'
import usePromise from "./usePromise"

const getLpToken = async (poolId: number, version: number): Promise<string> => {
    if (version === 2) {
        const masterchefSpookyV2 = await contracts.masterchefSpookyV2
        return masterchefSpookyV2.methods.lpToken(poolId).call()
    }
    const masterchefSpookyV3 = await contracts.masterChefSpookyV3
    return masterchefSpookyV3.methods.lpToken(poolId).call()
}

export const useLpToken = (poolId: number, version: number = 2): string => {
    return usePromise(getLpToken, poolId, version)
}

const checkIfLpIsInMCV2 = async (lpAddress: string): Promise<boolean> => {
    const masterchefSpookyV2 = await contracts.masterchefSpookyV2
    return masterchefSpookyV2.methods.isLpToken(lpAddress).call()
}

export const useIsLpInMCV2 = (lpAddress: string): boolean => {
    return usePromise(checkIfLpIsInMCV2, lpAddress)
}

const checkIfBooReward = async (farmId: number): Promise<string> => {
    const masterchefSpookyV2 = await contracts.masterchefSpookyV2
    return masterchefSpookyV2.methods.rewarder(farmId).call()
}

export const useIsBooReward = (farmId: number): string => {
    const rewardTokenAddress = usePromise(checkIfBooReward, farmId)
    return rewardTokenAddress
}

const getTotallAllocPoint = async (): Promise<number> => {
    const masterchefSpookyV2 = await contracts.masterchefSpookyV2
    return masterchefSpookyV2.methods.totalAllocPoint().call()
}

export const useTotalAllocPoint = (): number => {
    return usePromise(getTotallAllocPoint)
}

const getFarmAllocPoint = async (farmId: number, version: number): Promise<number> => {
    if (farmId !== undefined) {
        const masterchefSpooky = version === 2 ? await contracts.masterchefSpookyV2 : await contracts.complexRewarderV2
        const poolInfo = await masterchefSpooky.methods.poolInfo([farmId]).call()
        return poolInfo.allocPoint
    }
    return undefined
}

export const useFarmAllocPoint = (farmId: number, version: number = 2): number => {
    return usePromise(getFarmAllocPoint, farmId, version)
}

const getBooPerSecond = async (version: number): Promise<number> => {
    if (version === 2) {
        const masterchefSpookyV2 = await contracts.masterchefSpookyV2
        return masterchefSpookyV2.methods.booPerSecond().call()
    }
    const complexRewarderV2 = await contracts.complexRewarderV2
    return complexRewarderV2.methods.rewardPerSecond().call()
}

export const useBooPerSecond = (version: number = 2): number => {
    return usePromise(getBooPerSecond, version)
}

const getRewardToken = async (): Promise<string> => {
    const complexRewarderV2 = await contracts.complexRewarderV2
    return complexRewarderV2.methods.rewardToken().call()
}

export const useRewardToken = (): string => {
    return usePromise(getRewardToken)
}
