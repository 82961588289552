/**
 * Syncs the whole store contents with the localStorage.
 * By doing this, we can safely reload without losing any state, and no
 * problems arise from having multiple tabs open, as each has its own storage.
 */
import API from 'src/axiosConfig'
export default ({ dispatch, getState }) => {
  let state = getState()

  return (next) => (action) => {
    if (action.type === '@@_AFTER_RENDER') {
      // After first render, read saved state
      // (We have to wait until render so SSR and first render match)
      const payload = JSON.parse(window.localStorage.getItem('user'))
      if (payload) API.defaults.headers.common = { Authorization: `Bearer ${payload.token}` }
      dispatch({ type: 'loadUserData', payload })
    }
    const ret = next(action)
    const newState = getState()
    if (state.user !== newState.user) {
      window.localStorage.setItem('user', JSON.stringify(newState.user))
      API.defaults.headers.common = { Authorization: `Bearer ${newState.user.token}` }
      state = newState
    }
    return ret
  }
}
