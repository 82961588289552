import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Recipe, RecipeStatus } from "src/types"
import { useHistory } from "react-router-dom"
import { useMyRecipesRefresh, useRecipeCRUD } from "src/api/recipes"
import { useModal } from "@gluedigital/modal"
import { useToast } from "src/hooks/useToast"

import './RecipeItem.sass'
import fantomToken from 'src/static/images/fantomToken.png'
import { useAbort } from "src/hooks/useAbort"
import RecipeItemPerformance from "./RecipeItemPerformance"
import { formatRecipeTitle } from "src/routes/routesHelper"

interface RecipeItemProps {
  recipe: Recipe
  type: string
  status: RecipeStatus
}

const RecipeItem = ({ recipe, type, status }: RecipeItemProps) => {
  const [showBack, setShowBack] = useState(false)
  const recipeCRUD = useRecipeCRUD()
  const history = useHistory()
  const refresh = useMyRecipesRefresh()
  const modal = useModal()
  const sendToast = useToast()
  const handleFork = e => {
    e.stopPropagation()
    recipeCRUD.fork(recipe.id)
      .then(async () => {
        history.push('/dashboard/your')
        await refresh()
        sendToast(recipe.title, 'fork')
      }).catch(err => console.error(err.message))
  }

  const handleRemove = e => {
    e.stopPropagation()
    const confirmHandler = async () => await recipeCRUD.delete(recipe.id)
      .then(async () => {
        await refresh('draft,ready')
        sendToast(recipe.title, 'delete')
        modal.hide('alert-modal')
      }).catch(err => console.error(err.message))
    const cancelHandler = () => modal.hide('alert-modal')
    modal.show('alert-modal', { bodyTextID: 'recipe-item.delete-confirm', confirmHandler, cancelHandler })
  }
  const [canAbort, setCanAbort] = useState<boolean>(false)

  useEffect(() => {
    if (recipe.step) {
      const remainingInputs = recipe.step.map((node) => node.params.input)
      setCanAbort(remainingInputs.length > 0)
    }
  }, [recipe.step])

  const abort = useAbort(recipe)
  const handleAbort = async (e) => {
    e.stopPropagation()
    setCanAbort(false)
    await abort().then(() => setCanAbort(true))
  }
  const handleRetry = async (e) => {
    e.stopPropagation()
    modal.show("retry-approval-modal", { recipeID: recipe.id })
  }

  const handleAPRDetails = (e) => {
    e.stopPropagation()
    history.push("/recipe/apr-details/" + recipe.id)
  }

  const viewRecipeHandler = () => {
    const adaptTitle: string = formatRecipeTitle(recipe.title)
    history.push("/recipe/" + recipe.id + '/' + adaptTitle)
  }

  return (
    <article
      className={`recipe-item ${showBack ? 'back' : 'front'}`}
      onClick={() => setShowBack(!showBack)}
    >
      <div className="content">
        <img src={fantomToken} alt="coin illustration" />
        <section className="recipe-item-front">
          <div className="inner">
            <header>
              <span className={`privacy ${recipe?.public ? 'public' : 'private'}`}>{recipe?.public ? 'Public' : 'Private'}</span>
              {status === 'finished' && <div className="liquidated-tag">
                <span className="icon-liquidated" />
                <span><FormattedMessage id="recipe-item.finished-tag.liquidated" /></span>
              </div>}
              {status === 'failed' && <div className="liquidated-tag">
                <span className="icon-aborted" />
                <span><FormattedMessage id="recipe-item.finished-tag.aborted" /></span>
              </div>}
            </header>
            <h1 title={recipe.title}>
              {recipe.title.length > 53 ? `${recipe.title.slice(0, 50)}...` : recipe.title}
            </h1>
            <h4 title={recipe.short}>{recipe.short}</h4>
            {(status === 'active' || status === 'finished') && <RecipeItemPerformance recipeID={recipe.id} />}
          </div>
        </section>
        <section className="recipe-item-back">
          {(type !== "public" && status !== 'active' && status !== 'failed') &&
            <button className="recipe-item-delete" onClick={handleRemove}>
              <FormattedMessage id="recipe-item.delete-recipe" />
            </button>}
          <div className="inner">
            <button onClick={viewRecipeHandler}>
              <FormattedMessage id="recipe-item.view-recipe" />
            </button>
            {status === 'failed' && <button onClick={handleRetry as (e: any) => void}>
              <FormattedMessage id="recipe-item.retry" />
            </button>}
            <button onClick={handleFork}>
              <FormattedMessage id="recipe-item.clone-recipe" />
            </button>
            {status === 'active' && <>
              {/* <button disabled={!canAbort} onClick={handleAbort as (e: any) => void}>
                <FormattedMessage id="recipe-item.abort" />
              </button> */}
              <button disabled={!canAbort} onClick={handleAPRDetails}>
                <FormattedMessage id="recipe-item.apr" />
              </button>
            </>}
            {status === 'failed' && <button disabled={!canAbort} onClick={handleAbort as (e: any) => void}>
              <FormattedMessage id="recipe-item.cancel" />
            </button>}
            {status === 'finished' &&
              <button
                onClick={handleAPRDetails}>
                <FormattedMessage id="recipe-item.apr" />
              </button>}

          </div>
        </section>
      </div>
    </article>
  )
}

export default RecipeItem
