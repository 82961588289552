import React from "react"
import { FormattedMessage } from "react-intl"
import { FarmData, PairDayData, TokenInfoSpooky } from "src/api/farms/types"
import { useAllFarmsIdFromApi } from "src/api/recipes"
import { useEndBooAmountFromWithdrawEvent, useLpEarned } from "src/hooks/useFarmsLogs"
import { useGetTokenPriceUsd } from "src/hooks/useTokensLogsFromGraph"

interface EarnedV3FarmModalLogProps {
  previousData: any
  executionSteps: number
  lpUSDPriceAtOtherMoment: number
  masterchefInfo: PairDayData
  blockNumberAtOtherMoment: number
  lpUSDPriceAtDeposit: number
}
export const EarnedV3FarmModalLog = ({ previousData, executionSteps, lpUSDPriceAtOtherMoment, masterchefInfo, blockNumberAtOtherMoment, lpUSDPriceAtDeposit }: EarnedV3FarmModalLogProps) => {
  const farmAddress: string = masterchefInfo.pairAddress
  const DECIMAL_USD_ROUNDED: number = 3
  const version: number = masterchefInfo.poolIdV3 || masterchefInfo.poolIdV3 === 0 ? 3 : 2
  const farmData: FarmData = useLpEarned(previousData.id, previousData.date, executionSteps) || { state: 'Pending', lpDeposited: 'No Info', earned: 'No Info' }
  const totalLpAmount: number = Number(farmData.lpDeposited.slice(0, -2)) + Number(farmData.earned.slice(0, -2))
  const lpDifference: number = totalLpAmount - Number(farmData.lpDeposited.slice(0, -2))

  const allFarmLPs: string[] = useAllFarmsIdFromApi(version)
  const rewardTokenData: TokenInfoSpooky = useGetTokenPriceUsd(masterchefInfo.tokenReward, blockNumberAtOtherMoment)
  const amountRewardWei: number = useEndBooAmountFromWithdrawEvent(farmAddress, allFarmLPs, version)
  const amountRewardEther: number = amountRewardWei / 10 ** 18

  const totalLPUSDValueAtOtherMoment: number = lpUSDPriceAtOtherMoment * totalLpAmount
  const lpUSDValueAtDeposit: number = Number(farmData.lpDeposited.slice(0, -2)) * lpUSDPriceAtDeposit
  const totalUSDValue: number = totalLPUSDValueAtOtherMoment + amountRewardEther * parseFloat(rewardTokenData.priceUSD)
  const differenceEarnedValue: number = totalUSDValue - lpUSDValueAtDeposit

  return (
    <div id="farm-log-field">
      <h3><FormattedMessage id="farm-modal-log.earned" /></h3>
      <strong className="span-coin">$ {(differenceEarnedValue).toFixed(DECIMAL_USD_ROUNDED)} </strong>
      <div className="div-2column-lps">
        <span className="lp-amount-row first-column">{lpDifference.toFixed(12)} <strong>LPs</strong></span>
      </div>
    </div>
  )
}
