import React from "react";
import { FormattedMessage } from "react-intl"
import { CloseModal } from "@gluedigital/modal"
import { ModalErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary";
import { ENVIRONMENT } from "src/constants";
import { getTokenImage } from "src/api/farms/helpers";
import Loading from "src/components/common/Loading/Loading";
import { DepositLogModalProps } from "./DepositModalLog";

import "./DepositModalLog.sass"

export const LoadingDepositModalLog = (props: DepositLogModalProps) => {
  const { previousData } = props
  const pair = previousData?.data?.pair
  const myPoolId: string = pair.id
  return (
    <div className="swap-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button>
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="deposit-lp-modal.title" /></h1>
          {/* <NodeStateAndDate isNodeExecuted={isExecutionStarted} showingDate={showingDate} recipeDetails={recipeDetails} numberOfLogs={executionSteps} /> */}
        </header>
        <div className="deposit-modal-log-wrapper">
          <div className="main-div">
            <div className="deposit-chosen-div">
              <div className="div-tokens">
                <span className="div-token-images">
                  <img className="logo img-token-deposit" src={getTokenImage(pair?.token0)} />
                  <img className="logo img-token-deposit" src={getTokenImage(pair?.token1)} />
                </span>
                <span className="span-token-deposit">{pair?.token0} - {pair?.token1}</span>
              </div>
              <div className="div-link-ftmscan" >
                <a className="link-ftmscan"
                  href={`${ENVIRONMENT.blockExplorerUrls}/address/${myPoolId.split('-')[0]}`}
                  target="_blank"
                  rel="noreferrer noopener">
                  <FormattedMessage id="farm-modal.view-contract" /> <strong>ftmscan.com</strong></a>
              </div>
            </div>
            <div>
            <ModalErrorBoundary>
                <section className="deposit-modal-currency">
                  <Loading />
                  <Loading />
                  <Loading />
                  <Loading />
                </section>
              </ModalErrorBoundary>
            </div>
          </div>
          <div className="div-provider">
            <span className="span-title-provider"><FormattedMessage id="farm-modal-log.provider" /> </span>
            <span className="span-provider">{pair?.provider}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
