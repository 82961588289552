import AddFundsNode from "../../../components/Diagram/nodes/AddFundsNode";
import DepositOnLPNode from "../../../components/Diagram/nodes/DepositOnLPNode";
import ComboTriggerNode from "../../../components/Diagram/nodes/ComboTriggerNode";
import SendToWalletNode from "../../../components/Diagram/nodes/SendToWalletNode";
import StakeLPNode from "../../../components/Diagram/nodes/StakeLPNode";
import LiquidateNode from "../../../components/Diagram/nodes/LiquidateNode";
import SplitNode from "../../../components/Diagram/nodes/SplitNode";
import SwapNode from "../../../components/Diagram/nodes/SwapNode";
import CustomEdge from "src/components/Diagram/CustomEdge/CustomEdge";
import FarmNode from "src/components/Diagram/nodes/FarmNode";
import NestedStrategiesNode from "src/components/Diagram/nodes/NestedStrategiesNode";
import { Edge, Node } from "react-flow-renderer";
import { YearnYDaemonVaultsData } from "src/api/yearnVaults/yearnTypes";

// Nodes data
export interface BaseNodeData {
  onRemoveElementById: (id: string) => void
  onEditElementByID: (id: string, data: any) => void
  nextConnected: boolean
  contracts?: ContractsData
}

export interface ContractsData {
  batch: string
  nodes: string
  vault?: string
  strategy?: string
}

export interface StarterNode extends BaseNodeData {
  outputCoin: string
  amount: string
}
export interface NonTerminalNode extends BaseNodeData {
  inputCoin?: string // Defined on connection
  outputCoin: string // Defined on creation
}
export interface TerminalNode extends BaseNodeData {
  inputCoin?: string // Defined on connection
  nextConnected: true
}

export interface AddFundsNodeData extends StarterNode { }
export type AddFundsModalData = Pick<AddFundsNodeData, 'amount' | 'outputCoin'>

interface TokenTriggerData {
  conditionProvider?: ConditionProvider.chainlink
  condition?: TokenCondition
  contracts?: ContractsData
}

interface TokenTriggerNodeData extends NonTerminalNode, TokenTriggerData { }

interface UserTriggerData {
  conditionProvider?: ConditionProvider.user
  condition?: UserCondition
  contracts?: ContractsData
}

interface UserTriggerNodeData extends NonTerminalNode, UserTriggerData { }

interface APRTriggerData {
  conditionProvider?: ConditionProvider.spookyswap
  condition?: APRCondition
  contracts?: ContractsData
}

interface APRTriggerNodeData extends NonTerminalNode, APRTriggerData { }

interface TimeTriggerData {
  conditionProvider?: ConditionProvider.time
  condition?: TimeCondition
  contracts?: ContractsData
}
interface TimeTriggerNodeData extends NonTerminalNode, TimeTriggerData { }

interface SentimentTriggerData {
  conditionProvider: ConditionProvider.sensei
  condition?: SentimentCondition
  contracts?: ContractsData
}
interface SentimentTriggerNodeData extends NonTerminalNode, SentimentTriggerData { }

interface ProfitTriggerData {
  conditionProvider?: ConditionProvider.tortle
  condition?: ProfitCondition
  contracts?: ContractsData
}

interface ProfitTriggerNodeData extends NonTerminalNode, ProfitTriggerData { }

export type ComboTriggerNodeData = TokenTriggerNodeData | UserTriggerNodeData | APRTriggerNodeData | TimeTriggerNodeData | SentimentTriggerNodeData | ProfitTriggerNodeData

export type ComboTriggerModalData = TokenTriggerData | UserTriggerData | APRTriggerData | TimeTriggerData | SentimentTriggerData | ProfitTriggerData

export interface DepositOnLPNodeData extends NonTerminalNode {
  pair: LPPair
}
export type DepositOnLPModalData = Pick<DepositOnLPNodeData, 'pair'>

export interface FarmNodeData extends NonTerminalNode {
  inputAmount: string
  outputMain: string
  outputReward?: string
  pair: LPPair
  mode: FarmMode
  period?: FarmPeriod
  expectedAPR: number
}
export type FarmModalData = Pick<FarmNodeData, 'pair' | 'mode' | 'period' | 'expectedAPR'>

export interface LiquidateNodeData extends TerminalNode, LiquidateModalData {
  outputCoin: string
}
export type LiquidateModalData = Pick<LiquidateNodeData, "outputCoin" | "inputCoin">

export interface SendToWalletNodeData extends TerminalNode { }
export interface SendToWalletModalData extends SendToWalletNodeData {
  address: string
}
export interface SplitNodeData extends NonTerminalNode {
  firstCoin?: string
  secondCoin?: string
  firstPercentage: number
  secondPercentage: number
  secondNextConnected: boolean
}

export interface NestedStrategiesNodeData extends NonTerminalNode {
  vault?: YearnYDaemonVaultsData
}
export type NestedStrategiesModalData = Pick<NestedStrategiesNodeData, 'vault'>

export type SplitModalData = Pick<SplitNodeData, 'firstCoin' | 'secondCoin' | 'firstPercentage' | 'secondPercentage' | 'inputCoin'>

export interface SwapNodeData extends NonTerminalNode {
  amount?: number
}
export type SwapModalData = Pick<SwapNodeData, "outputCoin" | "inputCoin">

export interface StakeNodeData extends NonTerminalNode {
  coin: "LP"
}

// ===================================================================

// React flow types
export const nodeTypes = {
  addFundsNode: AddFundsNode,
  depositOnLPNode: DepositOnLPNode,
  comboTriggerNode: ComboTriggerNode,
  sendToWalletNode: SendToWalletNode,
  stakeLPNode: StakeLPNode,
  liquidateNode: LiquidateNode,
  splitNode: SplitNode,
  swapNode: SwapNode,
  farmNode: FarmNode,
  nestedStrategiesNode: NestedStrategiesNode
}

export const edgeTypes = {
  myCustomEdge: CustomEdge
}
// ===================================================================

// Element types
export type NodeType = 'addFundsNode' | 'splitNode' | 'swapNode' | 'depositOnLPNode' | 'farmNode' | 'comboTriggerNode' | 'nestedStrategiesNode' | 'sendToWalletNode' | 'liquidateNode'
export type NodeModalData = AddFundsModalData | SplitModalData | SwapModalData | DepositOnLPModalData | FarmModalData | ComboTriggerModalData | LiquidateModalData | NestedStrategiesModalData
export type NodeData = AddFundsNodeData | DepositOnLPNodeData | FarmNodeData | LiquidateNodeData | ComboTriggerNodeData | SplitNodeData | SwapNodeData | SendToWalletNodeData | NestedStrategiesNodeData

export interface EdgeElement extends Edge<any> {
  sourceHandle: string // The handle of the source node
  targetHandle: string // The handle of the target node
  type: 'myCustomEdge'
}

export type NodeElement = AddFundsElement | DepositOnLPElement | FarmElement | LiquidateElement | ComboTriggerElement | SplitElement | SwapElement | SendToWalletElement | NestedStrategiesElement
export type DiagramElement = EdgeElement | AddFundsElement | DepositOnLPElement | FarmElement | LiquidateElement | ComboTriggerElement | SplitElement | SwapElement | SendToWalletElement | NestedStrategiesElement

export interface AddFundsElement extends Node<AddFundsNodeData> {
  type: 'addFundsNode'
}
export interface DepositOnLPElement extends Node<DepositOnLPNodeData> {
  type: 'depositOnLPNode'
}

export interface FarmElement extends Node<FarmNodeData> {
  type: 'farmNode'
}

export interface LiquidateElement extends Node<LiquidateNodeData> {
  type: 'liquidateNode'
}

export interface ComboTriggerElement extends Node<ComboTriggerNodeData> {
  type: 'comboTriggerNode'
}

export interface SplitElement extends Node<SplitNodeData> {
  type: 'splitNode'
}

export interface SwapElement extends Node<SwapNodeData> {
  type: 'swapNode'
}

export interface SendToWalletElement extends Node<SendToWalletNodeData> {
  type: 'sendToWalletNode'
}

export interface NestedStrategiesElement extends Node<NestedStrategiesNodeData> {
  type: 'nestedStrategiesNode'
}
// Util types

export interface LPPair {
  id: string
  token0: string
  token1: string
  provider: PoolProvider
}
export enum PoolProvider {
  spooky = "SpookySwap"
}

export enum ConditionProvider {
  chainlink = 'CHAINLINK',
  user = 'USER',
  spookyswap = 'SPOOKYSWAP',
  time = 'TIME',
  sensei = 'SENSEIZERO',
  tortle = "TORTLE"
}

export type Condition = TokenCondition | UserCondition | APRCondition | TimeCondition

export type ComparisonType = "lower" | "higher"
export interface TokenCondition {
  type?: ComparisonType
  value?: number
  coinToCompare?: string
}

export interface APRCondition {
  farmID?: string
  value?: number
  type?: ComparisonType
}
export interface UserCondition {
  receivedUserInput?: boolean
}

export interface TimeCondition {
  timeComparison?: "period" | "exact"
  value?: number
}

export enum AverageSentiment {
  veryLow = 0,
  low = 1,
  neutral = 2,
  high = 3,
  veryHigh = 4
}
export interface TokenSentiment {
  ticker: string
  sentiment: AverageSentiment
  volume: number
}
export interface SentimentCondition {
  token: string
  volume: number
  sentiment: AverageSentiment
  volumeComparison: ComparisonType
  sentimentComparison: ComparisonType
  composition: 'or' | 'and'
}
export interface ProfitCondition {
  type?: ComparisonType
  value?: number
}

export interface Token {
  id: string
  address: string
}

export interface TokenWithAmount extends Token {
  amount: string
}
export interface TokenForApproval extends Token {
  amount: string
  status: ApprovalStatus
}

export type ApprovalStatus = 'ready' | 'pending' | 'approved' | 'rejected'

export interface NodeToken {
  token: string
  amount: string
}
export interface NodeTokenWithPrice extends NodeToken {
  priceUSD: string
  priceFTM: string
  totalValueUSD: string
}
export interface LPTokenWithPrice extends NodeTokenWithPrice {
  token0: string
  token1: string
}

export type FarmMode = 'autocompound' | 'autoharvest'
export type FarmPeriod = 'daily' | 'weekly' | 'monthly' | 'auto'
