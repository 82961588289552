import React from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch } from 'react-redux'
import { useModal } from '@gluedigital/modal'
import { useHistory } from 'react-router-dom'
import { ENVIRONMENT } from "src/constants"
import { changeChain, checkNetworkAndGetAccount, getLoginObject } from "src/routes/login-helper"
import { login, setUserAccount } from "src/store/actions/user"
import './NoLoginConnectWallet.sass'

declare const ethereum: any

const NoLoginConnectWallet = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const modal = useModal()

  const hasWallet = typeof ethereum !== "undefined"
  const loginMetamask = async () => {
    if (!hasWallet) {
      console.error("MetaMask is not installed!")
      return { result: 'ERROR', message: 'MetaMask is not installed!' }
    } else {
      try {
        const accounts = await ethereum.request({ method: "eth_requestAccounts" })
        if (ethereum.chainId !== ENVIRONMENT.chainId) {
          const res = await changeChain()
          console.log(res)
        }
        const loginObject = await getLoginObject(accounts[0])
        const account = await checkNetworkAndGetAccount()
        await dispatch(login(loginObject))
        dispatch(setUserAccount(account))
        const deviceAccepted: string = localStorage.getItem("deviceAccepted")
        if (deviceAccepted === "true") {
          history.push('/dashboard/your')
          modal.hide()
        } else modal.show("terms-and-conditions-modal")
      } catch (err) {
          alert(err.message)
          modal.hide()
      }
    }
  }

  return (
    <div id="no-login-connect-wallet">
      <img src="/images/logo_white.png" alt="modal liquidate icon" />
      <div className="visible-background">
        <p><FormattedMessage id="text-connect-wallet" /></p>
        <h3><FormattedMessage id="connect-your-wallet" /></h3>
        <button id="no-login-metamask" className="connect-btn" onClick={loginMetamask as () => void}><FormattedMessage id="connect-your-wallet" /></button>
      </div>
    </div>
  )
}

export default NoLoginConnectWallet
