import React from "react"
import { FormattedMessage } from "react-intl"
import { useLPPriceUSD } from "src/hooks/useLPsLogs"
import { useEtherAmountFromAddress } from "src/hooks/useWei&Ether"
import { RecipeDetails } from "src/types"

interface InfoDepositModalLogProps {
  logEvents: any
  myPoolId: string
  blockNumberAtDeposit: number
  blockNumberAtOtherMoment: number
  graphLastBlock: number
  executionSteps: number
  recipeDetails: RecipeDetails
  poolAPR: number | string
  poolLiquidity: string
}

export const InfoDepositModalLog = ({ ...props }: InfoDepositModalLogProps) => {
  const executionSteps: number = props.executionSteps
  const NO_EXECUTED_NODE: number = 0
  const ACTIVE_NODE: number = 2
  const FINISHED_NODE: number = 4
  const ABORTED_NODE: number = 5
  const lpAmountDeposited: string = useEtherAmountFromAddress(props?.logEvents[1]?.output?.token, props?.logEvents[1]?.output?.amount)

  const lpUSDPriceAtDeposit: number = useLPPriceUSD(props.myPoolId, props.blockNumberAtDeposit, props.graphLastBlock)
  const lpUSDPriceAtOtherMoment: number = useLPPriceUSD(props.myPoolId, props.blockNumberAtOtherMoment, props.graphLastBlock)
  const earnedUSDValue: string = (Number(lpAmountDeposited) * lpUSDPriceAtOtherMoment - Number(lpAmountDeposited) * lpUSDPriceAtDeposit).toFixed(3)
  return (
    <section className="deposit-modal-currency">

      <div id="first-slot">
        <h3><FormattedMessage id="farm-modal-log.deposited" /></h3>
        {(executionSteps === ACTIVE_NODE || executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE)
          ?
          <>
            <strong className="span-coin"> $ {(Number(lpAmountDeposited) * lpUSDPriceAtDeposit).toFixed(3)}</strong>
            <span className="span-amount-lp">{lpAmountDeposited.toString()} <strong>LPs</strong></span>
          </>
          : <strong className="span-coin"> No executed</strong>}
      </div>

      {executionSteps !== NO_EXECUTED_NODE ?
        <div id="second-slot">
          {executionSteps === ACTIVE_NODE &&
            <>
              <h3><FormattedMessage id="farm-modal-log.current" /></h3>
              <strong className="span-coin">$ {(Number(lpAmountDeposited) * lpUSDPriceAtOtherMoment).toFixed(3)}</strong>
            </>}
          {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) &&
            <>
              <h3><FormattedMessage id="farm-modal-log.earned" /></h3>
              <strong className="span-coin">$ {earnedUSDValue}</strong>
            </>
          }
          {executionSteps === NO_EXECUTED_NODE &&
            <>
              <h3><FormattedMessage id="farm-modal-log.earned" /></h3>
              <strong className="span-coin">$ --- </strong>
            </>
          }
          <span className="span-amount-lp">{lpAmountDeposited.toString()} <strong>LPs</strong></span>
        </div>
        :
        <div id="second-slot">
          <h3><FormattedMessage id="farm-modal-log.earned" /></h3>
          <strong className="span-coin"> No executed</strong>
        </div>
      }

      <div id="third-slot">
        <h3><FormattedMessage id="farm-modal-log.apr" /></h3>
        <strong className="span-coin">{props.poolAPR || '?'} %</strong>
      </div>

      <div id="fourth-slot">
        <h3><FormattedMessage id="farm-modal-log.liquidity" /></h3>
        <strong className="span-coin">$ {props.poolLiquidity}</strong>
      </div>
    </section>)
}
