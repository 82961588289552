
import { clientYearn } from 'src/components/ApolloClient/index'
import { ALL_VAULTS } from 'src/api/yearnVaults/yearnQueries'
import usePromise from './usePromise'
import { YearnVaultsData, YearnYDaemonVaultsData } from 'src/api/yearnVaults/yearnTypes'
import { filterYearnYDaemonData } from 'src/api/yearnVaults/yearnHelpers'
import { ENVIRONMENT, yearnVaultsYDaemon } from 'src/constants'
import { useFetch } from 'ruse-fetch'

const getYearnVaults = async (): Promise<YearnVaultsData> => {
  const results = await clientYearn.query({
    query: ALL_VAULTS(),
    fetchPolicy: 'cache-first',
  })
  return results.data.vaults
}

export const useFetchYearnVaults = (): YearnVaultsData => {
  const result: YearnVaultsData = usePromise(getYearnVaults)
  return result
}

export const useFetchYearnDaemonAllVaults = (): YearnYDaemonVaultsData[] => {
  const data: any = useFetch(yearnVaultsYDaemon[ENVIRONMENT.chainId])
  const filteredData: YearnYDaemonVaultsData[] = filterYearnYDaemonData(data)
  return filteredData
}

export const useFetchYearnDaemonSingleVault = (vaultAddress: string): YearnYDaemonVaultsData => {
  const data: any = useFetch("https://ydaemon.yearn.finance/250/vaults/" + vaultAddress + "?strategiesDetails=noDetails")
  const filteredData: YearnYDaemonVaultsData[] = filterYearnYDaemonData([data])
  return filteredData[0]
}
