import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useRecipeLogs } from "src/api/recipes"
import { getEtherStringAmountFromAddressFixed15, getInputAmountStringFromAddressDecimals } from "src/contracts/TokensDecimalsHelpers";
import { NodeExecutionResult, RecipeExecutionLog } from "src/types"

const ADDRESS_LENGTH: Number = 42
export const useLogsWithTokenDecimals = (): RecipeExecutionLog[] => {
  const [recipeLogsDecimal, setRecipeLogsDecimals] = useState<RecipeExecutionLog[]>([])

  const { id } = useParams()
  const recipeLogs = useRecipeLogs(id)
  useEffect(() => {
    Promise.all(recipeLogs.map(async (log) => await Promise.all(log.events.map(auxEvents))
      .then((events) => ({ ...log, events }))))
      .then((logs) => setRecipeLogsDecimals(logs))
      .catch(err => console.error(err.message))
  }, [recipeLogs])
  return recipeLogsDecimal
}

export const auxEvents = async (event: NodeExecutionResult): Promise<NodeExecutionResult> => {
  const eventCloned: NodeExecutionResult = { ...event }
  if (event?.output) {
    if (Array.isArray(event.output)) {
      for (let index = 0; index < event.output.length; index++) {
        const out = event.output[index]
        const cb = async () => {
          const newValue = await getEtherStringAmountFromAddressFixed15(out.token, out.amount)
          eventCloned.output[index] = { ...out, amount: newValue.toString() }
        }
        cb().catch(err => console.error(err.message))
      }
    } else {
      if (event.output.token && event.output.token.length === ADDRESS_LENGTH) {
        const newValue = await getEtherStringAmountFromAddressFixed15(event.output?.token, event.output?.amount)
        eventCloned.output = { ...event.output, amount: newValue.toString() }
      }
    }
  }

  if (event?.input) {
    if (event.input.token.length === ADDRESS_LENGTH) {
      const newValue = await getInputAmountStringFromAddressDecimals(event.input?.token, event.input?.amount)
      eventCloned.input = { ...event.input, amount: newValue.toString() }
    }
  }
  return (eventCloned)
}
