import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CurrentFarmInfo } from '../../nodeModals/ComboTriggerModal/APRTriggerInput'
import { useAllFarmsIdFromApi, useTotalAllocPointFromApi } from 'src/api/recipes'
import { useFarmDataByTimestamp } from 'src/hooks/usePairInfoFromGraph'
import { useBooPerSecond, useFarmAllocPoint } from 'src/hooks/useMasterchefV2'
import { calculateFarmApr, calculateFeeApr } from 'src/api/farms/aprHelpers'
import { BOO_ADDRESS, ENVIRONMENT } from 'src/constants'
import Loading from 'src/components/common/Loading/Loading'
import { ComboSpookySwapLogProps } from './ComboSpookySwapLog'
import { obtainUTCTimestampFromDate } from 'src/components/Diagram/nodes/nodesLogsHelper'
import { useTokenUSDPriceByTimestamp } from 'src/hooks/useTokensLogsFromGraph'

const ManualComboSpookySwapLog = ({ data, recipeDetails, currentRowLog }: ComboSpookySwapLogProps) => {
  const executionTimestamp: number = obtainUTCTimestampFromDate(currentRowLog?.date)
  const myFarmAddress: string = data?.condition?.farmID
  const allFarmLPs: string[] = useAllFarmsIdFromApi(2)
  const poolId: number = allFarmLPs.indexOf(myFarmAddress)
  const pairInfo: any = useFarmDataByTimestamp(executionTimestamp, myFarmAddress)
  let myFarm = pairInfo?.data?.pairDayDatas[0]
  const splitDecimals = myFarm?.reserveUSD.split('.')
  const liquidity = `${splitDecimals[0]}.${splitDecimals[1].slice(0, 2)}`
  const booPerSecond: number = useBooPerSecond()
  const allocPoint: number = useFarmAllocPoint(poolId)
  const totalAllocPoint: number = useTotalAllocPointFromApi(2)
  const booPriceData = useTokenUSDPriceByTimestamp(BOO_ADDRESS[ENVIRONMENT.chainId], executionTimestamp)
  const rewardTokenPrice: number = booPriceData.data.tokenDayDatas[0].priceUSD
  const aprFees: number = calculateFeeApr(myFarm.dailyVolumeUSD, liquidity)
  const aprFarm: number = calculateFarmApr(booPerSecond, allocPoint, totalAllocPoint, rewardTokenPrice, liquidity)
  myFarm = { ...myFarm, aprFarm, aprFees }
  const currentFarmInfo: CurrentFarmInfo = {
    label: `${myFarm?.token0?.symbol} - ${myFarm?.token1?.symbol}`,
    apr: (myFarm?.aprFarm + myFarm?.aprFees).toFixed(2)
  }

  if (myFarm === undefined) {
    return (
      <div className="combo-trigger-modal modal-content">
        <div className="modal-wrapper">
          <Loading />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="combo-trigger-grid">
        <section>
          <h2><FormattedMessage id="combo-trigger-modal.if" /></h2>
          <span className="span-info">{data.conditionProvider}</span>
        </section>
        <section>
          <h2><FormattedMessage id="combo-trigger-modal.says" /></h2>
          <span className="span-info">{currentFarmInfo?.label}</span>
        </section>
      </div>
      <section>
        <h2><FormattedMessage id="combo-trigger-modal.is" /></h2>
        <span className="span-info">
          <FormattedMessage id={`combo-trigger-modal.${data.condition.type}`} />
          <FormattedMessage id="combo-trigger-modal.than" /> {data.condition.value} %
        </span>
        <div className="div-current">
          {(recipeDetails?.status === "active" || recipeDetails?.status === "finished") &&
            <>
              <span className="span-current">
                <FormattedMessage id="combo-trigger-modal.current-apr" />{' '}
              </span>
              <span className="span-coin-price">
                {currentFarmInfo?.label} {currentFarmInfo?.apr} %
              </span>
            </>
          }
        </div>
      </section>
    </>
  )
}

export default ManualComboSpookySwapLog
