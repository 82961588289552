import fetch from 'cross-fetch'
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from "@apollo/client"
import { ENVIRONMENT, fantomBlocksSubGraphUri, masterchefTheGraphUriFromSpookyswap, yearnVaultsSubgraph } from 'src/constants'

const linkSpooky = createHttpLink({
  uri: masterchefTheGraphUriFromSpookyswap[ENVIRONMENT.chainId],
  fetch
})

const linkYearnVaults = createHttpLink({
  uri: yearnVaultsSubgraph[ENVIRONMENT.chainId],
  fetch
})
const blockLink = createHttpLink({
  uri: fantomBlocksSubGraphUri,
  fetch
})

export const blockClient = new ApolloClient({
  link: blockLink,
  cache: new InMemoryCache(),
})

export const clientSpooky = new ApolloClient({
  link: linkSpooky,
  cache: new InMemoryCache()
})

export const clientYearn = new ApolloClient({
  link: linkYearnVaults,
  cache: new InMemoryCache()
})
