import usePromise from "./usePromise"
import { clientSpooky } from 'src/components/ApolloClient'
import { CURRENT_ALL_FARMS_DATA, PAIR_DATA_IN_SPECIFIC_TIMESTAMP } from 'src/api/farms/thegraph/pairsQueries'
import { ApolloQueryResult } from "@apollo/client"
import { useTimestampFromBlockNumber } from "./useWeb3"
// import { PairDayDataResponse } from "src/api/farms/types"

const getPairDataByBlockNumber = async (pairId: string, timestamp: number): Promise<ApolloQueryResult<any>> => {
    const result = await clientSpooky.query({
        query: PAIR_DATA_IN_SPECIFIC_TIMESTAMP(pairId, Number(timestamp)),
        fetchPolicy: 'cache-first',
      })
    return result
}

export const usePairDayDataByBlockNumber = (pairId: string, blockNumber: number): any => {
    const timestamp = useTimestampFromBlockNumber(blockNumber)
    const result = usePromise(getPairDataByBlockNumber, pairId, timestamp)
    return result
}

const getCurrentFarmsData = async (timestamp: number, allFarmsLps: string[] | string): Promise<ApolloQueryResult<any>> => {
    const result = await clientSpooky.query({
        query: CURRENT_ALL_FARMS_DATA(timestamp, allFarmsLps),
        fetchPolicy: 'cache-first',
    })
    return result
}

export const useCurrentFarmsData = (blockNumber: number, allFarmsLps: string[]) => {
    const timestamp = useTimestampFromBlockNumber(blockNumber)
    return usePromise(getCurrentFarmsData, timestamp, allFarmsLps)
}

export const useFarmDataByTimestamp = (timestamp: number, allFarmsLps: string[] | string) => {
        const data = usePromise(getCurrentFarmsData, timestamp, allFarmsLps)
        return data
}
