import React from "react"
import iconDone from 'src/static/images/icon-done.png'
import abortIcon from 'src/static/images/abortIcon.svg'
import gearsIcon from 'src/static/images/gears.svg'

import { FormattedMessage } from "react-intl"
import './NodeStateAndDate.sass'
import { RecipeDetails } from "src/types"

interface NodeStateAndDateProps {
  isNodeExecuted: boolean
  showingDate: string
  recipeDetails: RecipeDetails
  numberOfLogs?: number
}

const NodeStateAndDate = (props: NodeStateAndDateProps) => {
  const { isNodeExecuted, showingDate, recipeDetails, numberOfLogs } = props
  const INPROGRESS_FARMS_DEPOSIT_LOGS_STEPS: number = 2
  const recipeStatus: string = recipeDetails.status

  let stateAndDate: JSX.Element = <>
    <div className="global-done-state">
      <img className="done-icon" src={iconDone} />
      <span className="done-text"> <FormattedMessage id="view-log-node-done" />!</span>
    </div>
    <div className="done-date">
      <span > {showingDate} </span>
    </div>
  </>

  if (isNodeExecuted) {
    if (recipeStatus === "finished" && numberOfLogs === INPROGRESS_FARMS_DEPOSIT_LOGS_STEPS) {
      stateAndDate =
        <div className="global-no-done-state">
          <img className="aborted-icon" src={abortIcon} />
          <span className="no-done-text"><FormattedMessage id="view-log-node-aborted" /> </span>
        </div>
    } else if (recipeStatus === "active" && numberOfLogs === INPROGRESS_FARMS_DEPOSIT_LOGS_STEPS) {
      stateAndDate =
        <div className="global-no-done-state">
          <img className="progress-icon" src={gearsIcon} />
          <span className="no-done-text"><FormattedMessage id="view-log-node-inprogress" /> </span>
        </div>
    }
  } else {
    if (recipeStatus === "failed") {
      stateAndDate =
        <div className="global-no-done-state">
          <span className="no-done-text"><FormattedMessage id="view-log-node-failed" /> </span>
        </div>
    } else if (recipeStatus === "active") {
      stateAndDate =
        <div className="global-no-done-state">
          <span className="icon icon-clock inqueue-clock" />
          <span className="no-done-text"><FormattedMessage id="view-log-node-inqueue" /> </span>
        </div>
    } else if (recipeStatus === "finished" && numberOfLogs === undefined) {
      stateAndDate =
        <div className="global-no-done-state">
          <img className="aborted-icon" src={abortIcon} />
          <span className="no-done-text"><FormattedMessage id="view-log-node-aborted" /> </span>
        </div>
    }
  }

  return (
    <div id="node-state-date">
      {stateAndDate}
    </div>
  )
}
export default NodeStateAndDate
