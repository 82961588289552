import React, { memo, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { DepositOnLPNodeData } from "src/routes/RecipeDiagram/helpers/types";
import { Handle } from "react-flow-renderer";
import Buttons from "./Buttons";

import "./DepositOnLPNode.sass";
import NodeExecutionTag from "./NodeExecutionTag";
import { obtainRecipeDetails, obtainRecipeLogs } from "src/routes/RecipeDiagram/helpers/obtainRecipeLogs";
import { useCompareAccounts } from "./nodesLogsHelper";
import { useDispatch, useSelector } from "react-redux";
import { useNodeValidator } from "src/hooks/useValidator";
import { focusNode } from "src/store/actions/diagram";

interface DepositOnLPNodeProps {
  data: DepositOnLPNodeData;
  id: string;
}
export default memo(({ id, data }: DepositOnLPNodeProps) => {
  const [showButtons, setShowButtons] = useState(false);

  const focusedNode = useSelector((state: any) => state.diagram.focused)
  const dispatch = useDispatch()

  const positionTarget: any = "left";
  const positionSource: any = "right";

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setShowButtons(false)
      dispatch(focusNode())
    }
  }

  const handleShowButtons = () => {
    dispatch(focusNode(id))
    if (compareAddresses) {
      setShowButtons(true)
    }
  }

  const account = useSelector((s: any) => (s.user ? s.user.account : undefined))
  const userAddress = account?.account.slice(2)
  const diagramisValid = useSelector((s: any) => s.validation.validated)

  const recipeLogs = obtainRecipeLogs()
  const recipeDetails = obtainRecipeDetails()
  const compareAddresses: boolean = useCompareAccounts(userAddress)



  const isValid = useNodeValidator(data, "depositOnLPNode")

  const handleNodeStyle = useMemo(() => {
    if (!compareAddresses) {
      return ''
    } else if (recipeDetails?.status === 'failed') {
      return 'node-handle-failed'
    } else if (diagramisValid || recipeDetails?.status === 'active') {
      return 'handle-valid'
    } else if (data.inputCoin) {
      return 'handle-connected'
    } else {
      return 'node-handle-private'
    }
  }, [compareAddresses, diagramisValid, data.inputCoin])

  return (
    <div className={`node ${focusedNode && focusedNode !== id ? 'node-unfocused' : ''}`} onClick={handleShowButtons} onBlur={handleBlur} tabIndex={0}>
      <NodeExecutionTag nodeID={id} maxExecutionSteps={4} />
      <div className={"deposit-on-lp"} >
        {compareAddresses && !isValid && <span className="icon icon-exclamation" />}
        <Handle style={{ top: 50 }} className={`node-handle ${handleNodeStyle}`} type="target" position={positionTarget} id="deposit-input" />
        <Handle style={{ top: 50 }} className={`node-handle ${handleNodeStyle}`} type="source" position={positionSource} id="deposit-output" />
      </div>
      <div className="node-info">
        <FormattedMessage id="node.depositOnLPNode.short" />  {data.pair.token0} - {data.pair.token1}
      </div>
      {
        showButtons &&
        <Buttons
          data={data} id={id}
          editModalType={"deposit-lp-modal"}
          recipeLogs={recipeLogs}
          recipeDetails={recipeDetails}
          viewDetailsModalType={"deposit-modal-log"}
        />
      }
    </div>
  );
});
