import React from 'react'
import { FormattedMessage } from 'react-intl'

import './ComboSteps.sass'

const ComboSteps = () => {
  return (
    <div id="combo-steps">
      <div className="step step-1">
        <FormattedMessage id="combo-steps.step-1" />
      </div>
      <div className="step step-2">
        <span className="icon icon-angle_right" />
        <FormattedMessage id="combo-steps.step-2" />
      </div>
      <div className="step step-3">
        <span className="icon icon-angle_right" />
        <FormattedMessage id="combo-steps.step-3" />
      </div>
      <div className="step step-4">
        <span className="icon icon-angle_right" />
        <FormattedMessage id="combo-steps.step-4" />
      </div>
    </div>
  )
}

export default ComboSteps
