import React from "react"
// import MarkdownMessage from "@gluedigital/markdown-message"
import { FormattedMessage } from "react-intl"
import { useRecipeCRUD } from "src/api/recipes"
import { registerModal, useModal } from "@gluedigital/modal"
import { Link, useHistory } from 'react-router-dom'

import arrow from 'src/static/images/back-arrow.svg'
import "./RecipeNotWorkingModal.sass"

interface RecipeWorkingModalProps {
  recipeID: number
}

const RecipeNotWorkingModal = (props: RecipeWorkingModalProps) => {
  const { recipeID } = props

  const history = useHistory()
  const modal = useModal()
  const recipeCRUD = useRecipeCRUD()

  const handleViewRecipe = () => {
    history.push(`/recipe/${recipeID}`); modal.hide()
  }

  const handleRetry = async (e) => {
    e.stopPropagation()
    try {
      await recipeCRUD.execute(recipeID)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="recipe-not-working-modal modal-content">
      <div className="not-working-buttons">
        <h1>
          Ops,<br /> <strong>your recipe is not working</strong>
        </h1>
        <button onClick={handleViewRecipe}>
          <FormattedMessage id="recipe-working.go-recipe" />
        </button>
        <button id="retry-button" onClick={() => handleRetry}>
          <FormattedMessage id="recipe-working.retry-recipe" />
        </button>
        <Link onClick={() => modal.hide()} to="/dashboard/failed">
          <img src={arrow} />
          <span><FormattedMessage id="recipe-working.dashboard" /></span>
        </Link>
      </div>
    </div>
  )
}

registerModal('recipe-not-working-modal', RecipeNotWorkingModal)
