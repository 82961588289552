import React from "react";
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import "./NoRecipes.sass"
import fantomToken from 'src/static/images/fantomToken.png'

const NoRecipes = () => {
  return (
    <div id="no-recipes">
      <div className="wrapper-recipes">
        <div className="wrapper">
          <article>
            <img src={fantomToken} alt="fantom Token illustration" />
            <h1>
              <FormattedMessage id="no-recipes.title" />
            </h1>
            <p>
              <FormattedMessage id="no-recipes.text" />
            </p>
            <div className="buttons">
              <Link className="button create-recipe" to="/recipe">
                <FormattedMessage id="create-recipe" />
              </Link>
              <Link className="button browse-recipe" to="./browse">
                <FormattedMessage id="browse-recipe" />
              </Link>
            </div>
          </article>
        </div>
      </div>
    </div>
  )
}

export default NoRecipes
