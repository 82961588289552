import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { RecipeStatus } from "src/types"

interface DiagramButtonsProps {
    status: RecipeStatus
    isRecipeOwner: boolean
    recipeExists: boolean
    isEmpty: boolean
    isValid: boolean
    validateRecipe: () => void
    onSave: () => void
    onRun: () => void
    onAbort: () => Promise<void>
}

const DiagramButtons = (props: DiagramButtonsProps) => {
    const { status, isRecipeOwner, recipeExists, isValid } = props

    const [canAbort, setCanAbort] = useState(true)
    const abortHandler = () => {
        setCanAbort(false)
        void props.onAbort().then(() => {
            setCanAbort(true)
        })
    }
    if (!isRecipeOwner && recipeExists) {
        return <></>
    }

    let buttons: JSX.Element

    switch (status) {
        case "active":
            buttons = (<div className="abort-button-wrapper">
                <div className="circles-div">
                    <span className="running-icon small" />
                    <span className="running-icon medium" />
                    <span className="running-icon large" />
                </div>
                <span><FormattedMessage id="dnd-flow.recipe-running" /></span>
                <button disabled={!canAbort} onClick={abortHandler}><FormattedMessage id="dnd-flow.abort" /></button>
            </div>)
            break
        case "failed":
            buttons = (<>
                <button disabled={!canAbort} onClick={abortHandler}><FormattedMessage id="dnd-flow.cancel" /></button>
                <button className={`button ${isValid ? '' : 'disabled'}`} disabled={!isValid} onClick={props.onRun} >
                    <FormattedMessage id="dnd-flow.retry" />
                </button>
            </>)
            break
        case "draft":
        case "ready":
            buttons = (<>
                <button onClick={props.onSave}>
                    <FormattedMessage id="dnd-flow.update" />
                </button>
                <button onClick={props.validateRecipe}>
                    <FormattedMessage id="dnd-flow.validate" />
                </button>
                <button className={`button ${isValid ? '' : 'disabled'}`} disabled={!isValid} onClick={props.onRun} >
                    <FormattedMessage id="dnd-flow.run" />
                </button>
            </>)
            break
        default:
            break
    }

    if (!recipeExists) {
        buttons = <button disabled={props.isEmpty} onClick={props.onSave}>
            <FormattedMessage id="dnd-flow.add" />
        </button>
    }

    return <footer className="dnd-controls">{buttons}</footer>
}

export default DiagramButtons
