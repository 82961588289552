import React from 'react'
import Loading from 'src/components/common/Loading/Loading'
import { useSelector } from 'react-redux'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import LanguageSelector from 'src/components/Landing/LanguageSelector/LanguageSelector'
import "./DnDFlow.sass"
import "../Diagram.sass"

export const LoadingDnDFlow = () => {
  const account = useSelector((s: any) => (s.user ? s.user.account : undefined))
  return (
    <div id="loading-dndFlow">
      <div className="dnd-layout">
        <header className="dnd-header">
          <section>
            <button className="button-back">
              <span className="icon icon-angle_left" />
              <span><FormattedMessage id="back" /></span>
            </button>
           {account && <h2 className="account-number">{account.account.substr(0, 6) + "..." + account.account.slice(-4)}</h2>}
          </section>
          <section className="balance">
          {account
          ? <h1><FormattedNumber value={account.balance} style="decimal" /> <sup>FTM</sup> </h1>
          : <h1>0.0 <sup>FTM</sup> </h1>
          }
            <LanguageSelector iconRight contracted />
          </section>
        </header>
      </div>
      <div className="dnd-loading">
        <Loading />
      </div>
    </div>
  )
}
