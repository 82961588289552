import React from "react"
import { FormattedMessage } from "react-intl"
import OnlyDesktopVeil from "src/components/OnlyDesktopVeil/OnlyDesktopVeil"
import Loading from "src/components/common/Loading/Loading"
import "src/components/modals/RecipeWorkingModal"
import "src/components/modals/WaitRecipeWorkingModal"
import "src/components/modals/RetryApprovalModal/RetryApprovalModal"
import "src/components/modals/CompleteModal"
import "./RecipeSummary.sass"

const LoadingRecipeSummary = () => {
  return (
    <div id="recipe-summary" className="page">
      <a className="back">
        <span className="icon icon-arrow-right" />
        <span><FormattedMessage id="recipe-summary.back" /></span>
      </a>
      <h1><FormattedMessage id="recipe-summary.title" /></h1>
      <div>
        <Loading />
      </div>
      <OnlyDesktopVeil />
    </div>
  )
}

export default LoadingRecipeSummary
