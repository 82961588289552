import React, { memo, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Handle } from "react-flow-renderer";
import Buttons from "./Buttons";
import NodeExecutionTag from "./NodeExecutionTag";
import { obtainRecipeDetails, obtainRecipeLogs } from "src/routes/RecipeDiagram/helpers/obtainRecipeLogs";
import { useCompareAccounts } from "./nodesLogsHelper";
import { useDispatch, useSelector } from "react-redux";
import { useNodeValidator } from "src/hooks/useValidator";
import { focusNode } from "src/store/actions/diagram";
import { NestedStrategiesNodeData } from "src/routes/RecipeDiagram/helpers/types";

import "./NestedStrategiesNode.sass";

interface NestedStrategiesProps {
  data: NestedStrategiesNodeData
  id: string
}
export default memo(({ id, data }: NestedStrategiesProps) => {
  const [showButtons, setShowButtons] = useState<boolean>(false)
  const recipeDetails = obtainRecipeDetails()

  const focusedNode = useSelector((state: any) => state.diagram.focused)
  const dispatch = useDispatch()

  const positionTarget: any = "left"
  const positionSource: any = "right"

  const account = useSelector((s: any) => (s.user ? s.user.account : undefined))
  const userAddress = account?.account.slice(2)
  const diagramisValid = useSelector((s: any) => s.validation.validated)

  const recipeLogs = obtainRecipeLogs()
  const compareAddresses: boolean = useCompareAccounts(userAddress)

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setShowButtons(false)
      dispatch(focusNode())
    }
  }

  const handleShowButtons = () => {
    dispatch(focusNode(id))
    if (compareAddresses) {
      setShowButtons(true)
    }
  }

  const isValid = useNodeValidator(data, "farmNode")

  const handleNodeStyle = useMemo(() => {
    if (!compareAddresses) {
      return ''
    } else if (recipeDetails?.status === 'failed') {
      return 'node-handle-failed'
    } else if (diagramisValid || recipeDetails?.status === 'active') {
      return 'handle-valid'
    } else if (data.inputCoin) {
      return 'handle-connected'
    } else {
      return 'node-handle-private'
    }
  }, [compareAddresses, diagramisValid, data.inputCoin])

  return (
    <div className={`node ${focusedNode && focusedNode !== id ? 'node-unfocused' : ''}`} onClick={handleShowButtons} onBlur={handleBlur} tabIndex={0}>
      <NodeExecutionTag nodeID={id} maxExecutionSteps={4} />
      <div className={"nested-strategies"} >
        {compareAddresses && !isValid && <span className="icon icon-exclamation" />}
        <Handle style={{ top: 50 }} className={`node-handle ${handleNodeStyle}`} type="target" position={positionTarget} id="nested-strategies-input" />
        <Handle style={{ top: 50 }} className={`node-handle ${handleNodeStyle}`} type="source" position={positionSource} id="nested-strategies-output" />
      </div>
      <div className="node-info">
        <FormattedMessage id="node.nestedStrategiesNode" />
        <span>  {data?.vault?.formated_name}</span>
      </div>
      {
        showButtons &&
        <Buttons
          data={data} id={id}
          editModalType={"nested-strategies-modal"}
          recipeLogs={recipeLogs}
          recipeDetails={recipeDetails}
          viewDetailsModalType={"nested-strategies-modal-log"}
        />
      }
    </div>
  );
});
