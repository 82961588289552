import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useRecipeDetails, useRecipeLogs } from "src/api/recipes"
import { useRecipeLogsLive } from "src/context/SocketContext"
import { getNumberOfLogsForDepositAndFarms } from "./nodesLogsHelper"

interface NodeExecutionTagProps {
  nodeID: string
  maxExecutionSteps?: number
}
enum NodeExecutionStatus {
  failed = 'failed',
  aborted = 'aborted',
  inProgress = 'inprogress',
  inQueue = 'inqueue',
  done = 'done',
}

const NodeExecutionTag = (props: NodeExecutionTagProps) => {
  const { nodeID } = props
  const maxExecutionSteps = props?.maxExecutionSteps
  const { id } = useParams()
  const logs = useRecipeLogs(id)
  const recipeDetails = useRecipeDetails(id);
  const [hasLogs, setHasLogs] = useState(false)
  const [nodeStatus, setNodeStatus] = useState<NodeExecutionStatus>(NodeExecutionStatus.inQueue)
  const [isOwner, setIsOwner] = useState(false)
  const account = useSelector((s: any) => (s.user ? s.user.account : undefined))

  const liveRecipeLogs = useRecipeLogsLive(id)

  useEffect(() => {
    let completedSteps = 0
    if (logs?.length > 0) {
      setHasLogs(logs?.length > 0)
      const logsFound = logs.filter((log) => log.events.some((event) => event.nodeID === nodeID))
      if (logsFound) {
        if (maxExecutionSteps && logsFound?.length > 0) {
          const farmAndDepositNumberOfLogs: number = getNumberOfLogsForDepositAndFarms(logs, nodeID)
          if (farmAndDepositNumberOfLogs === 2) setNodeStatus(NodeExecutionStatus.inProgress)
          else if (farmAndDepositNumberOfLogs === 4) setNodeStatus(NodeExecutionStatus.done)
          else if (farmAndDepositNumberOfLogs === 5) setNodeStatus(NodeExecutionStatus.aborted)
        } else if (logsFound?.length > 0) {
          setNodeStatus(NodeExecutionStatus.done)
        }
      }
      if (liveRecipeLogs?.length > 0) {
        const logsFound = liveRecipeLogs.filter((log) => log.events.find((event) => {
          return event.nodeID === nodeID
        }
        ))
        if (maxExecutionSteps && logsFound?.length > 0) {
          completedSteps += logsFound.reduce((events, currentLog) => {
            return currentLog.events
              .filter((event) => event.nodeID !== nodeID)
              .concat(events)
          }, []).length
          if (completedSteps === 2) setNodeStatus(NodeExecutionStatus.inProgress)
          else if (completedSteps === 4) setNodeStatus(NodeExecutionStatus.done)
          else if (completedSteps === 5) setNodeStatus(NodeExecutionStatus.aborted)
        } else if (logsFound.length > 0) {
          setNodeStatus(NodeExecutionStatus.done)
        }
      }
    }
  }, [logs, liveRecipeLogs, maxExecutionSteps, nodeID])

  useEffect(() => {
    if (logs?.length > 0) {
      if (recipeDetails?.status === "finished") {
            setNodeStatus(s => s === NodeExecutionStatus.done ? s : NodeExecutionStatus.aborted)
      }
    }
  }, [logs, maxExecutionSteps, nodeID, recipeDetails])

  useEffect(() => {
    setIsOwner(account?.account.slice(2) === recipeDetails?.owner)
  }, [account, recipeDetails])

  useEffect(() => {
    if (recipeDetails?.status === 'failed') {
      setNodeStatus(NodeExecutionStatus.failed)
    }
  }, [recipeDetails])

  if ((!hasLogs && recipeDetails?.status !== "failed") || !isOwner) {
    return <></>
  }
  return <span className={`node-upper-tag ${nodeStatus}-tag`}> <FormattedMessage id={`view-log-node-${nodeStatus}`} /></span>
}

export default NodeExecutionTag
