import { YearnVaultsData, YearnYDaemonVaultsData } from "./yearnTypes";
import web3 from "src/utils/web3";

export const addTotalAssetToYearnVaults = (totalAssetsArray: string[], yearnVaultsData: YearnVaultsData[]): YearnVaultsData[] => {
  const endingYearnVaultData: YearnVaultsData[] = yearnVaultsData.map((vault, i) => {
    let assetInEther: string
    let data: YearnVaultsData
    if (vault?.token?.decimals === 18) {
      assetInEther = web3.utils.fromWei(totalAssetsArray[i], "ether")
      data = { ...vault, totalAssets: assetInEther }
    } else if (vault?.token?.decimals !== 18) {
      const numberLength: number = totalAssetsArray[i].length
      const decimals: number = vault?.token?.decimals
      const decimalPart: string = totalAssetsArray[i].slice(-decimals)
      const integerPart: string = totalAssetsArray[i].slice(0, (numberLength - decimals))
      assetInEther = integerPart + '.' + decimalPart
      data = { ...vault, totalAssets: assetInEther }
    }
    return data
  })
  return endingYearnVaultData
}

export const filterYearnYDaemonData = (data: any): YearnYDaemonVaultsData[] => {
  const filteredData: YearnYDaemonVaultsData[] = data.map((vault) => {
    let newTotalAssets: string

    if (vault.decimals === 18) newTotalAssets = web3.utils.fromWei(vault.tvl.total_assets, "ether")
    else if (vault.decimals !== 18) {
      const dataLength: number = vault.tvl.total_assets.length
      const decimalPart: string = vault.tvl.total_assets.slice(-vault.decimals)
      const integerPart: string = vault.tvl.total_assets.slice(0, (dataLength - vault.decimals))
      newTotalAssets = integerPart + '.' + decimalPart
    }
    const newTotalAssetsUSD: string = (Number(newTotalAssets) * vault.tvl.price).toFixed(2)
    const newVault: YearnYDaemonVaultsData = {
      address: vault.address,
      apy: (vault.apy.net_apy * 100),
      decimals: vault.decimals,
      display_name: vault.display_name,
      display_symbol: vault.display_symbol,
      formated_name: vault.formated_name,
      formated_symbol: vault.formated_symbol,
      icon: vault.icon,
      tvl: {
        price: vault.tvl.price,
        total_assets: newTotalAssets,
        total_assets_usd: newTotalAssetsUSD,
        total_delegated_assets: vault.tvl.total_delegated_assets,
        tvl: vault.tvl.tvl,
        tvl_deposited: vault.tvl.tvl_deposited,
        tvl_delegated: vault.tvl.tvl_delegated
      },
      token: {
        name: vault.token.name,
        symbol: vault.token.symbol,
        icon: vault.token.icon,
        address: vault.token.address,
        decimals: vault.token.decimals
      }
    }
    return newVault
  })
  return filteredData
}
