import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { PairDayData } from "src/api/farms/types"
import { CurrentFarmInfo } from "./APRTriggerInput"

interface APRTriggerLabelProps {
  pairs: PairDayData[]
  farmID: string
}
const APRTriggerLabel = ({ farmID, pairs }: APRTriggerLabelProps) => {
  const [currentFarmInfo, setCurrentFarmInfo] = useState<CurrentFarmInfo>(undefined)
  useEffect(() => {
    const targetFarm: PairDayData = pairs.find((pair) => pair.id.split('-')[0] === farmID)
    if (targetFarm) {
      const farmInfo: CurrentFarmInfo = {
        label: `${targetFarm.token0.symbol} - ${targetFarm.token1.symbol}`,
        apr: (targetFarm.aprFarmCurrent + targetFarm.aprFeesCurrent).toFixed(2)
      }
      setCurrentFarmInfo(farmInfo)
    }
  }, [farmID, pairs])
  return (
    <>
      {currentFarmInfo && <span>
        < span className="span-current">
          <FormattedMessage id="combo-trigger-modal.current-apr" />
        </span>
        <span className="span-coin-price">{' '}{currentFarmInfo.label} {currentFarmInfo.apr}%</span>
      </span>}</>

  )
}

export default APRTriggerLabel
