import { format } from "date-fns";
import React, { Suspense } from "react";
import { FormattedMessage } from "react-intl";
import { ComboTriggerNodeData, ConditionProvider } from "src/routes/RecipeDiagram/helpers/types";
import "src/routes/RecipeDiagram/SideBarViewLogs/SidebarViewLogs.sass"
import { ViewRecipeLogsProps } from "../ViewRecipeLogs/ViewRecipeLogs";
import SpookySwapComboExtraDataLog from "./SpookySwapComboExtraDataLog";

export interface ComboExtraDataLogProps extends ViewRecipeLogsProps {
  data: ComboTriggerNodeData
}

const ComboExtraDataLog = (props: ComboExtraDataLogProps) => {
  const { data } = props
  return (
    <div className="div-extra-info">
      {data.conditionProvider === ConditionProvider.chainlink &&
        <> <div className="first-column">
          <p><FormattedMessage id="view-logs-combo-provider" />  </p>
          <p><FormattedMessage id="view-logs-combo-coin-compare" />  </p>
          <p><FormattedMessage id="view-logs-combo-condition" />   </p>
        </div>
          <div className="second-column">
            <p>{data.conditionProvider}</p>
            <p>{data.condition.coinToCompare}</p>
            <p>{data.condition.type} <FormattedMessage id="combo-trigger-modal.than" /> {data.condition.value} USD  </p>
          </div></>}
      {data.conditionProvider === ConditionProvider.user &&
        <div className="first-column">
          <p><FormattedMessage id="view-logs-manual-execution" /></p>
        </div>
      }
      {data.conditionProvider === ConditionProvider.spookyswap && (
        <>
          <Suspense fallback={"Loading ..."}>
            <SpookySwapComboExtraDataLog data={data} />
          </Suspense>
        </>
      )
      }
      {data.conditionProvider === ConditionProvider.tortle &&
        <> <div className="first-column">
          <p><FormattedMessage id="view-logs-combo-provider" />  </p>
          <p><FormattedMessage id="combo-type-profit.profit" />  </p>
          <p><FormattedMessage id="view-logs-combo-condition" />   </p>
        </div>
          <div className="second-column">
            <p>{data.conditionProvider}</p>
            <p></p>
            <p>{data.condition.type} <FormattedMessage id="combo-trigger-modal.than" /> {data.condition.value} %  </p>
          </div></>}

      {data.conditionProvider === ConditionProvider.sensei &&
        <> <div className="first-column">
          <p><FormattedMessage id="view-logs-combo-provider" />  </p>
          <p><FormattedMessage id="view-logs-combo-coin-compare" />  </p>
          <p><FormattedMessage id="view-logs-combo-sentiment.condition" />   </p>
          <p><FormattedMessage id="view-logs-combo-sentiment.type" />   </p>
          <p><FormattedMessage id="view-logs-combo-volume.condition" />   </p>
        </div>
          <div className="second-column">
            <p>{data.conditionProvider}</p>
            <p>{data.condition.token}</p>
            <p>{data.condition.sentimentComparison} <FormattedMessage id="combo-trigger-modal.than" /> {data.condition.sentiment}   </p>
            <p>{data.condition.composition} </p>
            <p>{data.condition.volumeComparison} <FormattedMessage id="combo-trigger-modal.than" /> {data.condition.volume}   </p>
          </div></>}

      {data.conditionProvider === ConditionProvider.time &&
        <> <div className="first-column">
          <p><FormattedMessage id="view-logs-combo-provider" />  </p>
          <p><FormattedMessage id="combo-type-DCA.execution" />  </p>
          <p><FormattedMessage id="combo-type-DCA.time" />   </p>
        </div>
          <div className="second-column">
            <p>{data.conditionProvider}</p>
            <p>{data.condition.timeComparison}</p>
            <p> {format(new Date(data.condition.value), 'dd-MM-yyyy HH:mm')}  </p>
          </div></>}
    </div >
  )
}

export default ComboExtraDataLog
