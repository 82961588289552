export const addresses = {
  masterchefSpookyV1: {
    '0xfa': '0x2b2929E785374c651a81A63878Ab22742656DcDd',
    '0xfa2': '0xa1767059b6843eCD8a6989c795f6457605A4dB04',
  },
  masterchefSpookyV2: {
    '0xfa': '0x18b4f774fdC7BF685daeeF66c2990b1dDd9ea6aD',
    '0xfa2': '0xfc750D03D27766a0592378D49A37eBA8D44BA306',
  },
  complexRewarderV2: {
    '0xfa': '0xDdB816c59200aF55b1Ca20735Ef086626a2C6a8D',
    '0xfa2': '',
  },
  masterchefSpookyV3: {
    '0xfa': '0x9C9C920E51778c4ABF727b8Bb223e78132F00aA4',
    '0xfa2': ''
  },
  multicall: {
    '0xfa': '0xeaa5bc63466ce2359c2a13dce952bd0e88a5bef7',
    '0xfa2': '0x84fa9C99F9f561dF37145b51cA97a84385917035',
  },
  routerSpooky: {
    '0xfa': '0xF491e7B69E4244ad4002BC14e878a34207E38c29',
    '0xfa2': '0x40Be9a4E29A16D089C487ec74D3644F4fCAc3dc0',
  },
  uniswapV2Factory: {
    '0xfa': '0x152eE697f2E276fA89E96742e9bB9aB1F2E61bE3',
    '0xfa2': '0xEE4bC42157cf65291Ba2FE839AE127e3Cc76f741',
  }
}
