import React, { Suspense, useEffect } from "react"
import { Route, useHistory } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { useModal } from "@gluedigital/modal"
import { useAllRecipeDetailsLive } from "src/context/SocketContext"
import { useToast } from "src/hooks/useToast"
import SideMenu from "src/components/SideMenu/SideMenu"
import OnlyDesktopVeil from "src/components/OnlyDesktopVeil/OnlyDesktopVeil"
import AccountData from "src/components/AccountData/AccountData"
import ActiveRecipes from "./ActiveRecipes/ActiveRecipes"
import BrowseRecipes from "./BrowseRecipes/BrowseRecipes"
import LiquidatedRecipes from "./LiquidatedRecipes/LiquidatedRecipes"
import YourRecipes from "./YourRecipes/YourRecipes"
import FailedRecipes from "./FailedRecipes/FailedRecipes"
import ToastList from "src/components/common/RecipeToasts/ToastList"
import Loading from "src/components/common/Loading/Loading"

import "src/components/modals/SelectWalletModal"
import "./Dashboard.sass"

declare const ethereum: any

interface DashboardProps {
  match: any
}

const Dashboard = ({ match }: DashboardProps) => {
  const user = useSelector((s: any) => s.user)
  const ready: boolean = useSelector((s: any) => s.ready)
  const account = user.account
  const history = useHistory()
  const modal = useModal()

  ethereum.on('accountsChanged', function (accounts) {
    if (accounts[0] !== account?.account && account?.account !== undefined) {
      history.push('/')
      modal.show('select-wallet-modal')
    }
  })

  useEffect(() => {
    if (ready && ethereum.selectedAddress !== account?.account && ethereum.selectedAddress != null) {
      history.push('/')
      modal.show('select-wallet-modal')
    }
  }, [ready, history, modal, account?.account])

  const sendToast = useToast()
  const lastRecipeChange = useAllRecipeDetailsLive()

  useEffect(() => {
    if (!lastRecipeChange) {
      return
    }
    const { status, title, step } = lastRecipeChange
    if (status === 'active' && step.length <= 0) {
      sendToast(title, 'execute')
    } else if (status === "finished" || (status === 'active' && step.length > 0)) {
      sendToast(title, 'execute-success')
    } else if (status === "failed") {
      sendToast(title, 'execute-fail')
    }
  }, [lastRecipeChange, sendToast])

  return (
    <div id="dashboard" className="page">
      <header>
        {account &&
          <>
            <ToastList />
            <button className="create-recipe" onClick={() => history.push("/recipe")}>
              <FormattedMessage id="create-recipe" />
            </button>
            <AccountData account={account.account} />
          </>
        }
      </header>
      <aside>
        <Suspense fallback={<Loading />}>
          <SideMenu />
        </Suspense>
      </aside>
      <div className="dashboard-content">
        <Route path={`${match.path}/active`} exact component={ActiveRecipes} />
        <Route path={`${match.path}/browse`} exact component={BrowseRecipes} />
        <Route path={`${match.path}/liquidated`} component={LiquidatedRecipes} exact />
        <Route path={`${match.path}/your`} exact component={YourRecipes} />
        <Route path={`${match.path}/failed`} exact component={FailedRecipes} />
      </div>
      <OnlyDesktopVeil />
    </div>
  )
}

const DashboardWrapper = (props) => (
  <Suspense fallback={<div />}>
    <Dashboard {...props} />
  </Suspense>
)

export default DashboardWrapper
