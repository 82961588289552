
import web3 from "src/utils/web3"
import { ENVIRONMENT } from "src/constants"
import { BN, currentAPR, organisedPoolsInfo } from "src/api/farms/farmHelpers"
import { ethers } from "ethers"
import { EventData } from 'web3-eth-contract/types/index'
import batchAbi from 'src/contracts/abis/batch.json'
import ttVaultAbi from 'src/contracts/abis/TortleVault.json'
import strategyAbi from 'src/contracts/abis/TortleStrategy.json'
import strategyV3Abi from 'src/contracts/abis/TortleStrategyV3.json'
import { FarmData } from "src/api/farms/types"
import usePromise from "./usePromise"
import pairsAPI from "src/api/farms/api"

const getFarmPairs = async (blockNumber = 0, pairId = null) => {
  const spookyData = await pairsAPI.getSpookyData({}, blockNumber, false, pairId)
  const result = organisedPoolsInfo([...spookyData.poolsData])
  result.sort((a, b) => b.aprFarm + b.aprFees - (a.aprFarm + a.aprFees))
  return {
    spookyData: {
      data: spookyData.data,
      updated: new Date(),
    },
    pools: {
      data: result,
      updated: new Date(),
    },
  }
}

export const useFarmPairs = (blockNumber = 0, pairId = null) => {
  const data = usePromise(getFarmPairs, blockNumber, pairId)
  return data
}

const lpEarned = async (id: string, date: Date, executionSteps: number): Promise<FarmData> => {
  if (executionSteps === 0) return undefined
  const data = { id, date }
  const ACTIVE_NODE: number = 2
  const FINISHED_NODE: number = 4
  const ABORTED_NODE: number = 5
  const filter = {
    fromBlock: 0,
    toBlock: 'latest',
  }
  const abiEv = [
    {
      indexed: false,
      internalType: "address",
      name: "user",
      type: "address"
    },
    {
      indexed: false,
      internalType: "uint256",
      name: "amount",
      type: "uint256"
    },
    {
      indexed: false,
      internalType: "uint256",
      name: "total",
      type: "uint256"
    }
  ]
  const batch = new web3.eth.Contract(batchAbi as any, ENVIRONMENT.contracts.batch)
  const eventsTtDeposited: EventData[] = await batch.getPastEvents('ttDeposited', filter)
  const event = (eventsTtDeposited.filter((event) => {
    return (event.returnValues.id === web3.utils.keccak256(data.id))
  }))[0]
  const vaultAddr = event?.returnValues?.ttVault
  const ttShares = event?.returnValues?.amount
  const logsDeposit = (await web3.eth.getTransactionReceipt(event?.transactionHash)).logs
  const logsVaultDeposit = logsDeposit.filter((log) => { return log.address.toLowerCase() === vaultAddr.toLowerCase() })
  const decodedEvDeposit = web3.eth.abi.decodeLog(abiEv, logsVaultDeposit[1].data, logsVaultDeposit[1].topics);
  const lpDeposited = BN(decodedEvDeposit[1])

  if (executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) {
    const eventsTtWithdrawed: EventData[] = await batch.getPastEvents('ttWithdrawed', filter)
    const eventWithdraw = (eventsTtWithdrawed.filter((e) => {
      return e.returnValues.id === web3.utils.keccak256(data.id)
    }))[0]
    const logs = (await web3.eth.getTransactionReceipt(eventWithdraw?.transactionHash)).logs
    const logsVault = logs.filter((log) => { return log.address.toLowerCase() === vaultAddr.toLowerCase() })
    const decodedEvWithdraw = web3.eth.abi.decodeLog(abiEv,
      logsVault[1].data,
      logsVault[1].topics);
    const lpWithdrawed = BN(decodedEvWithdraw[1])
    const _earned = ethers.utils.formatUnits(lpWithdrawed.sub(lpDeposited), 'ether')
    const earned = `${(+_earned).toFixed(12)} lp`
    const lpDepositedEth = ethers.utils.formatUnits(lpDeposited, 'ether')
    const currentApr = currentAPR(+lpDepositedEth, +_earned, data.date).toFixed(2)
    return {
      state: 'finished',
      lpDeposited: `${(+lpDepositedEth).toFixed(12)} lp`,
      earned,
      currentApr
    }
  }
  if (executionSteps === ACTIVE_NODE) {
    const vault = new web3.eth.Contract(ttVaultAbi as any, vaultAddr)
    const ttPrice = await vault.methods.getPricePerFullShare().call()
    const lpNow = BN(ttPrice).mul(BN(ttShares)).div(BN(10 ** 18))
    const _earned = ethers.utils.formatUnits(lpNow.sub(lpDeposited), 'ether')
    const earned = `${(+_earned).toFixed(12)}`
    // if (lpNow.sub(lpDeposited).lte(BN(0))) earned = 'Not enough data'
    const lpDepositedEth = ethers.utils.formatUnits(lpDeposited, 'ether')
    const currentApr = currentAPR(+lpDepositedEth, +_earned, data.date).toFixed(2)
    return {
      state: 'running',
      lpDeposited: `${(+lpDepositedEth).toFixed(12)} lp`,
      earned,
      currentApr
    }
  }
}

export const useLpEarned = (id: string, date: Date, executionSteps: number): FarmData => {
  const farmData = usePromise(lpEarned, id, date, executionSteps)
  return farmData
}

const getLastDepositEvent = async (lpAddress: string, lpAddressesByIndex: string[], version: number): Promise<EventData> => {
  const LIMIT_BLOCK = 47500000
  const filter = {
    fromBlock: LIMIT_BLOCK,
    toBlock: 'latest',
  }
  const vaultsData = version === 2 ? ENVIRONMENT.contracts.vaults : ENVIRONMENT.contracts.vaultsV3
  const batch = new web3.eth.Contract(batchAbi as any, ENVIRONMENT.contracts.batch)
  const eventsTtDeposited: EventData[] = await batch.getPastEvents('ttDeposited', filter)
  const eventsInDesiredFarm: EventData[] = []
  for (let i = 0; i < eventsTtDeposited.length; i++) {
    const vaultAddress: string = (eventsTtDeposited[i].returnValues.ttVault).toLowerCase()
    const vault = vaultsData.find((vault) => (vault.address).toLowerCase() === vaultAddress)
    if (vault !== undefined) {
      const vaultLPAddress: string = lpAddressesByIndex[Number(vault?.poolId)]
      if (vaultLPAddress.toLowerCase() === lpAddress) eventsInDesiredFarm.push(eventsTtDeposited[i])
    }
  }
  if (eventsInDesiredFarm.length > 0) return eventsInDesiredFarm[eventsInDesiredFarm.length - 1]
  return null
}

export const useLastDepositEvent = (lpAddress: string, lpAddressesByIndex: string[], version: number): EventData => {
  const lastEvent: EventData = usePromise(getLastDepositEvent, lpAddress, lpAddressesByIndex, version)
  return lastEvent
}

const getBooAmountFromStrategy = async (vaultStrategy: string, shares: number, version: number): Promise<number> => {
  if (version === 2) {
    const strategy = new web3.eth.Contract(strategyAbi as any, vaultStrategy)
    const booAmount: number = await strategy.methods.getBooPerFarmNode(shares).call()
    return booAmount
  }
  const strategy = new web3.eth.Contract(strategyV3Abi as any, vaultStrategy)
  const booAmount: number = await strategy.methods.getBooPerFarmNode(shares).call()
  return booAmount
}

export const useBooAmountFromStrategySC = (myFarmAddress: string, allFarmsLps: string[], version: number = 2): number => {
  const vaultsData = version === 2 ? ENVIRONMENT.contracts.vaults : ENVIRONMENT.contracts.vaultsV3
  const lastEvent: EventData = useLastDepositEvent(myFarmAddress, allFarmsLps, version)
  const sharesAmount: number = lastEvent?.returnValues?.amount
  const vault = vaultsData.find((vault) => (vault.address).toLowerCase() === (lastEvent?.returnValues?.ttVault).toLowerCase())
  const booAmount: number = usePromise(getBooAmountFromStrategy, vault.strategy, sharesAmount, version)
  return booAmount
}

const getEndBooAmountFromWithdrawEvent = async (lpAddress: string, lpAddressesByIndex: string[], version: number): Promise<number> => {
  const LIMIT_BLOCK = 47500000
  const filter = {
    fromBlock: LIMIT_BLOCK,
    toBlock: 'latest',
  }
  const vaultsData = version === 2 ? ENVIRONMENT.contracts.vaults : ENVIRONMENT.contracts.vaultsV3
  const batch = new web3.eth.Contract(batchAbi as any, ENVIRONMENT.contracts.batch)
  const eventsTtDeposited: EventData[] = await batch.getPastEvents('ttWithdrawed', filter)
  const eventsInDesiredFarm: EventData[] = []
  for (let i = 0; i < eventsTtDeposited.length; i++) {
    const vaultAddress: string = (eventsTtDeposited[i].returnValues.ttVault).toLowerCase()
    const vault = vaultsData.find((vault) => (vault.address).toLowerCase() === vaultAddress)
    if (vault !== undefined) {
      const vaultLPAddress: string = lpAddressesByIndex[Number(vault.poolId)]
      if (vaultLPAddress.toLowerCase() === lpAddress) eventsInDesiredFarm.push(eventsTtDeposited[i])
    }
  }
  if (eventsInDesiredFarm.length > 0) {
    const lastEvent: EventData = eventsInDesiredFarm[eventsInDesiredFarm.length - 1]
    const booAmount: number = lastEvent?.returnValues?.rewardAmount
    return booAmount
  }
  return null
}

export const useEndBooAmountFromWithdrawEvent = (lpAddress: string, lpAddressesByIndex: string[], version: number = 2): number => {
  const booAmount = usePromise(getEndBooAmountFromWithdrawEvent, lpAddress, lpAddressesByIndex, version)
  return booAmount
}
