import React from 'react'
import { registerModal } from '@gluedigital/modal'
import { ArenguForm } from 'react-arengu-forms'
import { Helmet } from 'react-helmet-async'

import './PresaleModal.sass'
import { useSelector } from 'react-redux'

const PresaleModal = () => {
  const currentLang = useSelector((s: any) => s.intl.locale)

  const arenguID = {
    en: '163040424270066730',
    zh: '163473479638279930',
    vi: '163473483865036585'
  }

  return (
    <>
      <Helmet>
        <script async src="https://sdk.arengu.com/forms.js"></script>
      </Helmet>
      <div className="pre-sale-modal modal-content">
        <ArenguForm id={arenguID[currentLang]} />
      </div>
    </>
  )
}

registerModal('pre-sale-modal', PresaleModal)
