import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useRecipePerformance } from "src/api/recipes"
import { RecipeDetails } from 'src/types'
import './APRSideViewDescription.sass'

interface APRSideViewDescriptionProps {
  recipeDetails: RecipeDetails
}
export const APRSideViewDescription = ({ recipeDetails }: APRSideViewDescriptionProps) => {
  const { performance } = useRecipePerformance(recipeDetails?.id)
  const APR: number = performance?.apr
  return (
    <>
      {(recipeDetails?.status === "active" || recipeDetails?.status === "finished")
        && APR && <div className={`apr ${APR > 0 ? '' : 'negative'}`}>
          <span><FormattedMessage id="apr-details.apr-info" /> {(APR * 100).toFixed(2)} %</span>
        </div>}
    </>
  )
}
