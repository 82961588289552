import React from 'react'
import { FormattedMessage } from 'react-intl'

import './ScrollDown.sass'

const ScrollDown = () => {
  return (
    <a href="/#intro" id="scroll-down">
      <FormattedMessage id="scroll-down" />
    </a>
  )
}

export default ScrollDown
