
import { ApolloQueryResult } from "@apollo/client"
import { clientSpooky } from 'src/components/ApolloClient'
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { TOKEN_DERIVED_FTM_BY_BLOCK_NUMBER, FTM_PRICE_BY_BLOCK_NUMBER, TOKEN_PRICE_USD } from '../api/farms/thegraph/tokenQueries'
import usePromise from "./usePromise"
import web3 from "src/utils/web3"
import { TokenInfoSpooky } from "src/api/farms/types"

export const getFTMPriceUSDByBlock = async (blockNumber: number, graphLastBlock: number): Promise<ApolloQueryResult<any>> => {
  const usefulBlockNumber: number = blockNumber > graphLastBlock ? graphLastBlock : blockNumber
  if (usefulBlockNumber !== 0 && usefulBlockNumber !== null && usefulBlockNumber !== undefined) {
    const tokenDataPromise = await clientSpooky.query({
      query: FTM_PRICE_BY_BLOCK_NUMBER(usefulBlockNumber),
      fetchPolicy: 'network-only',
    })
    return tokenDataPromise
  }
  return undefined
}

export const useFTMPriceUSDByBlock = (blockNumber: number, graphLastBlock: number): number => {
  const price = usePromise(getFTMPriceUSDByBlock, blockNumber, graphLastBlock)
  return price?.data?.bundle?.ethPrice
}

const getTokenDerivedFTMByBlock = async (token: string, blockNumber: number, graphLastBlock: number): Promise<ApolloQueryResult<any>> => {
  const usefulBlockNumber: number = blockNumber > graphLastBlock ? graphLastBlock : blockNumber
  let address: string = null
  if (token.length === 42) {
    address = token
  } else {
    address = token !== '' ? tokenNameToAddress(token).toLowerCase() : null
  }
  if (usefulBlockNumber !== 0 && usefulBlockNumber !== null && usefulBlockNumber !== undefined) {
    const tokenData = await clientSpooky.query({
      query: TOKEN_DERIVED_FTM_BY_BLOCK_NUMBER(address, usefulBlockNumber),
      fetchPolicy: 'network-only',
    })
    return tokenData
  }
  return undefined
}

export const useTokenDerivedFTMByBlock = (token: string, blockNumber: number, graphLastBlock: number): number => {
  const info = usePromise(getTokenDerivedFTMByBlock, token, blockNumber, graphLastBlock)
  return info?.data?.token?.derivedETH
}

export const useTokenPriceByBlock = (address: string, blockNumber: number, graphLastBlock): number => {
  const tokenDerived: number = useTokenDerivedFTMByBlock(address, blockNumber, graphLastBlock)
  const ftmPrice: number = useFTMPriceUSDByBlock(blockNumber, graphLastBlock)
  const tokenPrice: number = tokenDerived * ftmPrice
  return tokenPrice
}

export const getTokenUSDPriceByTimestamp = async (address: string, timestamp: number): Promise<ApolloQueryResult<any>> => {
  const tokenData = await clientSpooky.query({
    query: TOKEN_PRICE_USD(address, timestamp),
    fetchPolicy: 'network-only',
  })
  return tokenData
}

export const useTokenUSDPriceByTimestamp = (address: string, timestamp: number) => {
  return usePromise(getTokenUSDPriceByTimestamp, address, timestamp)
}

const getTokenPrice = async (pairAddress: string, blockNumber: number = undefined, timestamp: number = undefined) => {
  if (timestamp) {
    const res = pairAddress.length === 42
      ? await clientSpooky.query({ query: TOKEN_PRICE_USD(pairAddress, timestamp) })
      : await clientSpooky.query({ query: TOKEN_PRICE_USD(pairAddress.slice(0, -6), timestamp) })
    return res
  } else if (blockNumber) {
    const block = await web3.eth.getBlock(blockNumber)
    const blockTimestamp: number = block.timestamp
    const res = pairAddress.length === 42
      ? await clientSpooky.query({ query: TOKEN_PRICE_USD(pairAddress, blockTimestamp) })
      : await clientSpooky.query({ query: TOKEN_PRICE_USD(pairAddress.slice(0, -6), blockTimestamp) })
    return res
  } else if (!blockNumber && !timestamp) return undefined
}

export const useGetTokenPriceUsd = (pairAddress: string, blockNumber: number = undefined, timestamp: number = undefined): TokenInfoSpooky => {
  const result = usePromise(getTokenPrice, pairAddress, blockNumber, timestamp)
  const tokenData: TokenInfoSpooky = result.data.tokenDayDatas[0]
  return tokenData
}
