import React, { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { BaseNodeModal } from "../BaseNodeModal"
import { SplitModalData } from "src/routes/RecipeDiagram/helpers/types"
import { ENVIRONMENT } from "src/constants";

import "./SplitModal.sass"

interface SplitModalPropTypes extends BaseNodeModal<SplitModalData> { }

const coins = ENVIRONMENT.tokens

const SplitModal = (props: SplitModalPropTypes) => {
  const { onSave, previousData } = props

  const [firstCoin, setFirstCoin] = useState(previousData?.firstCoin)
  const [secondCoin, setSecondCoin] = useState(previousData?.secondCoin)

  const [firstPercentage, setFirstPercentage] = useState(previousData?.firstPercentage || 50)
  const [secondPercentage, setSecondPercentage] = useState(previousData?.secondPercentage || 50)

  const intl = useIntl()

  const currency: string = previousData?.inputCoin || intl.formatMessage({ id: 'split-modal.connect' })

  const changeCoins = (coinNumber: string, value: number) => {
    let targetValue = value <= 0 ? 1 : value
    if (targetValue >= 100) {
      targetValue = 99
    }
    if (coinNumber === 'first') {
      setFirstPercentage(targetValue)
      setSecondPercentage(100 - targetValue)
    } else {
      setSecondPercentage(targetValue)
      setFirstPercentage(100 - targetValue)
    }
  }

  return (
    <div className="split-modal modal-content">

      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button className="modal-close-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="split-modal.title" /></h1>
          <CloseModal>
            <button
              disabled={!firstCoin || !secondCoin}
              onClick={() => {
                onSave({ firstCoin, secondCoin, firstPercentage, secondPercentage })
              }}
              className="filled"
            >
              <FormattedMessage id="save" />
            </button>
          </CloseModal>
        </header>

        <section className="split-modal-data">
          <div className="from">
            <h2><FormattedMessage id="swap-modal.amount.from" /></h2>
            <strong>{currency}</strong>
          </div>
        </section>
        <div className="split-modal-grid">
          <section className="split-modal-currency">
            <h3><FormattedMessage id="swap-modal.amount.to" /></h3>
            <div className="input-wrapper">
              <select onChange={(ev) => { setFirstCoin(ev.target.value) }} defaultValue={firstCoin} name="coins">
                <FormattedMessage id="select.currency">
                  {text => <option value="" hidden>{text}</option>}
                </FormattedMessage>
                {coins.map(c => (
                  <option key={'1st-' + c.id} value={c.id}>{c.id}</option>
                ))}
              </select>
              <label><input onChange={(ev) => changeCoins('first', Number(ev.target.value))} type="number" value={firstPercentage} min={1} max={99} />%</label>
            </div>
          </section>
          <section className="split-modal-currency">
            <h3><FormattedMessage id="swap-modal.amount.to" /></h3>
            <div className="input-wrapper">
              <select onChange={(ev) => { setSecondCoin(ev.target.value) }} defaultValue={secondCoin} name="coins">
                <FormattedMessage id="select.currency">
                  {text => <option value="" hidden>{text}</option>}
                </FormattedMessage>
                {coins.map(c => (
                  <option key={'2nd-' + c.id} value={c.id}>{c.id}</option>
                ))}
              </select>
              <label><input onChange={(ev) => changeCoins('second', Number(ev.target.value))} type="number" value={secondPercentage} min={1} max={99} />%</label>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

registerModal("split-modal", SplitModal)
