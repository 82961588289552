import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import pairsAPI from "src/api/farms/api"
import { DepositOnLPNodeData } from "src/routes/RecipeDiagram/helpers/types"
import { ViewRecipeLogsProps } from "../ViewRecipeLogs/ViewRecipeLogs"
import { useLPPriceUSD } from "src/hooks/useLPsLogs"
import { useLastSyncGraphBlock } from "src/hooks/useGraph"
import { useBlockNumberFromTxHash, useDepositBlockNumberFromTxHash } from "src/hooks/useWeb3"

import 'src/routes/RecipeDiagram/SideBarViewLogs/SidebarViewLogs.sass'

interface DepositExtraDataLogProps extends ViewRecipeLogsProps {
  data: DepositOnLPNodeData
}
const DepositExtraDataLog = (props: DepositExtraDataLogProps) => {
  const { data, events, trans } = props
  const [poolAPR, setPoolAPR] = useState<number | string>()
  const [poolLiquidity, setPoolLiquidity] = useState<string>('?')

  const myPoolId: string = data.pair.id
  const NO_EXECUTED_NODE: number = 0
  const ACTIVE_NODE: number = 2
  const FINISHED_NODE: number = 4
  const ABORTED_NODE: number = 5
  let amountDeposited: string = '?'
  let executionSteps: number
  let blockNumberAtDeposit: number = 0
  let blockNumberAtOtherMoment: number = 0

  if (events !== undefined && events !== null) {
    executionSteps = events.length
    amountDeposited = events[1].output.amount
  } else {
    executionSteps = 0
  }

  const graphLastBlock: number = useLastSyncGraphBlock()
  blockNumberAtDeposit = useDepositBlockNumberFromTxHash(trans[0]) || null
  blockNumberAtOtherMoment = useBlockNumberFromTxHash(trans, executionSteps)
  if (executionSteps === ACTIVE_NODE) blockNumberAtOtherMoment = graphLastBlock

  useEffect(() => {
    if (blockNumberAtOtherMoment !== 0) {
      pairsAPI.getSinglePairForLogs(myPoolId.slice(0, -6), blockNumberAtOtherMoment)
        .then((poolData) => {
          setPoolAPR((poolData.poolAPR).toFixed(2))
          setPoolLiquidity(parseInt(poolData.reserveUSD).toLocaleString())
        }).catch((err) => {
          console.log("Error in depositExtraDataLog while getting singlePairInfo:", err)
          setPoolAPR("Not enough info")
          setPoolLiquidity("Not enough info")
        })
    }
  }, [myPoolId, blockNumberAtOtherMoment])

  const lpUSDPriceAtDeposit: number = useLPPriceUSD(myPoolId, blockNumberAtDeposit, graphLastBlock)
  const lpUSDPriceAtOtherMoment: number = useLPPriceUSD(myPoolId, blockNumberAtOtherMoment, graphLastBlock)
  const earnedUSDValue: string = (Number(amountDeposited) * lpUSDPriceAtOtherMoment - Number(amountDeposited) * lpUSDPriceAtDeposit).toFixed(3)

  return (
    <div className="div-extra-info">
      <div className="first-column">
        <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.deposited" />  </p>
          <div className="left-empty-position"></div>
        </div>
        <div className="logs-group-rows">
          {executionSteps === NO_EXECUTED_NODE && <p><FormattedMessage id="farm-modal-log.earned" />  </p>}
          {executionSteps === ACTIVE_NODE && <p><FormattedMessage id="farm-modal-log.current" />  </p>}
          {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) && <p><FormattedMessage id="farm-modal-log.earned" />  </p>}
          <div className="left-empty-position"></div>
        </div>
        <p><FormattedMessage id="farm-modal-log.apr" />   </p>
        <p><FormattedMessage id="farm-modal-log.liquidity" />  </p>
      </div>
      <div className="second-column">
        <div className="logs-group-rows">
          <p className="main-text-logs"> $ {(Number(amountDeposited) * lpUSDPriceAtDeposit).toFixed(3)}</p>
          <p>{amountDeposited} LP</p>
        </div>
        <div className="logs-group-rows">
          {executionSteps === NO_EXECUTED_NODE && <p className="main-text-logs">$ --- </p>}
          {executionSteps === ACTIVE_NODE && <p className="main-text-logs">$ {(Number(amountDeposited) * lpUSDPriceAtOtherMoment).toFixed(3)}</p>}
          {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) && <p className="main-text-logs">$ {earnedUSDValue}</p>}
          <p>{amountDeposited} LP</p>
        </div>
        <p>{poolAPR} % </p>
        <p> $ {poolLiquidity}</p>
      </div>
    </div>
  )
}

export default DepositExtraDataLog
