import React, { Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, useLocation } from 'react-router-dom'
import Home from './Home/Home'
import Dashboard from './Dashboard/Dashboard'
import InternalApp from './App/App'
import NotFound from './NotFound/NotFound'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import { ModalWrapper, Modal } from '@gluedigital/modal'
import Diagram from './RecipeDiagram/Diagram'
import RecipeSummary from './RecipeSummary/RecipeSummary'
import Meta from 'src/components/Meta/Meta'
import { SocketProvider } from 'src/context/SocketContext'
import AprDetails from './AprDetails/AprDetails'
// import DiagramViewLogs from './RecipeDiagram/DiagramViewLogs'

declare const __CLIENT__: boolean

const App = () => {
  const dispatch = useDispatch()

  const location = useLocation()
  useEffect(() => {
    dispatch({ type: '@@_AFTER_RENDER' })
  }, [dispatch])
  const currentLang = useSelector((s: any) => s.intl.locale)

  return (
    <ModalWrapper>
      <Meta />
      <main id="app" className={`language-${currentLang}`}>
        <ErrorBoundary>
          <div className="pages-transition-group">
            <Switch location={location}>
              <Route path="/" exact> <Home /></Route>
              {__CLIENT__ && (
                <InternalApp>
                  <SocketProvider>
                    <Switch>
                      <Route path="/recipe" exact component={Diagram}></Route>
                      {/* <Route path="/recipe/viewdata/:id" exact component={DiagramViewLogs}></Route> */}
                      <Route path="/recipe/apr-details/:id" exact component={AprDetails}></Route>
                      <Route path="/recipe/:id/:recipeName?" exact component={Diagram} ></Route>
                      <Route path="/recipe-summary/:id/:recipeName?" exact component={RecipeSummary}></Route>
                      <Route path="/dashboard/:pathParam1?" exact component={Dashboard}></Route>
                      <Route><NotFound /></Route>
                    </Switch>
                  </SocketProvider>
                </InternalApp>
              )}
            </Switch>
          </div>
        </ErrorBoundary>
        <Modal />
      </main>
    </ModalWrapper>
  )
}

const AppWrapper = (props) => (
  <Suspense fallback={<div />}>
    <App {...props} />
  </Suspense>
)

export default AppWrapper
