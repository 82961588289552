import { differenceInCalendarDays } from 'date-fns'
import { BigNumber } from 'ethers'
import copy from 'fast-copy'
import { pairTokensFarms, pairTokensPools, SPOOKY_FEE } from 'src/constants'
import web3 from "src/utils/web3"
import { FarmGraphData, PairDayData, PairDayDataResponse, PoolInfo, PoolsInfoObj, SpookyData } from './types'
import pairsContract from '../../contracts/abis/pairsContract.json'
import { tokenAddressToName } from 'src/components/Diagram/nodes/nodesLogsHelper'
import { ENVIRONMENT } from '../../constants';

export const BN = (n: any) => {
  return BigNumber.from(n.toString())
}

const EMPTY_ADDRESS: string = "0x0000000000000000000000000000000000000000"

export const getReserveFromPoolsContract = (pairAddress: string) => {
  const contract = new web3.eth.Contract(pairsContract as any, pairAddress)
  return contract.methods.getReserves().call()
}

export const getFarmApr = (
  pair: PairDayData,
  poolsInfo: PoolsInfoObj,
  totalAllocPoint: string,
  booPerSecond: string,
  booPriceUSD: number
) => {
  const FROM_WEI_TO_ETHER_CONSIDERING_PERCENTAGE: number = 10 ** 16
  const SECONDS_IN_A_YEAR: number = 31536000
  const booRewardPerYear = parseFloat(booPerSecond) * SECONDS_IN_A_YEAR
  const poolRewardProportion = parseFloat(poolsInfo[pair.pairAddress]?.allocPoint) / parseFloat(totalAllocPoint)
  const poolRewardPerYearUSD = poolRewardProportion * booRewardPerYear * booPriceUSD
  return poolRewardPerYearUSD / parseFloat(pair.reserveUSD) / FROM_WEI_TO_ETHER_CONSIDERING_PERCENTAGE
}

export const getFeesApr = (pair: PairDayData, fee: number) => {
  return (parseFloat(pair.dailyVolumeUSD) * 365 * fee) / parseFloat(pair.reserveUSD)
}

export const poolTransformer = (pool: PoolInfo, lpToken: string) => {
  const res = {
    lpToken: lpToken.toString(),
    allocPoint: pool.allocPoint.toString(),
    accBOOPerShare: null,
    lastRewardTime: null
  }
  if (pool.accBOOPerShare) {
    res.accBOOPerShare = pool.accBOOPerShare.toString()
    res.lastRewardTime = pool.lastRewardTime.toString()
  }
  return res
}

export const getVaultDataForPairTokens = (token0: string, token1: string): string => {
  const vaultIsV2: boolean = ENVIRONMENT.contracts.vaults.some((vault) => {
    return vault?.token0 === token0 && vault?.token1 === token1
  })

  if (vaultIsV2) {
    const vault: any = ENVIRONMENT.contracts.vaults.find((vault) => {
      return vault.token0 === token0 && vault.token1 === token1
    })
    return vault?.strategy
  } else {
    const vaultV3: any = ENVIRONMENT.contracts.vaultsV3.find((vault) => {
      return vault.token0 === token0 && vault.token1 === token1
    })
    return vaultV3?.strategy
  }
}

export const obtainRewardTokenForPair = (farmAddressOrderedByPoolId: string[], pair: PairDayData, tokensAddressForFarmRewards: string[]) => {
  const pairPoolId = farmAddressOrderedByPoolId.indexOf(pair.pairAddress)
  return (tokensAddressForFarmRewards[pairPoolId])
}

export const pairsTransformerSpooky = (spookyData: SpookyData, poolsInfo: PoolsInfoObj, farmAddressOrderedByPoolId: string[], poolsInfoV3: PoolsInfoObj, isPools: boolean, blockNumber = 0) => {
  const pairsWithApr = []
  const pairsIdentifier = !isPools ? pairTokensFarms : pairTokensPools
  const pairsDayData: PairDayDataResponse = spookyData.pairsDayData
  if (pairsDayData.hasTokens) {
    const spooky = {
      updated: '' + new Date().getTime(),
      pairTokens: {},
    }
    if (blockNumber !== 0) {
      const pairData: any = pairsDayData.data
      const pair = pairData.pairDayDatas[0]
      const pairWithApr = copy(pair)
      const tokenRewardAddress: string = obtainRewardTokenForPair(farmAddressOrderedByPoolId, pair, spookyData.tokensAddressForFarmRewards)
      pairWithApr.aprFarm = tokenRewardAddress === EMPTY_ADDRESS ?
        getFarmApr(pair, poolsInfo, spookyData.totalAllocPointBOO, spookyData.booPerSecond, spookyData.booPriceUsd) :
        getFarmApr(pair, poolsInfoV3, spookyData.totalAllocPointOther, spookyData.otherTokenPerSecond, spookyData.otherTokenPriceUsd)
      pairWithApr.aprFees = getFeesApr(pair, SPOOKY_FEE)
      pairWithApr.provider = 'SpookySwap'
      pairWithApr.tokenReward = tokenRewardAddress === EMPTY_ADDRESS ? 'BOO' : tokenAddressToName(spookyData.otherTokenAddress.toLowerCase())
      pairWithApr.allocPoint = tokenRewardAddress === EMPTY_ADDRESS ? poolsInfo[pair.pairAddress]?.allocPoint : poolsInfoV3[pair.pairAddress]?.allocPoint
      spooky.pairTokens[pair.pairAddress] = { token0: pair.token0, token1: pair.token1, }
      pairsWithApr.push(pairWithApr)
      const currentPairTokens = localStorage.getItem(pairsIdentifier)
      if (currentPairTokens) {
        const updatedPairTokens = JSON.parse(currentPairTokens)
        updatedPairTokens.spooky = spooky
        localStorage.setItem(pairsIdentifier, JSON.stringify(updatedPairTokens))
      } else localStorage.setItem(pairsIdentifier, JSON.stringify({ spooky }))
    } else {
      const dayIds: any[] = []
      pairsDayData.data.forEach((pair) => {
        const pairTimestampId = Number(pair.id.slice(-5))
        if (dayIds.length === 0) dayIds.push(pairTimestampId)
        if (!dayIds.includes(pairTimestampId)) dayIds.push(pairTimestampId)
      })
      let pairDayIndex: number = 0
      const currentIdDay: number = Math.max(...dayIds)
      for (const pair of pairsDayData.data) {
        pairDayIndex = dayIds.indexOf(Number(pair.id.slice(-5)))
        const pairWithApr = copy(pair)
        const tokenRewardAddress: string = obtainRewardTokenForPair(farmAddressOrderedByPoolId, pair, spookyData.tokensAddressForFarmRewards)
        pairWithApr.aprFarm = tokenRewardAddress === EMPTY_ADDRESS ?
          getFarmApr(pair, poolsInfo, spookyData.totalAllocPointBOO, spookyData.booPerSecond, Number(spookyData.booPriceUsd[pairDayIndex].priceUSD)) :
          getFarmApr(pair, poolsInfoV3, spookyData.totalAllocPointOther, spookyData.otherTokenPerSecond, Number(spookyData.otherTokenPriceUsd[pairDayIndex].priceUSD))
        pairWithApr.aprFees = getFeesApr(pair, SPOOKY_FEE)
        if (dayIds[pairDayIndex] === currentIdDay) {
          pairWithApr.aprFarmCurrent = tokenRewardAddress === EMPTY_ADDRESS ?
            getFarmApr(pair, poolsInfo, spookyData.totalAllocPointBOO, spookyData.booPerSecond, Number(spookyData.booPriceUsd[pairDayIndex].priceUSD)) :
            getFarmApr(pair, poolsInfoV3, spookyData.totalAllocPointOther, spookyData.otherTokenPerSecond, Number(spookyData.otherTokenPriceUsd[pairDayIndex].priceUSD))
          pairWithApr.aprFeesCurrent = getFeesApr(pair, SPOOKY_FEE)
        }
        pairWithApr.provider = 'SpookySwap'
        pairWithApr.tokenReward = tokenRewardAddress === EMPTY_ADDRESS ? 'BOO' : tokenAddressToName(spookyData.otherTokenAddress)
        pairWithApr.allocPoint = tokenRewardAddress === EMPTY_ADDRESS ? poolsInfo[pair.pairAddress]?.allocPoint : poolsInfoV3[pair.pairAddress]?.allocPoint
        spooky.pairTokens[pair.pairAddress] = { token0: pair.token0, token1: pair.token1, }
        pairsWithApr.push(pairWithApr)
      }
      const currentPairTokens = localStorage.getItem(pairsIdentifier)
      if (currentPairTokens) {
        const updatedPairTokens = JSON.parse(currentPairTokens)
        updatedPairTokens.spooky = spooky
        localStorage.setItem(pairsIdentifier, JSON.stringify(updatedPairTokens))
      } else localStorage.setItem(pairsIdentifier, JSON.stringify({ spooky }))
    }
  } else {
    if (blockNumber !== 0) {
      const pairData: any = pairsDayData.data
      const pair = pairData.pairDayDatas[0]
      const tokenRewardAddress: string = obtainRewardTokenForPair(farmAddressOrderedByPoolId, pair, spookyData.tokensAddressForFarmRewards)
      const pairWithApr = copy(pair)
      pairWithApr.aprFarm = tokenRewardAddress === EMPTY_ADDRESS ?
        getFarmApr(pair, poolsInfo, spookyData.totalAllocPointBOO, spookyData.booPerSecond, spookyData.booPriceUsd) :
        getFarmApr(pair, poolsInfoV3, spookyData.totalAllocPointOther, spookyData.otherTokenPerSecond, spookyData.otherTokenPriceUsd)
      pairWithApr.aprFees = getFeesApr(pair, SPOOKY_FEE)
      pairWithApr.token0 = pair.token0
      pairWithApr.token1 = pair.token1
      pairWithApr.provider = 'SpookySwap'
      pairWithApr.tokenReward = tokenRewardAddress === EMPTY_ADDRESS ? 'BOO' : tokenAddressToName(spookyData.otherTokenAddress)
      pairWithApr.allocPoint = tokenRewardAddress === EMPTY_ADDRESS ? poolsInfo[pair.pairAddress]?.allocPoint : poolsInfoV3[pair.pairAddress]?.allocPoint
      pairsWithApr.push(pairWithApr)
    } else {
      const pairTokens = JSON.parse(localStorage.getItem(pairsIdentifier)).spooky.pairTokens
      const dayIds: any[] = []
      pairsDayData.data.forEach((pair) => {
        if (dayIds.length === 0) dayIds.push(Number(pair.id.slice(-5)))
        if (!dayIds.includes(Number(pair.id.slice(-5)))) dayIds.push(Number(pair.id.slice(-5)))
      })
      let pairDayIndex: number = 0
      const currentIdDay: number = Math.max(...dayIds)
      for (const pair of pairsDayData.data) {
        if (!pairTokens[pair.pairAddress]) continue
        const pairWithApr = copy(pair)
        pairDayIndex = dayIds.indexOf(Number(pair.id.slice(-5)))
        const tokenRewardAddress: string = obtainRewardTokenForPair(farmAddressOrderedByPoolId, pair, spookyData.tokensAddressForFarmRewards)
        pairWithApr.aprFarm = tokenRewardAddress === EMPTY_ADDRESS ?
          getFarmApr(pair, poolsInfo, spookyData.totalAllocPointBOO, spookyData.booPerSecond, Number(spookyData.booPriceUsd[pairDayIndex].priceUSD)) :
          getFarmApr(pair, poolsInfoV3, spookyData.totalAllocPointOther, spookyData.otherTokenPerSecond, Number(spookyData.otherTokenPriceUsd[pairDayIndex].priceUSD))
        pairWithApr.aprFees = getFeesApr(pair, SPOOKY_FEE)
        if (dayIds[pairDayIndex] === currentIdDay) {
          pairWithApr.aprFarmCurrent = tokenRewardAddress === EMPTY_ADDRESS ?
            getFarmApr(pair, poolsInfo, spookyData.totalAllocPointBOO, spookyData.booPerSecond, Number(spookyData.booPriceUsd[pairDayIndex].priceUSD)) :
            getFarmApr(pair, poolsInfoV3, spookyData.totalAllocPointOther, spookyData.otherTokenPerSecond, Number(spookyData.otherTokenPriceUsd[pairDayIndex].priceUSD))
          pairWithApr.aprFeesCurrent = getFeesApr(pair, SPOOKY_FEE)
        }
        pairWithApr.token0 = pair.token0
        pairWithApr.token1 = pair.token1
        pairWithApr.provider = 'SpookySwap'
        pairWithApr.tokenReward = tokenRewardAddress === EMPTY_ADDRESS ? 'BOO' : tokenAddressToName(spookyData.otherTokenAddress)
        pairWithApr.allocPoint = tokenRewardAddress === EMPTY_ADDRESS ? poolsInfo[pair.pairAddress]?.allocPoint : poolsInfoV3[pair.pairAddress]?.allocPoint
        pairsWithApr.push(pairWithApr)
      }
    }
  }
  return pairsWithApr
}
export const obtainAvg5DaysFarmsData = (fiveDaysPairsInfo: FarmGraphData): FarmGraphData => {
  const avgPoolsData: any = []
  const farmAddresses: string[] = []
  const poolsArray = fiveDaysPairsInfo.pools.data
  for (let i = 0; i < poolsArray.length; i++) {
    if (!farmAddresses.includes(poolsArray[i].pairAddress)) {
      farmAddresses.push(poolsArray[i].pairAddress)
    }
  }

  for (const address of farmAddresses) {
    let totalDailyVolume: number = 0
    let totalReserveUSD: number = 0
    let totalAprFees: number = 0
    let totalAprFarm: number = 0
    const dataForSameFarm: any[] = poolsArray.filter((farm) => farm.pairAddress === address)
    for (let j = 0; j < dataForSameFarm.length; j++) {
      totalDailyVolume += parseFloat(dataForSameFarm[j].dailyVolumeUSD)
      totalReserveUSD += parseFloat(dataForSameFarm[j].reserveUSD)
      totalAprFees += parseFloat(dataForSameFarm[j].aprFees)
      totalAprFarm += parseFloat(dataForSameFarm[j].aprFarm)
    }
    totalDailyVolume = totalDailyVolume / dataForSameFarm.length
    totalReserveUSD = totalReserveUSD / dataForSameFarm.length
    totalAprFees = totalAprFees / dataForSameFarm.length
    totalAprFarm = totalAprFarm / dataForSameFarm.length
    const pairWithCurrentAPRs = dataForSameFarm.filter((farm) => farm.aprFarmCurrent !== undefined)
    const farmFiveDaysAvg = {
      ...pairWithCurrentAPRs[0],
      reserveUSD: totalReserveUSD,
      aprFarm: totalAprFarm,
      aprFees: totalAprFees,
      dailyVolumeUSD: totalDailyVolume,
    }
    if (!isNaN(totalAprFarm)) {
      avgPoolsData.push(farmFiveDaysAvg)
    }
  }
  const newFiveDaysInfo: FarmGraphData = { ...fiveDaysPairsInfo, pools: { data: avgPoolsData } }
  return newFiveDaysInfo
}

export const onlyActivePools = (pools: any[]) => {
  const result = []
  for (const i of pools) if (i.aprFarm > 0 || i.aprFees > 0) result.push(i)
  return result
}

export const organisedPoolsInfo = (pools: any[]) => {
  const result = []
  for (const i of pools) result.push(i)
  return result
}

export const currentAPR = (deposited: number, earned: number, startDate: Date): number => {
  let daysDifference = differenceInCalendarDays(new Date(), new Date(startDate))
  if (daysDifference <= 0) {
    daysDifference = 1
  }

  const depositReturn = earned / deposited

  const depositOverDays = (+depositReturn / daysDifference)

  return depositOverDays * 365 * 100
}

export const convertStringMonthToNumeric = (month: string): number => {
  switch (month) {
    case ('Jan'):
      return 0;
    case ('Feb'):
      return 1;
    case ('Mar'):
      return 2;
    case ('Apr'):
      return 3;
    case ('May'):
      return 4;
    case ('Jun'):
      return 5;
    case ('Jul'):
      return 6;
    case ('Aug'):
      return 7;
    case ('Sep'):
      return 8;
    case ('Oct'):
      return 9;
    case ('Nov'):
      return 10;
    case ('Dec'):
      return 11;
    default:
      return 12;
  }
}
export const setCorrectEpochTimeToGetFarms = (lastBlockTimeStamp: number) => {
  const utcDateLastBlockTimeStamp: string = new Date(lastBlockTimeStamp * 1000).toUTCString()
  const utcDateArray: string[] = utcDateLastBlockTimeStamp.split(' ')
  const day: number = Number(utcDateArray[1])
  const month: number = convertStringMonthToNumeric(utcDateArray[2])
  const year: number = Number(utcDateArray[3])
  const epochToRequest: any = Date.UTC(year, month, day, 0, 0, 0)
  return (Math.floor((epochToRequest) / 1000))
}
