import { tokenNameToAddress } from 'src/components/Diagram/nodes/nodesLogsHelper';
import { tokensABI } from "src/data/ABIs"

export const getBalance = async (provider: any, user: any, tokenAddress: string) => {
    const ROUND_SIX_DECIMALS : number = 1000000
    if(tokenAddress === tokenNameToAddress('FTM')) {
        const balanceNotRounded: number = parseFloat(user.balance.toString())
        const balanceRounded: number  = Math.floor(balanceNotRounded * ROUND_SIX_DECIMALS) / ROUND_SIX_DECIMALS
        return balanceRounded
    } else {
        const token: any = new provider.eth.Contract(tokensABI, tokenAddress);
        const decimals: number = await token.methods.decimals().call()
        let balance: number = provider.utils.fromWei(await token.methods.balanceOf(user.account).call(), 'ether')
        if(decimals == 18) {
            const balanceNotRounded: number = parseFloat(balance.toString())
            const balanceRounded  = Math.floor(balanceNotRounded * ROUND_SIX_DECIMALS) / ROUND_SIX_DECIMALS
            return balanceRounded
        } else {
            const decimalsDifference: number = 18 - decimals
            const valueDifference: number = 10 ** decimalsDifference
            balance = balance * valueDifference
            const balanceNotRounded: number = parseFloat(balance.toString())
            if(decimals == 6) {
                const balanceRounded: number  = Math.floor((balanceNotRounded- 0.000001) * ROUND_SIX_DECIMALS) / ROUND_SIX_DECIMALS
                return balanceRounded
            }
            const balanceRounded: number  = Math.floor(balanceNotRounded * ROUND_SIX_DECIMALS) / ROUND_SIX_DECIMALS
            return balanceRounded
        }
    }
}