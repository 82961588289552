import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useRecipeCRUD } from "src/api/recipes";
import { ENVIRONMENT, METAMASK_ERROR_CODES } from "src/constants";
import { useRecipeLogsLive } from "src/context/SocketContext";
import abortRecipe from "src/contracts/abort";
import web3 from "src/utils/web3";
import { useToast } from "./useToast";

export const useAbort = (recipe: any) => {
  const sendToast = useToast()
  const userAddress = useSelector((s: any) => s.user?.account?.account)
  const recipeCRUD = useRecipeCRUD()

  const liveRecipeLogs = useRecipeLogsLive(recipe?.id.toString())

  useEffect(() => {
      const abortLogs = liveRecipeLogs.filter((log) => log.logtype === 'aborted')
      if (abortLogs.length > 0) {
        const lastAbortLog = abortLogs[abortLogs.length - 1]
        if (lastAbortLog.events.length <= 0) {
          sendToast(recipe.title, 'abort', `${ENVIRONMENT.blockExplorerUrls}tx/${lastAbortLog.trans}`)
        } else {
          sendToast(recipe.title, 'abort-success', `${ENVIRONMENT.blockExplorerUrls}tx/${lastAbortLog.trans}`)
        }
      }
  }, [liveRecipeLogs])

  const toastCb = (txHash: string) => {
    sendToast(recipe.title, 'abort', `${ENVIRONMENT.blockExplorerUrls}tx/${txHash}`)
  }
  const abortHandler = useCallback(async () => {
    await abortRecipe(web3, userAddress, recipe.step, toastCb)
      .then(async (res) => {
        console.log('Abort res is: ', res)
        if (res) {
          sendToast(recipe.title, 'abort-success', `${ENVIRONMENT.blockExplorerUrls}tx/${res.transactionHash}`)
        }
        await recipeCRUD.abort(recipe.id)
      })
      .catch((err) => {
        if (err.code === METAMASK_ERROR_CODES.USER_DENIED || err.code === METAMASK_ERROR_CODES.TRANSACTION_UNDERPRICED) {
          console.log(err.message)
        } else {
          sendToast(recipe.title, 'abort-fail')
          console.error('Error detected while aborting recipe: ', err)
        }
      })
  }, [recipe])

  return abortHandler
}
