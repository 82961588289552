
import React, { Suspense, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import ExtraDataSideBarLogs from "src/components/Log/ExtraDataSideBarLogs/ExtraDataSideBarLogs"
import { convertMonth, getAmountPercentage, tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { NodeType } from "src/routes/RecipeDiagram/helpers/types"
import { NodeExecutionResult } from "src/types"
import 'src/routes/RecipeDiagram/SideBarViewLogs/SidebarViewLogs.sass'
import ComboExtraDataLog from "../ComboExtraDataLog/ComboExtraDataLog"
import DepositExtraDataLog from "../DepositExtraDataLog/DepositExtraDataLog"
import FarmExtraDataLog from "../FarmExtraDataLog/FarmExtraDataLog"
import { ENVIRONMENT } from "src/constants"
import { getEtherNumberAmountFromAddress } from '../../../contracts/TokensDecimalsHelpers';
import { SideBarErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { ExplanationButton } from "./FarmExplanationLog"
import { useModal } from "@gluedigital/modal"
import info from 'src/static/images/info.png'
import { DeprecatedVault } from '../FarmExtraDataLog/DeprecatedVault';
import { getVaultDataForPairTokens } from "src/api/farms/farmHelpers"

export interface ViewRecipeLogsProps {
  id: string
  date: Date[]
  type: NodeType
  trans: string[]
  data: any
  events: NodeExecutionResult[]
  recipeStatus: string
  live: boolean
}

const ViewRecipeLogs = (props: ViewRecipeLogsProps) => {
  const { type, data, events, date, live } = props
  const DECIMALS_FOR_TOKEN_AMOUNT: number = 10
  const DECIMALS_FOR_USD_AMOUNT: number = 3
  const modal = useModal()
  let UTCDate: string
  if (date.length > 0) {
    let localDate: Date
    if (date.length === 1) localDate = new Date(date[0])
    else if (date.length === 2) localDate = new Date(date[1])
    const UTCHour = localDate.getUTCHours() < 10 ? '0' + localDate.getUTCHours() : localDate.getUTCHours()
    const UTCMonth = localDate.getUTCMonth() + 1 < 10 ? convertMonth('0' + String(localDate.getUTCMonth() + 1)) : convertMonth(String(localDate.getUTCMonth() + 1))
    const UTCMinutes = localDate.getUTCMinutes() < 10 ? '0' + localDate.getUTCMinutes() : localDate.getUTCMinutes()
    const UTCDay = localDate.getUTCDate() < 10 ? '0' + localDate.getUTCDate() : localDate.getUTCDate()
    UTCDate = `${UTCDay} ${UTCMonth} ${UTCHour}:${UTCMinutes}`
  }
  let logBody: JSX.Element
  const [tokenAmountZero, setTokenAmountZero] = useState<number>(0)
  const [feeLiveSwap, setFeeLiveSwap] = useState<number>(0)
  const [tokenAmountZeroSplit, setTokenAmountZeroSplit] = useState<number>(0)
  const [tokenAmountOneSplit, setTokenAmountOneSplit] = useState<number>(0)
  const [tokenInputAmountSplit, setTokenInputamountSplit] = useState<number>(0)

  const handleClick = () => {
      modal.show('farm-explanation-modal')
  }

  useEffect(() => {
    if (type === "sendToWalletNode") {
      getEtherNumberAmountFromAddress(tokenNameToAddress(data.inputCoin), events[0]?.output?.amount.split('.').join('')).then((amount) => setTokenAmountZero(Number(amount))).catch(err => console.error(err.message));
    } else if (type === "liquidateNode") {
      getEtherNumberAmountFromAddress(tokenNameToAddress(data.outputCoin), events[0]?.input?.amount.split('.').join('')).then((amount) => setFeeLiveSwap(Number(amount))).catch(err => console.error(err.message));
      getEtherNumberAmountFromAddress(tokenNameToAddress(data.outputCoin), events[0]?.output?.amount).then((amount) => setTokenAmountZero(Number(amount))).catch(err => console.error(err.message));
    } else if (type === "swapNode") {
      getEtherNumberAmountFromAddress(tokenNameToAddress(data.outputCoin), events[0]?.output?.amount.split('.').join('')).then((amount) => setTokenAmountZero(Number(amount))).catch(err => console.error(err.message));
      getEtherNumberAmountFromAddress(tokenNameToAddress(data.outputCoin), events[0]?.input?.amount.split('.').join('')).then((amount) => setFeeLiveSwap(Number(amount))).catch(err => console.error(err.message));
    } else if (type === "splitNode") {
      getEtherNumberAmountFromAddress(tokenNameToAddress(data.inputCoin), events[0]?.input?.amount.split('.').join('')).then((amount) => setTokenInputamountSplit(Number(amount))).catch(err => console.error(err.message));
      getEtherNumberAmountFromAddress(tokenNameToAddress(data.firstCoin), events[0]?.output[0]?.amount.split('.').join('')).then((amount) => setTokenAmountZeroSplit(Number(amount))).catch(err => console.error(err.message));
      getEtherNumberAmountFromAddress(tokenNameToAddress(data.secondCoin), events[0]?.output[1]?.amount.split('.').join('')).then((amount) => setTokenAmountOneSplit(Number(amount))).catch(err => console.error(err.message));
    }
  }, [live, events, type, data.inputCoin, data.outputCoin, data.firstCoin, data.secondCoin])

  const amountToShow: number = live ? tokenAmountZero : Number(events[0]?.output?.amount)

  switch (type) {
    case "addFundsNode":
      logBody = <span className="span-nodesData">
        {`${events[0]?.input?.amount}  ${data.outputCoin}`}
        <span className="span-extraInfo"> <FormattedMessage id="view-details-added" /> </span>
      </span>
      break
    case "sendToWalletNode":
      logBody = <span className="span-nodesData">
        {(events.length > 0) &&
          <div className="mt-8">
            <span><FormattedMessage id="view-logs-received" /> {amountToShow.toFixed(DECIMALS_FOR_TOKEN_AMOUNT)} {data.inputCoin}</span>
            <span className="left-logs-usd-price">$ {(Number(events[0]?.extraData?.outputTokenWithPrice?.priceUSD) * amountToShow).toFixed(DECIMALS_FOR_USD_AMOUNT)}</span>
          </div>}
      </span>
      break
    case "splitNode":
      logBody = <span className="span-nodesData">
        {`${data.inputCoin}`} <span className="span-extraInfo">  <FormattedMessage id="view-details-to" /> </span>
        <span>{`${data?.firstPercentage}% ${data?.firstCoin} `}</span> <span className="span-extraInfo"> <FormattedMessage id="view-details-&" /> </span>
        <span>{`${data?.secondPercentage}% ${data?.secondCoin} `}</span>
        {(events.length > 0) &&
          <div>
            <div className="received-line">
              <span><FormattedMessage id="view-logs-received" /> {live ? (tokenAmountZeroSplit) : Number(events[0]?.output[0]?.amount).toFixed(DECIMALS_FOR_TOKEN_AMOUNT)} {data.firstCoin}</span>
            </div>
            <ExtraDataSideBarLogs
              tokenInput={data.inputCoin}
              tokenOutput={data.firstCoin}
              inputFeeAmountLive={getAmountPercentage(tokenInputAmountSplit, data?.firstPercentage)}
              inputFeeAmountNoLive={getAmountPercentage(events[0]?.input?.amount, data?.firstPercentage)}
              inputAmount={events[0]?.extraData?.inputTokenWithPrice?.priceUSD}
              outputAmount={events[0]?.extraData?.outputTokenWithPrice[0]?.priceUSD}
              live={live} />
          </div>}
        {(events.length > 0) &&
          <div>
            <div className="received-line">
              <span><FormattedMessage id="view-logs-received" /> {live ? (tokenAmountOneSplit) : Number(events[0]?.output[1]?.amount).toFixed(DECIMALS_FOR_TOKEN_AMOUNT)} {data.secondCoin}</span>
            </div>
            <ExtraDataSideBarLogs
              tokenInput={data.inputCoin}
              tokenOutput={data.secondCoin}
              inputFeeAmountLive={getAmountPercentage(tokenInputAmountSplit, data.secondPercentage)}
              inputFeeAmountNoLive={getAmountPercentage(events[0]?.input?.amount, data.secondPercentage)}
              inputAmount={events[0]?.extraData?.inputTokenWithPrice?.priceUSD}
              outputAmount={events[0]?.extraData?.outputTokenWithPrice[1]?.priceUSD}
              live={live} />
          </div>}
      </span>
      break
    case "swapNode":
      logBody = <span className="span-nodesData">
        {`${data.inputCoin}`} <span className="span-extraInfo">  <FormattedMessage id="view-details-to" /> </span>
        <span>{`${data.outputCoin}`}</span> <span className="span-extraInfo">  <FormattedMessage id="view-details-converted" /> </span>
        {(events.length > 0) &&
          <div>
            <div className="received-line">
              <span ><FormattedMessage id="view-logs-received" /> {amountToShow.toFixed(DECIMALS_FOR_TOKEN_AMOUNT)} {data.outputCoin}</span>
            </div>
            <ExtraDataSideBarLogs
              tokenInput={data.inputCoin}
              tokenOutput={data.outputCoin}
              inputFeeAmountLive={feeLiveSwap}
              inputFeeAmountNoLive={events[0].input?.amount}
              inputAmount={events[0]?.extraData?.inputTokenWithPrice?.priceUSD}
              outputAmount={events[0]?.extraData?.outputTokenWithPrice?.priceUSD}
              live={live} />
          </div>}
      </span>
      break
    case "comboTriggerNode":
      logBody = <span className="span-nodesData">
        {`${data.outputCoin}`}
        {(events.length > 0) &&
          <div className="mt-8">
            <ComboExtraDataLog {...props} />
          </div>}
      </span>
      break
    case "depositOnLPNode":
      logBody = <>
        <span className="span-nodesData">
          {`${data.pair.token0}-${data.pair.token1}`}
          <div>
            <a className="ftm-explorer-link ml-0"
              target="_blank"
              rel="noreferrer noopener"
              href={`${ENVIRONMENT.blockExplorerUrls}/address/${data?.pair?.id.split('-')[0]}`}>
              View contract</a></div> </span>
        {(events.length > 0) &&
          <div className="mt-8">
            <Suspense fallback={"Loading ..."}>
              <DepositExtraDataLog {...props} />
            </Suspense>
          </div>}
          {ENVIRONMENT.contracts.nodes !== data?.contracts?.nodes
            && <DeprecatedVault />}
      </>
      break
    case "farmNode":
      logBody = <><span className="span-nodesData">
        {`${data.pair.token0}-${data.pair.token1}`}
        <a className="ftm-explorer-link"
          target="_blank"
          rel="noreferrer noopener"
          href={`${ENVIRONMENT.blockExplorerUrls}/address/${data.pair.id.split('-')[0]}`}>
          View contract</a>
      </span>
        {(events.length > 0) &&
          <div className="mt-8">
            <Suspense fallback={"Loading ..."}>
              <FarmExtraDataLog {...props} />
            </Suspense>
          </div>}
          {(ENVIRONMENT.contracts.nodes !== data?.contracts?.nodes
            && getVaultDataForPairTokens(data?.pair?.token0, data?.pair?.token1) !== data?.contracts?.strategy)
            && <DeprecatedVault />}
          {(events.length > 0) && <button className="farm-details-label" onClick={handleClick}>
          <img src={info} className="info"/>
          <div className="farm-details-button">
            <ExplanationButton />
          </div>
        </button>}
      </>
      break
    case "liquidateNode":
      logBody = <span className="span-nodesData">
        {`${data.inputCoin}`}
        <span className="span-extraInfo">  <FormattedMessage id="view-details-to" /> </span>
        {`${data.outputCoin}`}
        {(events.length > 0) &&
          <div>
            <div className="received-line">
              <span><FormattedMessage id="view-logs-received" /> {amountToShow.toFixed(DECIMALS_FOR_TOKEN_AMOUNT)} {data.outputCoin}</span>
              <span className="left-logs-usd-price">$ {(Number(events[0]?.extraData?.outputTokenWithPrice?.priceUSD) * amountToShow).toFixed(DECIMALS_FOR_USD_AMOUNT)}</span>
            </div>
            <ExtraDataSideBarLogs
              tokenInput={data.inputCoin}
              tokenOutput={data.outputCoin}
              inputFeeAmountLive={feeLiveSwap}
              inputFeeAmountNoLive={events[0].input?.amount}
              inputAmount={events[0]?.extraData?.inputTokenWithPrice?.priceUSD}
              outputAmount={events[0]?.extraData?.outputTokenWithPrice?.priceUSD}
              live={live} />
          </div>}
      </span>
      break
    default:
      break
  }
  return (
    <div className="div-container">
      <SideBarErrorBoundary type={type} data={data}>
        <div className="node-div">
          <span className="span-nodeType" ><FormattedMessage id={`view-details-node.${type}`} />  </span>
          {logBody}
        </div>
      </SideBarErrorBoundary>
      <span className="span-time">{date.length > 0 && UTCDate}</span>
    </div >
  )
}

export default ViewRecipeLogs
