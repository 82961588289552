import React from 'react'

import title from 'src/static/images/not-desktop/only-desktop-title.png'
import bigCoin from 'src/static/images/not-desktop/big-coin.png'
import littleCoin from 'src/static/images/not-desktop/little-coin.png'
import blurredCoin from 'src/static/images/not-desktop/blurred-coin.png'
import tortleChar from 'src/static/images/not-desktop/tortle-ninja-char.png'

import './OnlyDesktopVeil.sass'

const OnlyDesktopVeil = () => {
  return (
    <div id="only-desktop">
      <div className="bg">
        <div className="bg-letters">
          <div className="container">
            <span>TORTLE NINJA</span>
            <img src={title} alt="" className="title" />
            <div className="dark-veil">
              <img src={tortleChar} alt="" className="tortle-char" />
              <img src={bigCoin} alt="" className="big-coin side-image" />
              <img src={littleCoin} alt="" className="little-coin side-image" />
              <img
                src={blurredCoin}
                alt=""
                className="blurred-coin side-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnlyDesktopVeil
