import React from "react"
import { FormattedMessage } from "react-intl"
import { useModal, registerModal, CloseModal } from "@gluedigital/modal"
import { ComboTriggerModalData } from "src/routes/RecipeDiagram/helpers/types"
import { BaseNodeModal } from "../BaseNodeModal"

import pricefeeds from "../../../../static/images/price-feeds.png"
import aprbased from "../../../../static/images/apr-based.png"
import timebased from "../../../../static/images/time-based.png"
import manualexecution from "../../../../static/images/manual-execution.png"
import social from "../../../../static/images/social.png"

import "./ComboTriggerPriceModal"
import "./ComboTriggerAPRModal"
import "./ComboTriggerManualModal"
import "./ComboTriggerTimeModal"
import "./ComboTriggerSentimentModal"
import "./ComboTriggerProfitModal"

import "./ComboTriggerModal.sass"

type ComboTriggerType = 'pricefeeds' | 'aprbased' | 'timebased' | 'manualexecution' | 'sentiment' | 'profit'

interface ComboTriggerModalPropTypes extends BaseNodeModal<ComboTriggerModalData> { }

const ComboTriggerModal = (props: ComboTriggerModalPropTypes) => {
  const modal = useModal();

  const handleClick = (type: ComboTriggerType) => {
    switch (type) {
      case 'pricefeeds':
        modal.show('combo-trigger-modal-pricefeeds', props)
        break
      case 'aprbased':
        modal.show('combo-trigger-modal-aprbased', props)
        break
      case 'timebased':
        modal.show('combo-trigger-modal-timebased', props)
        break
      case 'manualexecution':
        modal.show('combo-trigger-modal-manualexecution', props)
        break
      case 'sentiment':
        modal.show('combo-trigger-modal-sentiment', props)
        break
      case 'profit':
        modal.show('combo-trigger-modal-profit', props)
        break
      default:
    }
  }

  return (
    <div className="combo-trigger-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button className="modal-close-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="combo-trigger-modal.title" /></h1>
          <CloseModal>
          </CloseModal>
        </header>
        <div className="combo-types">
          <button className="combo-type-button" onClick={() => handleClick("pricefeeds")}>
            <img src={pricefeeds} alt="price feeds icon" />
            <span className="combo-type-text"><FormattedMessage id="combo-type-pricefeeds" /></span>
          </button>
          <button className="combo-type-button" onClick={() => handleClick("aprbased")}>
            <img src={aprbased} alt="apr based icon" />
            <span className="combo-type-text"><FormattedMessage id="combo-type-aprbased" /></span>
          </button>
          <button className="combo-type-button" onClick={() => handleClick("timebased")}>
            <img src={timebased} alt="time based icon" />
            <span className="combo-type-text"><FormattedMessage id="combo-type-timebased" /></span>
          </button>
          <button className="combo-type-button" onClick={() => handleClick("manualexecution")}>
            <img src={manualexecution} alt="manual execution icon" />
            <span className="combo-type-text"><FormattedMessage id="combo-type-manualexecution" /></span>
          </button>
          <button className="combo-type-button" onClick={() => handleClick("sentiment")}>
            <img src={social} alt="social sentiment icon" />
            <span className="combo-type-text"><FormattedMessage id="combo-type-sentiment" /></span>
          </button>
          <button className="combo-type-button" disabled onClick={() => handleClick("profit")}>
            <img src={manualexecution} alt="profits/losess icon" />
            <span className="combo-type-text"><FormattedMessage id="combo-type-profit" /></span>
          </button>
        </div>
      </div>
    </div >
  )
}

registerModal("combo-trigger-modal", ComboTriggerModal)
