import React from "react";
import { ApprovalStatus } from "src/routes/RecipeDiagram/helpers/types";

interface RetryDepositListProps {
  amount: string
  fantomStatus: ApprovalStatus
}

const RetryDepositList = (props: RetryDepositListProps) => {
  const { amount, fantomStatus } = props
  return <div className="approval-list">
    <div className="tokens-list">
      <div>
        <span className={`status-icon ${fantomStatus}`} />
        <span className="token-tag"><span className="amount-tag">{amount}</span> FTM</span>
        <span className="token-name">Fantom</span>
      </div>
    </div>
  </div>
}

export default RetryDepositList
